export const getInitialPaymentType = ({
  lastPaymentType,
  verifiedCompanyAccount,
}): string => {
  if (verifiedCompanyAccount) {
    return 'bankTransfer';
  }

  switch (lastPaymentType) {
    case 1:
      return 'courierDelivery';
    case 2:
      return 'bankTransfer';
    default:
      return '';
  }
};
