import { apiRequest } from '../api/request/makeRequest';
import type {
  JobApplicationPayload,
  UnsubscribeJobReportPayload,
} from './type';
import type { ThunkAPI } from '@store/configureStore';

const JOB_URL = 'job-application';
const JOB_EMAIL_REPORT_URL = 'job-email-report';

const saveJobApplication =
  (thunkAPI: ThunkAPI) =>
  ({ adId, ...body }: JobApplicationPayload) =>
    apiRequest(`${JOB_URL}/save/${adId}`, { method: 'POST', body, thunkAPI });

const unsubscribeJobReport =
  (thunkAPI: ThunkAPI) => (data: UnsubscribeJobReportPayload) =>
    apiRequest(`${JOB_EMAIL_REPORT_URL}/unsubscribe`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

export const JobAPI = {
  saveJobApplication,
  unsubscribeJobReport,
} as const;
