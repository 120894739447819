import { createAppAsyncThunk } from '@store/global/utils';

import { ShippingOrderStatusAPI } from './api';
import {
  FetchShippingOrderHistoryPayload,
  FetchShippingStatusPayload,
} from './type';

export const fetchShippingStatus = createAppAsyncThunk(
  'shippingCourier/fetchShippingStatus',
  async (payload: FetchShippingStatusPayload, thunkAPI) => {
    const { data } = await ShippingOrderStatusAPI.fetchShippingOrderStatus(
      thunkAPI
    )(payload);

    return data.results;
  }
);

export const fetchShippingHistory = createAppAsyncThunk(
  'shipping/fetchShippingOrderHistory',
  async (payload: FetchShippingOrderHistoryPayload, thunkAPI) => {
    const { data } = await ShippingOrderStatusAPI.fetchShippingOrderHistory(
      thunkAPI
    )(payload);
    return data;
  }
);
