import React, { createContext, useContext, useEffect } from 'react';

import { LocalStorageAPI } from '@lib/localStorage';

import { setHeader } from '@api/request/setHeader';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectIsUserLogged, selectToken } from '@store/auth/selector';
import { autoLogin, fetchToken, setToken } from '@store/auth/thunk';

const AuthSyncContext = createContext(undefined);

const useAuthSyncContextValue = () => {
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const isUserLogged = useAppSelector(selectIsUserLogged);

  useEffect(() => {
    if (token) {
      setHeader('Authorization', token);
    }
  }, [token]);

  useEffect(() => {
    async function loadFirebase() {
      const isLoggedViaOAuth = LocalStorageAPI.getWithExpiry('loginViaOAuth');
      if (!isLoggedViaOAuth) return;
      const { onAuthStateChanged, auth } = await import('../firebase');
      onAuthStateChanged(auth, (user) => {
        if (user && !isUserLogged) {
          dispatch(autoLogin(user));
        }
      });
    }
    loadFirebase();
  }, [isUserLogged, dispatch]);

  const authSync = (event: StorageEvent) => {
    if (event.key === 'login') {
      dispatch(fetchToken());
    }

    if (event.key === 'logout') {
      dispatch(setToken(''));
    }
  };

  useEffect(() => {
    window.addEventListener('storage', authSync);

    return () => {
      window.removeEventListener('storage', authSync);
      try {
        window.localStorage.removeItem('logout');
        window.localStorage.removeItem('login');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };
  }, []);

  return 1;
};

const AuthSyncContextProvider = ({ children }) => {
  const value = useAuthSyncContextValue();

  return (
    <AuthSyncContext.Provider value={value}>
      {children}
    </AuthSyncContext.Provider>
  );
};

export const useAuthSyncContext = () => {
  const context = useContext(AuthSyncContext);

  if (!context) {
    throw new Error(
      `AuthSyncContext must be used within AuthSyncContext.Provider`
    );
  }

  return context;
};

export default AuthSyncContextProvider;
