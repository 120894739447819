import { apiRequest } from '@store/api/request/makeRequest';
import { StaticPageResponse } from './type';
import type { ThunkAPI } from '@store/configureStore';

const STATIC_PAGES_URL = 'pages';

const fetchStaticPages = (thunkAPI: ThunkAPI) => (asPath: string) => {
  return apiRequest<StaticPageResponse>(`${STATIC_PAGES_URL}`, {
    method: 'POST',
    body: { pageName: `${asPath}.htm` },
    thunkAPI,
  });
};

export const StaticPagesAPI = {
  fetchStaticPages,
};
