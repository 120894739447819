import { getYearsOption } from 'src/lib/carFilters/getYearsOption';
import { decodeHTML } from 'src/lib/decodeHTML';

import { CarInformation, CustomCarValueResults } from './type';

const years = getYearsOption();

const km = {
  25000: { id: 25000, name: '25.000 km' },
  50000: { id: 50000, name: '50.000 km' },
  75000: { id: 75000, name: '75.000 km' },
  100000: { id: 100000, name: '100.000 km' },
  125000: { id: 125000, name: '125.000 km' },
  150000: { id: 150000, name: '150.000 km' },
  175000: { id: 175000, name: '175.000 km' },
  200000: { id: 200000, name: '200.000 km' },
  250000: { id: 250000, name: '250.000 km' },
};

const vehiclePower = {
  25: { id: '25', name: '25KS (18kW)' },
  50: { id: '50', name: '50KS (37kW)' },
  75: { id: '75', name: '75KS (59kW)' },
  100: { id: '100', name: '100KS (74kW)' },
  109: { id: '109', name: '109KS (80kW)' },
  125: { id: '125', name: '125KS (93kW)' },
  150: { id: '150', name: '150KS (112kW)' },
  200: { id: '200', name: '200KS (149kW)' },
  250: { id: '250', name: '250KS (186kW)' },
  300: { id: '300', name: '300KS (223kW)' },
};

const vehicleCC = {
  500: { id: '500', name: '500 cm3' },
  1150: { id: '1150', name: '1.150 cm3' },
  1300: { id: '1300', name: '1.300 cm3' },
  1600: { id: '1600', name: '1.600 cm3' },
  1800: { id: '1800', name: '1.800 cm3' },
  2000: { id: '2000', name: '2.000 cm3' },
  2500: { id: '2500', name: '2.500 cm3' },
  3000: { id: '3000', name: '3.000 cm3' },
  3500: { id: '3500', name: '3.500 cm3' },
  4500: { id: '4500', name: '4.500 cm3' },
};

export const createCarValues = (carValue: CustomCarValueResults) => {
  return {
    vehicleMakeYearMin: years,
    vehicleMakeYearMax: years,
    vehicleKmMin: km,
    vehicleKmMax: km,
    carFuelType: carValue.carFuelType,
    carBodyType: carValue.carBodyType,
    vehiclePowerMin: vehiclePower,
    vehiclePowerMax: vehiclePower,
    vehicleCcMax: vehicleCC,
    vehicleCcMin: vehicleCC,
    carDoors: carValue.carDoors,
    carSeats: carValue.carSeats,
    carGearbox: carValue.carGearbox,
    vehicleAircondition: carValue.vehicleAircondition,
    vehicleOrigin: carValue.vehicleOrigin,
    vehicleDisabledPerson: carValue.vehicleDisabledPerson,
    vehicleOwnerType: carValue.vehicleOwnerType,
    carColors: carValue.carColors,
    carOptions: carValue.carOptions,
    carDrives: carValue.carDrives,
    vehicleEmissionClass: carValue.vehicleEmissionClass,
    vehicleRegisteredDate: carValue.vehicleRegisteredDate,
  };
};

export const createAdCarInformation = (information: CarInformation) => ({
  vehicleMakeYear: {
    key: decodeHTML(information.vehicle_make_year.key),
    value: decodeHTML(information.vehicle_make_year.value),
  },
  vehicleKmFmt: {
    key: decodeHTML(information.vehicle_km_fmt.key),
    value: decodeHTML(information.vehicle_km_fmt.value),
  },
  carFuelTypeName: {
    key: decodeHTML(information.car_fuel_type_name.key),
    value: decodeHTML(information.car_fuel_type_name.value),
  },
  carBodyTypeName: {
    key: decodeHTML(information.car_body_type_name.key),
    value: decodeHTML(information.car_body_type_name.value),
  },
  carDoorsName: {
    key: decodeHTML(information.car_doors_name.key),
    value: decodeHTML(information.car_doors_name.value),
  },
  vehicleSeats: {
    key: decodeHTML(information.vehicle_seats.key),
    value: decodeHTML(information.vehicle_seats.value),
  },
  vehicleCC: {
    key: decodeHTML(information.vehicle_cc.key),
    value: decodeHTML(information.vehicle_cc.value),
  },
  vehiclePowerH: {
    key: decodeHTML(information.vehicle_power_h.key),
    value: decodeHTML(information.vehicle_power_h.value),
  },
  carGearboxName: {
    key: decodeHTML(information.car_gearbox_name.key),
    value: decodeHTML(information.car_gearbox_name.value),
  },
  vehicleExteriorColorName: {
    key: decodeHTML(information.vehicle_exterior_color_name.key),
    value: decodeHTML(information.vehicle_exterior_color_name.value),
  },
  vehicleAirConditionName: {
    key: decodeHTML(information.vehicle_aircondition_name.key),
    value: decodeHTML(information.vehicle_aircondition_name.value),
  },
  vehicleDrive: {
    key: decodeHTML(information.vehicle_drive_name.key),
    value: decodeHTML(information.vehicle_drive_name.value),
  },
  vehicleEmissionClass: {
    key: decodeHTML(information.vehicle_emission_class_name.key),
    value: decodeHTML(information.vehicle_emission_class_name.value),
  },
});
