import { getTimeStampFromDateString } from '@lib/localization/getTimestampFromDateString';

export const byPostedTimeAndMessageId = (
  a: { postedDatetime: string; messageId: number },
  b: { postedDatetime: string; messageId: number }
) => {
  const timeDifference =
    getTimeStampFromDateString(b.postedDatetime) -
    getTimeStampFromDateString(a.postedDatetime);
  if (timeDifference !== 0) {
    return timeDifference;
  }
  return b.messageId - a.messageId;
};
