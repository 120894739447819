/* eslint-disable @typescript-eslint/no-unused-vars */
import { apiRequest } from '@store/api/request/makeRequest';
import { makeQueryString } from '../api/request/makeQueryString';
import type {
  ReviewResponse,
  ReviewListPayload,
  ReviewCheckUserResponse,
  PostReviewPayload,
  PostReviewResponse,
  PostReviewReplyPayload,
  PostReviewReplyResponse,
} from './type';
import type { ThunkAPI } from '@store/configureStore';

const REVIEW_URL = 'reviews';

const fetchReviewForAd = (thunkAPI: ThunkAPI) => (adId: string) =>
  apiRequest<ReviewResponse>(`${REVIEW_URL}/ed/${adId}`, {
    method: 'GET',
    thunkAPI,
  });

const fetchReviewList =
  (thunkAPI: ThunkAPI) => (payload: ReviewListPayload) => {
    const { userId, firstParam, ...rest } = payload;

    const reviewQueryString = makeQueryString(rest);
    return apiRequest<ReviewResponse>(
      `${REVIEW_URL}/users/${userId}?${reviewQueryString}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );
  };

const fetchUserCheck = (thunkAPI: ThunkAPI) => (userId: string) =>
  apiRequest<ReviewCheckUserResponse>(`${REVIEW_URL}/${userId}/check`, {
    method: 'GET',
    thunkAPI,
  });

const postReview =
  (thunkAPI: ThunkAPI) => (data: { data: PostReviewPayload }) =>
    apiRequest<PostReviewResponse>(`${REVIEW_URL}`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

const postReviewReply =
  (thunkAPI: ThunkAPI) => (data: { data: PostReviewReplyPayload }) => {
    const { reviewId } = data.data;
    return apiRequest<PostReviewReplyResponse>(
      `${REVIEW_URL}/${reviewId}/reply`,
      {
        method: 'POST',
        body: data,
        thunkAPI,
      }
    );
  };

export const ReviewAPI = {
  fetchReviewForAd,
  fetchReviewList,
  fetchUserCheck,
  postReview,
  postReviewReply,
} as const;
