import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { extractByKey } from '@lib/extractByKey';
import { normalize } from 'src/lib/normalize';

import { fetchUserFavoriteCategories } from '@store/category/thunk';
import { fetchCarModels, fetchGroups } from '@store/group/thunk';
import { fetchUserAds } from '@store/meAds/thunk';

import { GroupInitialState, GroupItemType } from './type';

const normalizeFilteredGroups = (groups: GroupItemType[]) => {
  return groups.reduce((acc, item) => {
    const { parentId, id } = item;
    acc[parentId] = acc[parentId] || {};
    acc[parentId][id] = item;
    return acc;
  }, {});
};

const initialState: GroupInitialState = {
  groups: {},
  groupsIds: {},
  isLoading: false,
  carModels: [],
  filteredGroups: [],
};

const groupSlice = createSlice({
  name: 'group',
  initialState,

  reducers: {
    setGroups: {
      reducer(state, action: AnyAction) {
        const categoryId =
          action.payload[Object.keys(action.payload)[0]].parent;

        state.groups[categoryId] = action.payload;
        state.isLoading = false;
      },
      prepare(payload) {
        return { payload, meta: { normalizeKey: 'id' } };
      },
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[groupSlice.name])) {
        Object.assign(state, action.payload.group);
      }
    });

    builder.addCase(fetchGroups.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      const { categoryId } = action.meta.arg;

      state.groups[categoryId] = normalize(action.payload ?? [], 'id');
      state.groupsIds[categoryId] = extractByKey(action.payload ?? [], 'id');
      state.isLoading = false;
    });

    builder.addCase(fetchCarModels.fulfilled, (state, action) => {
      state.carModels = action.payload;
    });

    builder.addCase(fetchUserAds.fulfilled, (state, action) => {
      const { filteredGroups = [] } = action.payload;
      state.filteredGroups = normalizeFilteredGroups(filteredGroups);
    });

    builder.addCase(fetchUserFavoriteCategories.fulfilled, (state, action) => {
      const { filteredGroups = [] } = action.payload;

      state.filteredGroups = normalizeFilteredGroups(filteredGroups);
    });
  },
});

export const { setGroups } = groupSlice.actions;

export default groupSlice;
