/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import Router from 'next/router';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import handleParkAllAdsSuccessResponse from '@lib/ad/handleParkAllAdsResponse';
import { API_URL } from '@lib/constants/env.constants';
import { KEY_STATS } from '@lib/constants/meStats.constants';

import {
  AreYouHumanRoute,
  MyKPAdsRoute,
  MyKPFavoritesRoute,
  MyKPNotificationsRoute,
  NewAdRoute,
} from 'server/routes';

import { AdAPI } from '@store/ad/api';
import { setCaptchaSiteKey } from '@store/antiBot/slice';
import { Error } from '@store/global/type';
import { createAppAsyncThunk } from '@store/global/utils';
import { changeUserStats } from '@store/meStats/thunk';
import { showToast, toggleModal } from '@store/modal/slice';

import { createAd } from './model';
import {
  AgainstRulesPayload,
  DeleteAdPayload,
  ExportActiveUserAdsPayload,
  FetchAdPayload,
  FetchAdsPositionPayload,
  FetchAdTraversePayload,
  MissCatPayload,
  ParkAdPayload,
  ReportSpamPayload,
  SetAllAdsDeliveryMethodsPayload,
  UnParkAdPayload,
} from './type';
import {
  onFailureSetDeliveryMethod,
  onSuccessSetDeliveryMethod,
} from './utils';

export const fetchAd = createAppAsyncThunk(
  'ad/fetchAd',
  async (payload: FetchAdPayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      const response = await AdAPI.fetchAdInfo(thunkAPI)(payload);

      if (response?.data?.info?.captcha === 'soft') {
        dispatch(setCaptchaSiteKey(response.data.info.captchaSiteKey));
        dispatch(toggleModal('areYouHuman'));
      }

      return createAd(response.data.info);
    } catch (errors) {
      errors?.response?.data?.errors.forEach((error: Error) => {
        if (error.error_code === 'ad_deleted') {
          if (Router?.router?.query?.referrer === 'notification') {
            Router.push(
              MyKPNotificationsRoute.generateUrl({}, { infoBox: 'deleted' })
            );
          }
        }
        if (error.error_code === 'areYouHuman') {
          dispatch(setCaptchaSiteKey(errors.response.data.captchaSiteKey));
          if (error.error_description === 'hard')
            Router.push(AreYouHumanRoute.generateUrl());
        }
      });
      const response = errors.response.data;
      const { adInfo, captchaSiteKey } = response;
      if (captchaSiteKey) return {};
      if (!isEmpty(adInfo)) {
        return rejectWithValue(adInfo);
      }
      return rejectWithValue(response);
    }
  }
);

export const logAdVisit = createAppAsyncThunk(
  'ad/logAdVisit',
  async (payload: FetchAdPayload, thunkAPI) => {
    const { data } = await AdAPI.logAdVisit(thunkAPI)(payload);

    return data.results;
  }
);

export const fetchAdTraverse = createAppAsyncThunk(
  'ad/fetchAdTraverse',
  async (payload: FetchAdTraversePayload, thunkAPI) => {
    const { data } = await AdAPI.fetchAdTraverse(thunkAPI)(payload);

    return data.results;
  }
);

export const againstRules = createAppAsyncThunk(
  'ad/againstRules',
  async (payload: AgainstRulesPayload, thunkAPI) => {
    const { data } = await AdAPI.againstRules(thunkAPI)(payload);

    return data.results;
  }
);

export const reportSpam = createAppAsyncThunk(
  'ad/reportSpam',
  async (payload: ReportSpamPayload, thunkAPI) => {
    const { data } = await AdAPI.reportSpam(thunkAPI)(payload);

    return data.results;
  }
);

export const missCat = createAppAsyncThunk(
  'ad/missCat',
  async (payload: MissCatPayload, thunkAPI) => {
    const { data } = await AdAPI.missCat(thunkAPI)(payload);

    return data.results;
  }
);

export const followAd = createAppAsyncThunk(
  'ad/followAd',
  async (payload: FetchAdPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await AdAPI.followAd(thunkAPI)(payload);

    if (data.success) {
      dispatch(changeUserStats({ key: KEY_STATS.FOLLOW_AD }));
      dispatch(showToast({ content: 'Oglas dodat u Pratim' }));
    }

    return data.results;
  }
);

export const unfollowAd = createAppAsyncThunk(
  'ad/unfollowAd',
  async (payload: FetchAdPayload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { data } = await AdAPI.unfollowAd(thunkAPI)(payload);

    if (data.success) {
      dispatch(changeUserStats({ key: KEY_STATS.UNFOLLOW_AD }));
      dispatch(showToast({ content: 'Oglas izbačen iz Pratim' }));
    }

    const {
      query: { page },
      route,
    } = Router.router;
    const { adsIds, pages } = getState().search;
    const canRedirectMyKPFavorites =
      route === '/my-kp/favorite' && page !== '1' && size(adsIds) === 1;

    if (canRedirectMyKPFavorites) {
      Router.push(
        MyKPFavoritesRoute.generateUrl(
          {},
          {
            page: pages - 1,
          }
        )
      );
    }

    return data.results;
  }
);

export const addNewEd = createAppAsyncThunk(
  'ad/addNewEd',
  async (_, { getState }) => {
    if (getState().auth.token) {
      Router.push(NewAdRoute.generateUrl());
    }
  }
);

export const deleteAd = createAppAsyncThunk(
  'ad/deleteAd',
  async (payload: DeleteAdPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await AdAPI.deleteAd(thunkAPI)(payload);

    const key = payload.isDraftAd
      ? KEY_STATS.DELETE_DRAFT_AD
      : KEY_STATS.DELETE_AD;

    if (data.success) {
      dispatch(toggleModal(null));
      await dispatch(
        changeUserStats({
          key,
          adId: payload.adId,
          status: payload.status,
          activationPrice: payload.activationPrice,
        })
      );

      if (!payload.skipRedirect) {
        Router.push(MyKPAdsRoute.generateUrl({}, { infoBox: 'deleted' }));
      }
    }

    return data.results;
  }
);

export const exportActiveUserAds = createAppAsyncThunk(
  'ad/exportActiveUserAds',
  async (payload: ExportActiveUserAdsPayload, thunkAPI) => {
    const { data } = await AdAPI.exportActiveUserAds(thunkAPI)(payload);

    if (data.result) {
      window.open(`${API_URL}/${data.result}`, '_blank');
    }

    return data.result;
  }
);
export const parkAd = createAppAsyncThunk(
  'ad/parkAd',
  async (payload: ParkAdPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await AdAPI.parkAd(thunkAPI)(payload);

    if (data.success) {
      if (payload.redirect) {
        Router.push(MyKPAdsRoute.generateUrl({}, { infoBox: 'parked' }));
      } else {
        dispatch(changeUserStats({ key: KEY_STATS.PARKED_AD }));
        dispatch(toggleModal(null));
      }
    }

    return data.results;
  }
);

export const unparkAd = createAppAsyncThunk(
  'ad/unparkAd',
  async (payload: UnParkAdPayload, thunkAPI) => {
    const { data } = await AdAPI.unparkAd(thunkAPI)(payload);

    return data.results;
  }
);

export const fetchAdsPosition = createAppAsyncThunk(
  'ad/fetchAdsPosition',
  async (payload: FetchAdsPositionPayload, thunkAPI) => {
    const adsIds = payload;

    const { data } = await AdAPI.fetchAdsPosition(thunkAPI)({ adsIds });

    return data.results;
  }
);

export const parkAllAds = createAppAsyncThunk(
  'ad/parkAllAds',
  async (_, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { data } = await AdAPI.parkAllAds(thunkAPI)();

    if (data.success) {
      handleParkAllAdsSuccessResponse({
        dispatch,
        getState,
        unpark: false,
      });
    }
  }
);

export const unparkAllAds = createAppAsyncThunk(
  'ad/unparkAllAds',
  async (_, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { data } = await AdAPI.unparkAllAds(thunkAPI)();

    if (data.success) {
      handleParkAllAdsSuccessResponse({
        dispatch,
        getState,
        unpark: true,
      });
    }

    return data;
  }
);

export const setAllAdsDeliveryMethods = createAppAsyncThunk(
  'ad/setAllAdsDeliveryMethods',
  async (payload: SetAllAdsDeliveryMethodsPayload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    try {
      const { data } = await AdAPI.setAllAdsDeliveryMethods(thunkAPI)(payload);
      const { deviceType } = getState().meta;
      const isMobile = deviceType === 'mobile';

      if (data.success) {
        await onSuccessSetDeliveryMethod(dispatch, isMobile);
      }

      return data;
    } catch (errors) {
      await onFailureSetDeliveryMethod(
        dispatch,
        errors?.response?.data?.errors
      );
    }
  }
);
