import React from 'react';

import { getUserSystemTheme } from '@lib/theme/getUserSystemTheme';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectActiveTheme } from '@store/meta/selector';
import { setActiveTheme } from '@store/meta/slice';

import ButtonPrimaryBlue from '@components/controls/Button/ButtonPrimaryBlue';

import style from './ThemeToggle.module.scss';

const ThemeToggle = () => {
  const dispatch = useAppDispatch();
  const activeTheme = useAppSelector(selectActiveTheme);
  const selectedTheme =
    activeTheme === 'system' ? getUserSystemTheme() : activeTheme;
  const inactiveTheme = selectedTheme === 'light' ? 'dark' : 'light';

  return (
    <ButtonPrimaryBlue
      small
      onClick={() => {
        window.localStorage.setItem('theme', inactiveTheme);
        dispatch(setActiveTheme(inactiveTheme));
      }}
      className={style.darkSwitch}
    >
      {selectedTheme === 'light' ? 'Dark' : 'Light'}
    </ButtonPrimaryBlue>
  );
};

export default ThemeToggle;
