import { createAppAsyncThunk } from '@store/global/utils';
import { CustomValueAPI } from './api';

export const fetchCarValue = createAppAsyncThunk(
  'customValue/fetchCarValue',
  async (_, thunkAPI) => {
    const response = await CustomValueAPI.fetchCarValue(thunkAPI)();

    return response.data.results;
  }
);
