import React from 'react';
import classNames from 'classnames/bind';

import style from './Spinner.module.scss';

const setClass = classNames.bind(style);

type SpinnerProps = {
  blue?: boolean;
  medium?: boolean;
  darkBlue?: boolean;
  red?: boolean;
  ecomm?: boolean;
  className?: string;
};

const Spinner = ({
  blue = false,
  medium = false,
  darkBlue = false,
  red = false,
  ecomm = false,
  className = '',
}: SpinnerProps) => (
  <div
    className={`${setClass({
      ldsRing: true,
      blue,
      medium,
      darkBlue,
      red,
      ecomm,
    })} ${className}`}
  >
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Spinner;
