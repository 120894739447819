import { createAppAsyncThunk } from '@store/global/utils';

import { NotificationAPI } from './api';
import { NotificationListPayloadQuery, ReadNotification } from './type';

export const fetchNotifications = createAppAsyncThunk(
  'notification/list',
  async (payload: NotificationListPayloadQuery, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await NotificationAPI.list(thunkAPI)(payload.query);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const markAsRead = createAppAsyncThunk(
  'notification/markAsRead',
  async (payload: ReadNotification, thunkAPI) => {
    const { data } = await NotificationAPI.read(thunkAPI)({ id: payload.id });
    return data;
  }
);
