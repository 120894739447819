import { prependImageUrl } from '@lib/prependImageUrl';

export type Photo = {
  original: string;
  thumbnail: string;
  fullscreen: string;
};

export const createPhoto = (photo: any): Photo => {
  const emptyImage = '/static/images/no_photo100.png';
  const getThumbnail = (thumbnail: string) =>
    thumbnail === '' || thumbnail.includes('no_photo100')
      ? emptyImage
      : thumbnail;

  return {
    original: prependImageUrl(photo.big) ?? emptyImage,
    thumbnail: prependImageUrl(getThumbnail(photo.tmb_300x300)),
    fullscreen: prependImageUrl(photo.path) ?? emptyImage,
  };
};
