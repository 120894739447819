import { RequestStatus, Response } from '@store/global/type';

export enum TrackingCodeEventAction {
  formOpen = 'formOpen',
  formClosed = 'formClosed',
  sent = 'sent',
  openInterceptorFromInbox = 'openInterceptorFromInbox',
}

export enum TrackingCodeSource {
  message = 'message',
  inbox = 'inbox',
}

export type ShipmentTrackCourier = {
  id: number;
  name: string;
  icon: string;
};

export type MessageShipmentTrackCourier = ShipmentTrackCourier & {
  formTitle: string;
  formText: string;
  inputLabel: string;
  inputPlaceholder: string;
};

export type ShipmentTrackCourierInitialState = {
  messageShipmentTrackCourierById: Record<number, MessageShipmentTrackCourier>;
  messageShipmentTrackCourierIds: number[];
  isLoadingMessageShipmentTrackCourier: RequestStatus;
};

export type TrackingCode = {
  id: string;
  messageShipmentTrackCourierId: number | string;
  url: string;
  canUrlOpenDirect: boolean;
  messageShipmentTrackCourierName: string;
  useExternalApi: boolean;
};

export type SaveTrackingCodePayload = {
  adId?: string | number;
  receiverId?: string | number;
  threadId?: string | number;
  messageShipmentTrackCourierId: number;
  trackingCode: string;
  isScanned: boolean;
};

export type FetchMessageShipmentTrackCourierResponse = Response<
  MessageShipmentTrackCourier[]
>;
