import Router from 'next/router';

import { MyKPAdsRoute } from '@server/routes';

export const redirectToMyAds = ({ isPromoRenew }) => {
  const {
    query,
    query: { returnUrl },
  } = Router.router;
  let params = query;
  if (returnUrl && returnUrl !== 'undefined') {
    const url = Buffer.from(returnUrl as string, 'base64').toString();
    params = JSON.parse(url);
  }

  Router.push(
    MyKPAdsRoute.generateUrl(
      {},
      { ...params, infoBox: isPromoRenew ? 'activeRenew' : 'activePromo' }
    )
  );
};
