import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import differenceBy from 'lodash/differenceBy';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import { routeChangeComplete } from '@hooks/useResetState';
import {
  fetchInbox,
  fetchMessageAds,
  fetchMessageUsers,
} from '@store/messageInbox/thunk';
import { MessageInboxInitialState } from './type';

const initialState: MessageInboxInitialState = {
  inbox: {
    isLoading: true,
    threads: [],
    lastPosted: '',
    readTimeStart: '0000-00-00 00:00:00',
  },
  filters: {
    ads: [],
    isAdsLoading: false,
    users: [],
    isUsersLoading: false,
  },
};

const messageInboxSlice = createSlice({
  name: 'messageInbox',
  initialState,

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[messageInboxSlice.name])) {
        Object.assign(state, action.payload.message);
      }
    });

    builder.addCase(routeChangeComplete, (state) => {
      state.inbox = initialState.inbox;
    });

    builder.addCase(fetchInbox.fulfilled, (state, action) => {
      if (!action.payload) return;

      const {
        threads = initialState.inbox.threads,
        lastPosted = initialState.inbox.lastPosted,
        ...rest
      } = action.payload;

      const oldThreads = differenceBy(state.inbox.threads, threads, 'threadId');
      const newThreads = [...threads, ...oldThreads];

      const maxThreadsReadTime = newThreads
        .map((thread) => thread.messageReadTime)
        .sort();
      state.inbox = {
        threads: newThreads,
        ...rest,
        lastPosted,
        readTimeStart: last(maxThreadsReadTime),
        isLoading: false,
      };

      if (!isEmpty(threads)) {
        state.inbox.lastPosted = lastPosted;
        state.inbox.readTimeStart = last(maxThreadsReadTime);
      } else {
        state.inbox.lastPosted = '';
        state.inbox.readTimeStart = '0000-00-00 00:00:00';
      }
    });

    builder.addCase(fetchMessageAds.pending, (state) => {
      state.filters.isAdsLoading = true;
    });

    builder.addCase(fetchMessageAds.fulfilled, (state, action) => {
      state.filters.ads = action.payload;
      state.filters.isAdsLoading = false;
    });

    builder.addCase(fetchMessageUsers.pending, (state) => {
      state.filters.isUsersLoading = true;
    });

    builder.addCase(fetchMessageUsers.fulfilled, (state, action) => {
      state.filters.users = action.payload;
      state.filters.isUsersLoading = false;
    });
  },
});

export default messageInboxSlice;
