import { createWrapper } from 'next-redux-wrapper';
import { Action } from 'redux';

import { configureStore, ThunkAction } from '@reduxjs/toolkit';

import { DEVTOOLS_ENABLED } from 'src/lib/constants/env.constants';

import { maintenanceModeMiddleware } from './middlewares/maintenanceModeMiddleware';
import { normalizeMiddleware } from './middlewares/normalizeMiddleware';
import { userAuthMiddleware } from './middlewares/userAuthMiddleware';
import { userAuthRedirectMiddleware } from './middlewares/userAuthRedirectMiddleware';
import rootReducer from './rootReducer';

const config = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(maintenanceModeMiddleware)
        .prepend(userAuthMiddleware)
        .prepend(userAuthRedirectMiddleware)
        .concat(normalizeMiddleware),
    devTools: DEVTOOLS_ENABLED === 'true',
  });

  return store;
};

export type AppStore = ReturnType<typeof config>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;
export type AppDispatch = AppStore['dispatch'];

export type ThunkAPI = {
  getState: () => AppState;
  dispatch: AppDispatch;
};
export const wrapper = createWrapper<AppStore>(config);
