import { createAppAsyncThunk } from '@store/global/utils';
import { toggleModal, toggleScreen } from '@store/modal/slice';

import { BookAPI } from './api';
import { BookInfoPayload } from './type';

export const fetchBookInfo = createAppAsyncThunk(
  '/fetchBookInfo',
  async (payload: BookInfoPayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await BookAPI.fetchBookInfo(thunkAPI)(payload);
      dispatch(toggleScreen(''));
      dispatch(toggleModal('[BOOK_RESULT]'));
      return data.results;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors);
    }
  }
);
