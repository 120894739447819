import { createAppAsyncThunk } from '@store/global/utils';
import { AgreementAPI } from '@store/agreement/api';

export const fetchAgreements = createAppAsyncThunk(
  'agreement/fetchAgreements',
  async (_, thunkAPI) => {
    const response = await AgreementAPI.fetchAgreements(thunkAPI)();

    return response.data.results;
  }
);
