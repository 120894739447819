import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { SearchFormInitialState } from './type';

const initialState: SearchFormInitialState = {
  locationRadius: {
    0: { id: '0', name: 'do 0 km' },
    10: { id: '10', name: 'do 10 km' },
    25: { id: '25', name: 'do 25 km' },
    50: { id: '50', name: 'do 50 km' },
    75: { id: '75', name: 'do 75 km' },
    100: { id: '100', name: 'do 100 km' },
    150: { id: '150', name: 'do 150 km' },
    200: { id: '200', name: 'do 200 km' },
    250: { id: '250', name: 'do 250 km' },
    300: { id: '300', name: 'do 300 km' },
  },

  condition: {
    new: { id: 'new', name: 'Novo' },
    'as-new': { id: 'as-new', name: 'Nekorišćeno (polovno)' },
    used: { id: 'used', name: 'Korišćeno (polovno)' },
    damaged: { id: 'damaged', name: 'Oštećeno ili neispravno' },
  },

  immediateAvailable: {
    yes: { id: 'yes', name: 'Dostupno odmah' },
  },

  deleteSoon: {
    yes: { id: 'yes', name: 'Ističu u narednih 5 dana' },
  },

  deliveryMethod: {
    courierDelivery: { id: 'courierDelivery', name: 'Dostava' },
    localPickup: { id: 'localPickup', name: 'Lično preuzimanje' },
  },

  period: {
    today: { id: 'today', name: 'Današnji oglasi' },
    '3day': { id: '3day', name: 'Poslednja 3 dana' },
    '7day': { id: '7day', name: 'Poslednjih 7 dana' },
  },

  myKpPeriod: {
    last30Days: { name: 'U poslednjih 30 dana', id: 'last30Days' },
    last7Days: { name: 'U poslednjih 7 dana', id: 'last7Days' },
    last24h: { name: 'U poslednja 24 časa', id: 'last24h' },
    renewedLast24h: {
      name: 'Obnovljeni u poslednja 24 časa',
      id: 'renewedLast24h',
    },
  },

  type: {
    sell: { id: 'sell', name: 'Prodaje se' },
    buy: { id: 'buy', name: 'Kupuje se' },
  },

  adKind: {
    all: { id: 'all', name: 'Stvari i Usluge' },
    goods: { id: 'goods', name: 'Stvari' },
    service: { id: 'service', name: 'Usluge' },
  },

  priceText: {
    Besplatno: { id: 'Besplatno', name: 'Besplatno' },
    Kontakt: { id: 'Kontakt', name: 'Kontakt' },
    Dogovor: { id: 'Dogovor', name: 'Dogovor' },
  },

  currency: {
    rsd: { id: 'rsd', name: 'din' },
    eur: { id: 'eur', name: 'eur' },
  },

  focusedFilter: null,
  isSearchDetailedOpen: false,
  isSearchDetailedVisible: false,
  filtersToRemove: [],
  filtersToUpdate: [],
};

const searchFormSlice = createSlice({
  name: 'searchForm',
  initialState,

  reducers: {
    setSearchDetailedOpen(state, action) {
      state.isSearchDetailedOpen = action.payload;
    },
    setFocusedFilter(state, action) {
      state.focusedFilter = action.payload;
    },
    removeSelectedFilters(state, action) {
      state.filtersToRemove = action.payload;
    },
    updateSelectedFilters(state, action) {
      state.filtersToUpdate = action.payload;
    },
    setSearchDetailedVisible(state, action) {
      state.isSearchDetailedVisible = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[searchFormSlice.name])) {
        Object.assign(state, action.payload.searchForm);
      }
    });
  },
});
export const {
  setFocusedFilter,
  setSearchDetailedOpen,
  setSearchDetailedVisible,
  removeSelectedFilters,
  updateSelectedFilters,
} = searchFormSlice.actions;

export default searchFormSlice;
