import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';

import { formatDate } from '@lib/localization/formatDate';

import { toggleModal } from '@store/modal/slice';

type HandleAdSaveMinusWarningProps = {
  dispatch: ThunkDispatch<unknown, undefined, AnyAction> & Dispatch<AnyAction>;
  error: Record<string, string>;
  showPrepaidMinusWarning: boolean;
};

export const handleAdSaveMinusWarning = async ({
  dispatch,
  error,
  showPrepaidMinusWarning,
}: HandleAdSaveMinusWarningProps) => {
  const errors = {
    prepaid: '',
    date: '',
  };

  const errorModalMap: Record<string, string> = {
    prepaid_minus_warning: 'negativeBalance',
    draft_minus_warning: '[DRAFT_MINUS_WARNING]',
    prepaid_draft_minus_warning: '[PREPAID_DRAFT_MINUS_WARNING]',
  };

  const showMinusWarning =
    error &&
    showPrepaidMinusWarning &&
    Object.keys(errorModalMap).includes(error.error_code);

  if (showMinusWarning) {
    const modalType = errorModalMap[error.error_code];
    dispatch(toggleModal(modalType));
    errors.prepaid = error.error_description;
    errors.date = formatDate(error.error_prepaid_borrow_end);
  }

  return errors;
};
