/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import { MyAppContext } from 'kp';

import isServer from '@lib/ssrSetup/isServer';

import excludeSpecificQueryParamsBot from './excludeSpecificQueryParamsBot';
import isOldBrowser from './isOldBrowser';
import isOldOS from './isOldOS';
import maintenanceMode from './maintenanceMode';
import redirectRoutes from './redirectRoutes';
import sanitizeRoute from './sanitizeRoute';
import setLocals from './setLocals';
import sourceMaps from './sourceMaps';

const runSSRMiddlewares = async (
  ctx: MyAppContext['ctx']
): Promise<boolean> => {
  const { req } = ctx;
  const middlewares = [
    setLocals,
    maintenanceMode,
    isOldOS,
    isOldBrowser,
    excludeSpecificQueryParamsBot,
    sourceMaps,
    sanitizeRoute,
    redirectRoutes,
  ];

  const executeMiddleware = async (index: number) => {
    if (index >= middlewares.length) return false;

    const middleware = middlewares[index];
    return middleware(ctx, () => executeMiddleware(index + 1));
  };

  if (!req || !isServer) return false;

  return executeMiddleware(0);
};

export default runSSRMiddlewares;
