import type { ThunkAPI } from '@store/configureStore';

import { apiRequest } from '../api/request/makeRequest';

import type { FraudReportPayload } from './type';

const report = (thunkAPI: ThunkAPI) => (data: FraudReportPayload) => {
  return apiRequest('fraud', {
    method: 'POST',
    body: data,
    thunkAPI,
  });
};

export const FraudAPI = {
  report,
} as const;
