import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';

import { formatDateWithHours } from '@lib/localization/formatDateWithHours';

import { Any, RejectedError } from '@store/global/type';
import { toggleModal } from '@store/modal/slice';

type HandleAdSaveLockPeriodWarningProps = {
  dispatch: ThunkDispatch<unknown, undefined, AnyAction> & Dispatch<AnyAction>;
  response: Record<string, Any>;
};

export const handleAdSaveLockPeriodWarning = async ({
  dispatch,
  response,
}: HandleAdSaveLockPeriodWarningProps): Promise<{ errors: RejectedError }> => {
  const { prepaid, date, maxNumOfEds } = response;
  const errors: RejectedError = {
    ...response.errors[0],
    prepaid: prepaid ?? '',
    date: date ? formatDateWithHours(date) : '',
    maxNumOfEds: maxNumOfEds ?? '',
  };

  dispatch(toggleModal(response?.errors[0]?.error_code));

  return { errors };
};
