import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { createAction } from '@reduxjs/toolkit';

export const routeChangeStart = createAction('ROUTE_CHANGE_START');

export const useRouteChangeStart = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    router.events.on('routeChangeStart', (url) => {
      if (url !== router.asPath) {
        dispatch(routeChangeStart());
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
