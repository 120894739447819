import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { createAction } from '@reduxjs/toolkit';

export const routeChangeComplete = createAction('RESET_STATE');

export const useResetState = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    router.events.on('routeChangeComplete', (url) => {
      if (url !== router.asPath) {
        dispatch(routeChangeComplete());
      }
    });
  }, []);
};
