import { createSlice } from '@reduxjs/toolkit';
import { GdprInitialState } from './type';
import { addGdpr, requestGdpr } from './thunk';

const initialState: GdprInitialState = {
  activeStep: 0,
  pending: false,
  ready: false,
  created: '',
  statusEndDate: '',
  types: '',
  link: '',
};

const gdprSlice = createSlice({
  name: 'gdpr',
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(addGdpr.fulfilled, (state) => {
      state.activeStep = 1;
    });

    builder.addCase(requestGdpr.fulfilled, (state, action) => {
      const { pending, ready, created, statusEndDate, types, link } =
        action.payload;
      const setActiveStep = () => {
        if (pending) {
          return 1;
        }
        if (ready) {
          return 2;
        }
        return 0;
      };
      state.activeStep = setActiveStep();
      state.created = created;
      state.statusEndDate = statusEndDate;
      state.types = types;
      state.link = link;
    });
  },
});

export default gdprSlice;
