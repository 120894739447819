import { impression, sendToGTM } from '@lib/gtag';

export const loginEvents = {
  impression: () => impression('login'),

  clickLogin: (socialNetwork: string) =>
    sendToGTM({
      event: 'login',
      eventAction: 'click',
      eventLabel: socialNetwork,
      type: 'click',
      action: 'login',
      method: socialNetwork,
    }),

  clickRegister: () =>
    sendToGTM({
      event: 'login',
      eventAction: 'click',
      eventLabel: 'register',
      type: 'click',
      action: 'register',
    }),

  successLogin: (socialNetwork: string) =>
    sendToGTM({
      event: 'login',
      eventAction: 'success',
      eventLabel: socialNetwork,
      type: 'login',
      action: 'success',
      method: socialNetwork,
    }),

  successLoginActivation: (socialNetwork: string) =>
    sendToGTM({
      event: 'login',
      eventAction: 'activation',
      eventLabel: socialNetwork,
      type: 'login',
      action: 'activation',
      method: socialNetwork,
    }),

  failLogin: (socialNetwork: string) =>
    sendToGTM({
      event: 'login',
      eventAction: 'fail',
      eventLabel: socialNetwork,
      type: 'login',
      action: 'fail',
      method: socialNetwork,
    }),

  failFbNoEmail: () =>
    sendToGTM({
      event: 'login',
      eventAction: 'fail',
      eventLabel: 'fb_no_email',
      type: 'login',
      action: 'fail',
      method: 'fb_no_email',
    }),

  errorDeclined: () =>
    sendToGTM({
      event: 'login',
      eventAction: 'error',
      eventLabel: 'declined',
      type: 'login',
      action: 'error',
      method: 'declined',
    }),

  errorPending: () =>
    sendToGTM({
      event: 'login',
      eventAction: 'error',
      eventLabel: 'pending',
      type: 'login',
      action: 'error',
      method: 'pending',
    }),
};
