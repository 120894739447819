import { apiRequest } from '../api/request/makeRequest';
import type { FetchMediakitDownloadsResponse } from './type';
import type { ThunkAPI } from '@store/configureStore';

const MEDIA_KIT_URL = 'media-kit';

const fetchDownloads = (thunkAPI: ThunkAPI) => () =>
  apiRequest<FetchMediakitDownloadsResponse>(MEDIA_KIT_URL, {
    method: 'GET',
    thunkAPI,
  });

export const MediaKitAPI = {
  fetchDownloads,
} as const;
