import { impression, sendToFacebook, sendToGTM } from '@lib/gtag';

export const jobApplicationEvents = {
  impression: () => impression('job_application'),

  impressionType: (type: string) => {
    sendToFacebook({
      event: 'job-application',
      action: type,
    });
    if (type === 'application-link') {
      sendToGTM({
        event: 'job_application',
        eventAction: 'application-link',
        eventLabel: 'click',
        type: 'application-link',
        action: 'click',
      });
    }
  },

  successAplicationEmail: () => {
    sendToGTM({
      event: 'job_application',
      eventAction: 'application-email',
      eventLabel: 'success',
      type: 'application-email',
      action: 'success',
    });
    sendToFacebook({
      event: 'job_application',
      action: 'application-email',
    });
  },
};
