import Cookies, { CookieAttributes } from 'js-cookie';

import { COOKIE_URL } from '@lib/constants/env.constants';

const set = (
  name: string,
  value: string,
  options?: CookieAttributes
): string | undefined => {
  return Cookies.set(name, value, {
    expires: 1000,
    path: '/',
    domain: COOKIE_URL,
    ...options,
  });
};

const get = (name: string): string | undefined => {
  return Cookies.get(name);
};

const remove = (name: string, options?: CookieAttributes): void => {
  Cookies.remove(name, {
    path: '/',
    domain: COOKIE_URL,
    ...options,
  });
};

export const CookiesAPI = {
  set,
  get,
  remove,
};
