import { normalize } from 'src/lib/normalize';

import { createAppAsyncThunk } from '@store/global/utils';

import { GroupAPI } from './api';
import { GroupPayload } from './type';

export const fetchGroups = createAppAsyncThunk(
  'group/fetchGroups',
  async ({ categoryId, filters }: GroupPayload, thunkAPI) => {
    const response = await GroupAPI.fetchGroups(thunkAPI)({
      categoryId,
      filters,
    });

    return response.data.categories;
  }
);

export const fetchCarModels = createAppAsyncThunk(
  'group/fetchCarModels',
  async (groupId: string, thunkAPI) => {
    const response = await GroupAPI.fetchCarModels(thunkAPI)(groupId);

    return normalize(response.data.results, 'id');
  }
);
