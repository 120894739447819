import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useRouter } from 'next/router';
import debounce from 'lodash/debounce';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';
import useFetchRapid from '@hooks/useFetchRapid';

import { selectIsTokenFetched, selectIsUserLogged } from '@store/auth/selector';
import {
  fetchFeatureFlags,
  fetchGeneralGroupedInfo,
} from '@store/generalStats/thunk';
import { fetchUserGroupedInfo } from '@store/meStats/thunk';

const PrefetchContext = createContext(undefined);

const usePrefetchContextValue = () => {
  const dispatch = useAppDispatch();
  const { asPath } = useRouter();
  const isUserLoggedIn = useAppSelector(selectIsUserLogged);
  const isTokenFetched = useAppSelector(selectIsTokenFetched);
  const intervalRef = useRef(0);
  const didMountRef = useRef(false);

  useFetchRapid();

  useEffect(() => {
    dispatch(fetchFeatureFlags());
  }, [dispatch, isUserLoggedIn]);

  const debouncedFetchGeneralGroupedInfo = useMemo(() => {
    return debounce(() => {
      return dispatch(fetchGeneralGroupedInfo());
    }, 30000);
  }, [dispatch]);

  useEffect(() => {
    if (didMountRef.current) {
      debouncedFetchGeneralGroupedInfo();
    }
    didMountRef.current = true;
    return () => debouncedFetchGeneralGroupedInfo.cancel();
  }, [asPath, debouncedFetchGeneralGroupedInfo]);

  useEffect(() => {
    const periodicallyFetch = () => {
      dispatch(fetchUserGroupedInfo());
    };

    if (isUserLoggedIn && isTokenFetched) {
      periodicallyFetch();

      intervalRef.current = window.setInterval(periodicallyFetch, 50000);
    }

    return () => clearInterval(intervalRef.current);
  }, [dispatch, isUserLoggedIn, isTokenFetched]);

  return 1;
};

const PrefetchContextProvider = ({ children }) => {
  const value = usePrefetchContextValue();

  return (
    <PrefetchContext.Provider value={value}>
      {children}
    </PrefetchContext.Provider>
  );
};

export const usePrefetchContext = () => {
  const context = useContext(PrefetchContext);

  if (!context) {
    throw new Error(
      `PrefetchContext must be used within PrefetchContext.Provider`
    );
  }

  return context;
};

export default PrefetchContextProvider;
