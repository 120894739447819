import Router from 'next/router';
import Cookies from 'js-cookie';
import NProgress from 'nprogress';

import { COOKIE_URL } from './constants/env.constants';
import { forceRefresh } from './forceRefresh';

export function initRouterListeners() {
  Router.events.on('routeChangeStart', async (url) => {
    if (!url.includes('modal')) {
      NProgress.start();
    }

    if (Cookies.get('kpizlog')) {
      Cookies.remove('kpizlog', { path: '/', domain: COOKIE_URL });
    }

    await forceRefresh(url);
  });

  Router.events.on('routeChangeComplete', () => {
    NProgress.done();
  });

  Router.events.on('routeChangeError', () => {
    NProgress.done();
  });
}
