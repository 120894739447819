import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import type {
  DeleteAddressPayload,
  FetchAddressPayload,
  FetchAddressResponse,
  FetchStreetsPayload,
  FetchStreetsResponse,
  FetchTownsPayload,
  FetchTownsResponse,
  SaveAddressPayload,
  SaveAddressResponse,
} from './type';

const ADDRESS_URL = 'me/address';

const saveAddress = (thunkAPI: ThunkAPI) => (data: SaveAddressPayload) =>
  apiRequest<SaveAddressResponse>(`${ADDRESS_URL}/save`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const fetchAddress =
  (thunkAPI: ThunkAPI) =>
  ({ adId }: FetchAddressPayload) => {
    const params = adId ? `?adId=${adId}` : '';
    return apiRequest<FetchAddressResponse>(`${ADDRESS_URL}/list${params}`, {
      method: 'GET',
      thunkAPI,
    });
  };

const deleteAddress =
  (thunkAPI: ThunkAPI) =>
  ({ id }: DeleteAddressPayload) =>
    apiRequest(`${ADDRESS_URL}/delete/${id}`, { method: 'POST', thunkAPI });

const fetchStreets = (thunkAPI: ThunkAPI) => (data: FetchStreetsPayload) =>
  apiRequest<FetchStreetsResponse>(`${ADDRESS_URL}/suggested-streets`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const fetchTowns = (thunkAPI: ThunkAPI) => (data: FetchTownsPayload) =>
  apiRequest<FetchTownsResponse>(`${ADDRESS_URL}/suggested-towns`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

export const AddressAPI = {
  saveAddress,
  fetchAddress,
  deleteAddress,
  fetchStreets,
  fetchTowns,
} as const;
