import { formatCurrency } from './localization/formatCurrency';

export const getPriceText = (
  adType: string,
  adKind: string,
  price: number,
  currency: string
) => {
  if (adType === 'sell') {
    return `${formatCurrency(price)} ${currency}`;
  }

  return adKind === 'goods' ? 'Kupujem' : 'Tražim';
};
