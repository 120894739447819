import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useAppDispatch } from './redux/useAppDispatch';
import { useAppSelector } from './redux/useAppSelector';

import { getSecondsBetweenTwoDate } from 'src/lib/getSecondsBetweenTwoDate';
import { fetchRapidMessages } from '@store/rapidMessage/thunk';
import {
  selectLastNotificationUpdateTime,
  selectRapidLastRequestTime,
} from '@store/rapidMessage/selector';

export default function useFetchRapid() {
  const dispatch = useAppDispatch();
  const { asPath } = useRouter();
  const lastRequestTime = useAppSelector(selectRapidLastRequestTime);
  const lastNotificationUpdateTime = useAppSelector(
    selectLastNotificationUpdateTime
  );

  const secondBetweenLastRequest = getSecondsBetweenTwoDate(
    new Date(),
    new Date(lastRequestTime)
  );

  useEffect(() => {
    const updatedSinceTime = lastNotificationUpdateTime ?? lastRequestTime;
    if (
      Number.isNaN(secondBetweenLastRequest) ||
      secondBetweenLastRequest > 60
    ) {
      dispatch(fetchRapidMessages({ updatedSinceTime }));
    }
  }, [dispatch, asPath]);
}
