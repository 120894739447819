import React from 'react';

import { KPAppleStoreRoute, KPPlayStoreRoute } from '@server/routes';

import AppleStoreLogo from '@components/common/responsive/Logo/AppleStoreLogo';
import GooglePlayLogo from '@components/common/responsive/Logo/GooglePlayLogo';
import Link from '@components/controls/Link/Link';

import style from './ErrorUnderConstruction.module.scss';

const ErrorUnderConstructionBlacklistMode = () => {
  return (
    <>
      <div className={style.title}>
        Trenutno smo nedostupni na mobilnom sajtu.
      </div>
      <div className={style.description}>
        <span className={style.small}>
          U međuvremenu, koristite našu aplikaciju! Vaš <span>k</span>
          <span>p</span> tim.
        </span>
      </div>
      <div className={style.appStoreInfo}>
        <Link
          ariaLabel="KupujemProdajem PlayStore link"
          href={KPPlayStoreRoute.generateUrl()}
          rel="noopener"
          target="_blank"
        >
          <GooglePlayLogo />
        </Link>
        <Link
          ariaLabel="KupujemProdajem AppleStore link"
          href={KPAppleStoreRoute.generateUrl()}
          rel="noopener"
          target="_blank"
        >
          <AppleStoreLogo />
        </Link>
      </div>
    </>
  );
};

export default ErrorUnderConstructionBlacklistMode;
