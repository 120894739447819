import Bowser from 'bowser';

import { AppStore } from '@store/configureStore';
import {
  setDeviceType,
  setIsDesktopRequested,
  setScreenWidth,
} from '@store/meta/slice';

export async function setPlatformType(
  cookies: Record<string, string>,
  deviceInfo: Bowser.Parser.ParsedResult,
  store: AppStore
) {
  const isDesktopRequested = ['1', '2'].includes(cookies.desktop_requested);

  const isMobile =
    deviceInfo?.platform?.type === 'mobile' && !isDesktopRequested;

  store.dispatch(setIsDesktopRequested(isDesktopRequested));

  if (isMobile) {
    await store.dispatch(setDeviceType('mobile'));
  }

  await store.dispatch(setScreenWidth(cookies.screenWidth ?? null));
}
