import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { fetchShippingHistory, fetchShippingStatus } from './thunk';
import { ShippingOrderStatusInitialState } from './type';

const initialState: ShippingOrderStatusInitialState = {
  shippingCourierOrderStatus: {
    trackingCode: '',
    shippingCompany: '',
    statusDescription: '',
    progressBar: [],
    statusDatetime: '',
    location: '',
    phone: '',
    statusType: 'default',
    recipient: {
      name: '',
      address: '',
      town: '',
    },
    statusHistory: [],
    infoText: '',
    canCallCourier: false,
    callCourierText: '',
  },
  isExpandedHistorySection: false,
  shippingHistory: {
    shippingHistoryList: [],
    pages: 0,
    totalCount: 0,
    isShppingHistoryLoading: true,
  },
};

const shippingOrderStatusSlice = createSlice({
  name: 'shippingOrderStatus',
  initialState,
  reducers: {
    setIsExpandedHistorySection(state, action) {
      state.isExpandedHistorySection = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[shippingOrderStatusSlice.name])) {
        Object.assign(state, action.payload.shippingCourier);
      }
    });

    builder.addCase(fetchShippingStatus.fulfilled, (state, action) => {
      state.shippingCourierOrderStatus = action.payload;
    });

    builder.addCase(fetchShippingHistory.fulfilled, (state, action) => {
      const { results, pages, totalCount } = action.payload;

      state.shippingHistory.shippingHistoryList = results;
      state.shippingHistory.pages = pages;
      state.shippingHistory.totalCount = totalCount;
      state.shippingHistory.isShppingHistoryLoading = false;
    });
    builder.addCase(fetchShippingHistory.pending, (state) => {
      state.shippingHistory.isShppingHistoryLoading = true;
    });
    builder.addCase(fetchShippingHistory.rejected, (state) => {
      state.shippingHistory.isShppingHistoryLoading = false;
    });
  },
});

export const { setIsExpandedHistorySection } = shippingOrderStatusSlice.actions;

export default shippingOrderStatusSlice;
