import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';
import type {
  EditUserNotificationAlertsPayload,
  EditUserProfilePayload,
  UserProfileResponse,
} from '@store/meProfile/type';

const USER_URL = 'me';

const fetchUserProfile = (thunkAPI: ThunkAPI) => () =>
  apiRequest<UserProfileResponse>(`${USER_URL}/profile`, {
    method: 'GET',
    thunkAPI,
  });

const editUserProfile =
  (thunkAPI: ThunkAPI) => (body: EditUserProfilePayload) =>
    apiRequest(`${USER_URL}/profile/edit`, {
      method: 'POST',
      body,
      thunkAPI,
    });

const editUserNotificationAlerts =
  (thunkAPI: ThunkAPI) => (body: EditUserNotificationAlertsPayload) =>
    apiRequest(`${USER_URL}/notification-alerts/edit`, {
      method: 'POST',
      body,
      thunkAPI,
    });

export const MeProfileAPI = {
  fetchUserProfile,
  editUserProfile,
  editUserNotificationAlerts,
} as const;
