import { createAppAsyncThunk } from '@store/global/utils';
import { EmojiAPI } from './api';

export const fetchEmoji = createAppAsyncThunk(
  'emoji/fetchEmoji',
  async (_, thunkAPI) => {
    const { data } = await EmojiAPI.fetchEmoji(thunkAPI)();

    return data.results;
  }
);
