import { apiRequest } from '../api/request/makeRequest';
import { formatSearchParams } from 'src/lib/formatSearchParams';
import type {
  DeleteSavedSearchPayload,
  FetchSavedSearchesPayload,
  RegisterNotificationClickAndSearchFiltersByHashPayload,
  SaveSearchPayload,
  SavedSearchResponse,
  SearchFilterByHashPayload,
  SearchFilterByHashResponse,
  UpdateSaveSearchesPayload,
} from './type';
import type { ThunkAPI } from '@store/configureStore';

const SAVE_SEARCH_URL = 'usersavedsearch';

const saveSearch = (thunkAPI: ThunkAPI) => (data: SaveSearchPayload) => {
  const formattedData = formatSearchParams(data.data);
  return apiRequest(`${SAVE_SEARCH_URL}/save-search`, {
    method: 'POST',
    body: { data: formattedData },
    thunkAPI,
  });
};

const getSearchFiltersByHash =
  (thunkAPI: ThunkAPI) => (data: SearchFilterByHashPayload) =>
    apiRequest<SearchFilterByHashResponse>(
      `${SAVE_SEARCH_URL}/get-search-filters-by-hash`,
      {
        method: 'POST',
        body: data,
        thunkAPI,
      }
    );

const registerNotificationClickAndGetSearchFiltersByHash =
  (thunkAPI: ThunkAPI) =>
  (data: RegisterNotificationClickAndSearchFiltersByHashPayload) =>
    apiRequest<SearchFilterByHashResponse>(
      `${SAVE_SEARCH_URL}/register-notification-click-and-get-search-filters-by-hash`,
      {
        method: 'POST',
        body: data,
        thunkAPI,
      }
    );

const fetchSavedSearches =
  (thunkAPI: ThunkAPI) =>
  ({ page = '1' }: FetchSavedSearchesPayload) =>
    apiRequest<SavedSearchResponse>(
      `${SAVE_SEARCH_URL}/get-searches?page=${page}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );

const updateSavedSearches =
  (thunkAPI: ThunkAPI) => (data: UpdateSaveSearchesPayload) =>
    apiRequest(`${SAVE_SEARCH_URL}/update-search`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

const deleteSavedSearches =
  (thunkAPI: ThunkAPI) => (data: DeleteSavedSearchPayload) =>
    apiRequest(`${SAVE_SEARCH_URL}/delete-search`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

export const SaveSearchAPI = {
  saveSearch,
  fetchSavedSearches,
  updateSavedSearches,
  deleteSavedSearches,
  getSearchFiltersByHash,
  registerNotificationClickAndGetSearchFiltersByHash,
} as const;
