/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-empty */
import Router from 'next/router';
import { AnyAction, Dispatch } from 'redux';

import { ThunkDispatch } from '@reduxjs/toolkit';

import { createBase64 } from '@lib/createBase64';
import { delay } from '@lib/delay';
import { loginEvents } from '@lib/events/login.event';
import { LocalStorageAPI } from '@lib/localStorage';

import {
  AccountActivationRoute,
  FacebookErrorRoute,
  MyKPAdsRoute,
  TwoStepVerificationRoute,
  UserBlockedRoute,
} from '@server/routes';

import { fetchReturnUrl } from '@store/generalStats/thunk';
import { Error } from '@store/global/type';
import { setActivationEmail } from '@store/meAccount/slice';

import { setSuccessfulyLogin } from './slice';
import { LoginResponse } from './type';

export const SET_LOADING = 'auth/setLoading';

const SOCIAL_AUTH_TTL = 400 * 24 * 60 * 60 * 1000;

export const setLoading = (payload: string) => {
  return {
    type: SET_LOADING,
    payload,
  };
};

export const redirect = async (
  dispatch: ThunkDispatch<unknown, undefined, AnyAction> & Dispatch<AnyAction>
) => {
  const {
    pathname,
    query: { return_url, returnUrl },
  } = Router.router;
  const pathsArray = ['/login', '/verifikacioni-kod'];
  const redirectTo: any = return_url || returnUrl;
  if (redirectTo && redirectTo !== 'undefined') {
    const url = Buffer.from(redirectTo, 'base64').toString();
    if (Number(url)) {
      await dispatch(fetchReturnUrl(url));
    } else {
      Router.push(url);
    }
  } else if (pathsArray.includes(pathname)) {
    Router.push(MyKPAdsRoute.generateUrl());
  }
};

export const onSuccessLogin = async (
  dispatch: ThunkDispatch<unknown, undefined, AnyAction> & Dispatch<AnyAction>,
  socialNetwork: string,
  data: LoginResponse,
  setToken: any
) => {
  loginEvents.successLogin(socialNetwork);
  await dispatch(setSuccessfulyLogin());
  await delay(1000);
  try {
    await dispatch(setToken(data?.token));
    window.localStorage.setItem('login', Date.now().toString());
    if (socialNetwork !== 'direct') {
      LocalStorageAPI.setWithExpiry('loginViaOAuth', true, SOCIAL_AUTH_TTL);
    }
    await redirect(dispatch);
  } catch (e) {}
};

export const onFailureLogin = async (
  errors: Error[],
  payload: any,
  dispatch: ThunkDispatch<unknown, undefined, AnyAction> & Dispatch<AnyAction>
) => {
  const { email } = payload;
  errors.map((error) => {
    if (error.error_code === 'user_status_declined') {
      loginEvents.errorDeclined();
      Router.push(UserBlockedRoute.generateUrl());
    }
    if (error.error_code === 'user_status_pending') {
      loginEvents.errorPending();
      Router.push(
        AccountActivationRoute.generateUrl({}, { status: 'pending' })
      );
    }
    if (error.error_code === 'oauth_email_exist') {
      loginEvents.failFbNoEmail();
      Router.push(FacebookErrorRoute.generateUrl());
    }

    if (
      error.error_code === 'login_invalid_verification_daily' &&
      errors.some((err) => err.error_code === 'account_verification_required')
    ) {
      Router.push(
        TwoStepVerificationRoute.generateUrl(
          {},
          {
            type: 'onemogućeno-logovanje',
          }
        )
      );
    }

    if (
      error.error_code === 'account_verification_required' &&
      !errors.some(
        (err) => err.error_code === 'login_invalid_verification_daily'
      )
    ) {
      const { asPath } = Router.router;
      const isLoginPath = asPath.includes('/login');
      const returnUrl = isLoginPath ? '' : createBase64(asPath);
      Router.push(TwoStepVerificationRoute.generateUrl({}, { returnUrl }));
    }
  });
  dispatch(setActivationEmail(email));
};

export const onFailureCheckVerificationCode = async (errors: Error[]) => {
  errors.map((error) => {
    if (error.error_code === 'login_invalid_verification_daily') {
      Router.push(
        TwoStepVerificationRoute.generateUrl(
          {},
          {
            type: 'onemogućeno-logovanje',
          }
        )
      );
    }
  });
};

export const createVerificationCodePayload = (values: {
  [key: string]: string;
}) => {
  return Number(Object.values(values).join(''));
};
