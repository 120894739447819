import imageCompression from 'browser-image-compression';

import { FileAPI } from '@store/file/api';
import { createAppAsyncThunk } from '@store/global/utils';

import { FileType, FileUploadPayload, RejectedFileError } from './type';

const MIN_BYTES_TO_COMPRESS = 500000;

export const compressFile = async (file: FileType) => {
  if (!/^image/.test(file?.type) || file.size < MIN_BYTES_TO_COMPRESS) {
    return file;
  }
  try {
    const options = {
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressed = await imageCompression(file, options);

    const compressedFile = Object.assign(compressed, {
      percent: 0,
      id: file.id,
      error: '',
    });

    return compressedFile;
  } catch (error) {
    return file;
  }
};

export const uploadJobFiles = createAppAsyncThunk<
  Partial<FileType>,
  FileUploadPayload,
  { rejectValue: RejectedFileError }
>('file/uploadJobFiles', async (payload, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  const { file } = payload;

  try {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('use', 'job_application');
    const { data } = await FileAPI.upload(thunkAPI)(formData);

    return { id: file.id, error: '', ...data.results };
  } catch (e) {
    const { errors } = e.response.data;
    return rejectWithValue({
      id: file.id,
      path: '',
      error: errors[0].error_description,
    });
  }
});

export const uploadAdSaveFile = createAppAsyncThunk<
  Partial<FileType>,
  FileUploadPayload,
  { rejectValue: RejectedFileError }
>('file/uploadAdSaveFile', async (payload, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  const { file, onUploadProgress } = payload;
  try {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('use', 'ad');
    const { data } = await FileAPI.upload(thunkAPI)(
      formData,
      (progressEvent) => {
        return onUploadProgress(progressEvent, file);
      }
    );

    return { id: file.id, error: '', ...data.results };
  } catch (e) {
    const { errors } = e.response.data;

    return rejectWithValue({
      id: file.id,
      path: '',
      error: errors[0].error_description,
    });
  }
});

export const uploadFile = createAppAsyncThunk<
  Partial<FileType>,
  FileUploadPayload,
  { rejectValue: RejectedFileError }
>('file/uploadFile', async (payload, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  const { file, onUploadProgress, use } = payload;
  try {
    const formData = new FormData();
    formData.append('file', file, file.name);
    if (use) {
      formData.append('use', use);
    }

    const { data } = await FileAPI.upload(thunkAPI)(formData, (progressEvent) =>
      onUploadProgress(progressEvent, file)
    );

    return { id: file.id, error: '', percent: 100, ...data.results };
  } catch (e) {
    const { errors } = e.response.data;
    return rejectWithValue({
      id: file.id,
      path: file.path,
      error: errors[0].error_description,
      percent: 100,
    });
  }
});

export const rotate = createAppAsyncThunk(
  'file/rotate',
  async (payload: string, thunkAPI) => {
    const { data } = await FileAPI.rotate(thunkAPI)({ filePath: payload });

    return data.results;
  }
);
