import { HYDRATE } from 'next-redux-wrapper';
import unset from 'lodash/unset';
import without from 'lodash/without';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { extractByKey } from 'src/lib/extractByKey';
import { normalize } from 'src/lib/normalize';

import { createAd } from '@store/ad/model';
import { followAd, unfollowAd } from '@store/ad/thunk';
import { fetchCategoryInfo } from '@store/categoryInfo/thunk';
import {
  getSearchFiltersByHash,
  registerNotificationClickAndGetSearchFiltersByHash,
  saveSearch,
} from '@store/savedSearch/thunk';
import {
  fetchSearchedAds,
  fetchSearchRecent,
  fetchSeoCategoryText,
} from '@store/search/thunk';

import { SearchInitialState } from './type';

const initialState: SearchInitialState = {
  total: 0,
  pages: 1,
  page: 1,
  filterId: 0,
  filterName: '',
  postedMax: null,
  isLoading: true,
  adsIds: [],
  byId: {},
  hasReachedMax: false,
  isSearchExisting: false,
  isSearchSaveAllowed: false,
  showSearchIsbnInfo: false,
  searchRecent: [],
  isSavingSearchLoading: false,
  hashErrors: [],
  seoCategoryText: '',
  isSeoCategoryTextAllowed: true,
  categoryPartners: [],
  salePartners: [],
  searchFilterScreen: 0,
  myKpSearchFilterScreen: 0,
  searchFiltersOrigin: 'byFilters',
  isError: false,
  lastSearchResult: null,
  lastSearchQuery: null,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,

  reducers: {
    setSearchAsSaved(state, action) {
      state.isSearchExisting = action.payload;
    },
    setFilterId(state, action) {
      state.filterId = action.payload;
    },
    removeSearchRecent(state, action) {
      const searchRecent = state.searchRecent.filter(
        (item) => item.filterId !== Number(action.payload)
      );
      state.searchRecent = searchRecent;
    },
    removeAllSearchRecent(state) {
      state.searchRecent = [];
    },
    setSearchFilterScreen(state, action) {
      state.searchFilterScreen = action.payload;
    },
    setMyKPSearchFilterScreen(state, action) {
      state.myKpSearchFilterScreen = action.payload;
    },
    setSearchFiltersOrigin(state, action) {
      state.searchFiltersOrigin = action.payload;
    },
    resetSearchState(state) {
      Object.assign(state, initialState);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[searchSlice.name])) {
        Object.assign(state, action.payload.search);
      }
    });

    builder.addCase(fetchSearchedAds.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(fetchSearchedAds.fulfilled, (state, action) => {
      if (!action?.payload) return;

      const {
        total,
        pages,
        page,
        filterId,
        filterName,
        postedMax,
        hasReachedMax,
        isSearchExisting,
        isSearchSaveAllowed,
        showSearchIsbnInfo,
        leaders,
        sales,
      } = action.payload;
      const ads = action.payload.ads.map((ad) => createAd(ad));
      state.adsIds = extractByKey(ads, 'id');
      state.byId = normalize(ads, 'id');
      state.total = total;
      state.pages = pages;
      state.page = page;
      state.filterId = filterId;
      state.filterName = filterName;
      state.postedMax = postedMax;
      state.hasReachedMax = hasReachedMax;
      state.isSearchExisting = isSearchExisting;
      state.isSearchSaveAllowed = isSearchSaveAllowed;
      state.showSearchIsbnInfo = showSearchIsbnInfo;
      state.isLoading = false;
      state.isError = hasReachedMax || total === 0;
      state.categoryPartners = leaders;
      state.salePartners = sales;
      state.searchRecent = [];
      state.lastSearchQuery = action?.meta?.arg;
      state.lastSearchResult = action?.payload;
    });

    builder.addCase(fetchSearchRecent.fulfilled, (state, action) => {
      if (action.payload instanceof Array) {
        state.searchRecent = action.payload.reverse();
      }
    });

    builder.addCase(followAd.fulfilled, (state, action) => {
      const { adId } = action.meta.arg;
      state.lastSearchQuery = initialState.lastSearchQuery;
      if (state.byId[adId]) {
        state.byId[adId].isFollowing = true;
        state.byId[adId].favoriteCount += 1;
      }
    });

    builder.addCase(unfollowAd.fulfilled, (state, action) => {
      const { adId, isFavoritePage = false } = action.meta.arg;
      state.lastSearchQuery = initialState.lastSearchQuery;
      if (state.byId[adId]) {
        state.byId[adId].isFollowing = false;
        state.byId[adId].favoriteCount -= 1;
      }
      if (isFavoritePage) {
        const adsIds = without(state.adsIds, adId);
        state.adsIds = adsIds;
        unset(state.byId, adId);
        state.total -= 1;
        if (adsIds.length < 30 && state.total <= 30) {
          state.pages = 1;
        }
      }
    });

    builder.addCase(saveSearch.pending, (state) => {
      state.isSavingSearchLoading = true;
    });

    builder.addCase(saveSearch.fulfilled, (state) => {
      state.isSavingSearchLoading = false;
    });

    builder.addCase(saveSearch.rejected, (state, action) => {
      if (
        action.payload &&
        Object.keys(action?.payload).includes('saved_search_already_exists')
      ) {
        state.isSearchExisting = true;
      }
    });

    builder.addCase(getSearchFiltersByHash.rejected, (state, action) => {
      state.hashErrors = action.payload;
    });

    builder.addCase(
      registerNotificationClickAndGetSearchFiltersByHash.rejected,
      (state, action) => {
        state.hashErrors = action.payload;
      }
    );

    builder.addCase(fetchSeoCategoryText.fulfilled, (state, action) => {
      state.seoCategoryText = action?.payload?.text ?? '';
      state.isSeoCategoryTextAllowed = true;
    });

    builder.addCase(fetchSeoCategoryText.rejected, (state, action) => {
      if (
        action.payload &&
        Object.keys(action?.payload).includes(
          'get_seo_category_text_failed_incorrect_id_provided'
        )
      ) {
        state.isSeoCategoryTextAllowed = false;
      }
    });

    builder.addCase(fetchCategoryInfo.fulfilled, (state, action) => {
      state.seoCategoryText = action?.payload?.text ?? '';
      state.isSeoCategoryTextAllowed = true;
    });

    builder.addCase(fetchCategoryInfo.rejected, (state, action) => {
      if (
        action.payload &&
        Object.keys(action?.payload).includes(
          'get_seo_category_text_failed_incorrect_id_provided'
        )
      ) {
        state.isSeoCategoryTextAllowed = false;
      }
    });
  },
});

export const {
  setSearchAsSaved,
  setFilterId,
  removeSearchRecent,
  setSearchFilterScreen,
  setMyKPSearchFilterScreen,
  setSearchFiltersOrigin,
  removeAllSearchRecent,
  resetSearchState,
} = searchSlice.actions;

export default searchSlice;
