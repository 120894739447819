import { apiRequest } from '../api/request/makeRequest';
import { formatSearchParams } from 'src/lib/formatSearchParams';
import type {
  InvoicePayload,
  InvoicePdfPayload,
  InvoicePdfResponse,
  InvoiceResponse,
} from './type';
import type { ThunkAPI } from '@store/configureStore';

const INVOICE_URL = 'invoice';

const fetchInvoices = (thunkAPI: ThunkAPI) => (payload: InvoicePayload) => {
  const searchParamsObj = formatSearchParams(payload.query);
  const searchParams = new URLSearchParams(searchParamsObj).toString();
  return apiRequest<InvoiceResponse>(`${INVOICE_URL}/list?${searchParams}`, {
    method: 'GET',
    thunkAPI,
  });
};

const fetchInvoicePdf =
  (thunkAPI: ThunkAPI) =>
  ({ id }: InvoicePdfPayload) => {
    return apiRequest<InvoicePdfResponse>(`${INVOICE_URL}/pdf/${id}`, {
      method: 'GET',
      thunkAPI,
    });
  };

const requestInvoiceZip = (thunkAPI: ThunkAPI) => () => {
  return apiRequest(`${INVOICE_URL}/pdf/zip`, {
    method: 'GET',
    thunkAPI,
  });
};

export const invoiceAPI = {
  fetchInvoices,
  fetchInvoicePdf,
  requestInvoiceZip,
} as const;
