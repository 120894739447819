import { getUserSystemTheme } from '@lib/theme/getUserSystemTheme';

import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectActiveTheme } from '@store/meta/selector';

export const useSelectedTheme = () => {
  const activeTheme = useAppSelector(selectActiveTheme);

  const selectedTheme =
    activeTheme === 'system' ? getUserSystemTheme() : activeTheme;
  return selectedTheme;
};
