import { apiRequest } from '../api/request/makeRequest';
import type { CustomCarValueResponse } from './type';
import type { ThunkAPI } from '@store/configureStore';

const CUSTOM_VALUE_URL = 'custom-values';

const fetchCarValue = (thunkAPI: ThunkAPI) => () =>
  apiRequest<CustomCarValueResponse>(`${CUSTOM_VALUE_URL}/car`, {
    method: 'GET',
    thunkAPI,
  });

export const CustomValueAPI = {
  fetchCarValue,
} as const;
