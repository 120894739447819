import { HYDRATE } from 'next-redux-wrapper';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { fetchBanners, postBannerShowTrack } from '@store/banner/thunk';

import { BannersInitialState } from './types';
import { generateBackupBanner } from './utils';

const initialState: BannersInitialState = {
  byId: {},
  isLoading: false,
  bannerShowTrack: [],
  excludeBannerTypes: [],
  byIdBackup: generateBackupBanner(),
  isAdBlockDetected: false,
};

const bannerSlice = createSlice({
  name: 'banner',
  initialState,

  reducers: {
    addBannerShowTrack(state, action) {
      state.bannerShowTrack.push(action.payload);
    },
    setAdBlockDetected(state, action) {
      state.isAdBlockDetected = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[bannerSlice.name])) {
        Object.assign(state, action.payload.banner);
      }
    });

    builder.addCase(fetchBanners.pending, (state) => {
      state.byId = initialState.byId;
      state.isLoading = true;
    });

    builder.addCase(fetchBanners.fulfilled, (state, action) => {
      state.byId = action.payload ?? {};
      if (isEmpty(action.payload)) {
        state.byId = { ...state.byId, ...state.byIdBackup };
      }
      state.isLoading = false;
    });

    builder.addCase(fetchBanners.rejected, (state) => {
      state.byId = { ...state.byId, ...state.byIdBackup };
      state.isLoading = false;
    });

    builder.addCase(postBannerShowTrack.pending, (state, action) => {
      state.bannerShowTrack = state.bannerShowTrack.filter((obj) => {
        const { arg } = action.meta;
        // @ts-ignore: temp during migration
        return arg.indexOf(obj.bannerId) !== -1;
      });
    });
  },
});

export const { addBannerShowTrack, setAdBlockDetected } = bannerSlice.actions;

export default bannerSlice;
