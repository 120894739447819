import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import {
  CalculateShippingPricePayload,
  CalculateShippingPriceResponse,
  CheckCourierFieldsPayload,
  FetchCourierSettingsPayload,
  FetchCourierSettingsResponse,
  ScheduleCourierPayload,
} from './type';

const SHIPPING_URL = 'shipping';

const scheduleCourier =
  (thunkAPI: ThunkAPI) => (data: ScheduleCourierPayload) => {
    const { courierId, ...restData } = data;
    return apiRequest(`${SHIPPING_URL}/${courierId}/order-courier`, {
      body: restData,
      method: 'POST',
      thunkAPI,
    });
  };

const fetchCourierSettings =
  (thunkAPI: ThunkAPI) => (data: FetchCourierSettingsPayload) => {
    const { courierId, adId } = data;
    const params = adId ? `?adId=${adId}` : '';
    return apiRequest<FetchCourierSettingsResponse>(
      `${SHIPPING_URL}/${courierId}/settings${params}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );
  };

const calculateShippingPrice =
  (thunkAPI: ThunkAPI) => (data: CalculateShippingPricePayload) => {
    const { courierId, ...restData } = data;
    return apiRequest<CalculateShippingPriceResponse>(
      `${SHIPPING_URL}/${courierId}/calculate-price`,
      {
        body: restData,
        method: 'POST',
        thunkAPI,
      }
    );
  };

const checkCourierFields =
  (thunkAPI: ThunkAPI) => (data: CheckCourierFieldsPayload) => {
    const { courierId, ...restData } = data;
    return apiRequest(`${SHIPPING_URL}/${courierId}/check`, {
      body: restData,
      method: 'POST',
      thunkAPI,
    });
  };

export const ShippingAPI = {
  scheduleCourier,
  fetchCourierSettings,
  calculateShippingPrice,
  checkCourierFields,
} as const;
