import { impression, sendToGTM } from '@lib/gtag';

export const reviewEvents = {
  impression: () => impression('review'),

  successReview: (userType: string) =>
    sendToGTM({
      event: 'review',
      eventAction: 'success',
      eventLabel: userType,
      action: 'success',
      method: userType,
    }),
};
