import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import type {
  PrepaidBalanceResponse,
  UnreadNotificationResponse,
  UserGroupedInfoResponse,
  UserStatsResponse,
} from './type';

const USER_URL = 'me';

const fetchUserStats = (thunkAPI: ThunkAPI) => () =>
  apiRequest<UserStatsResponse>(`${USER_URL}/stats`, {
    method: 'GET',
    thunkAPI,
  });

const fetchUserGroupedInfo = (thunkAPI: ThunkAPI) => () =>
  apiRequest<UserGroupedInfoResponse>(USER_URL, { method: 'GET', thunkAPI });

const fetchPrepaidBalance = (thunkAPI: ThunkAPI) => () =>
  apiRequest<PrepaidBalanceResponse>(`${USER_URL}/prepaid-balance`, {
    method: 'GET',
    thunkAPI,
  });

const fetchUnreadNotification = (thunkAPI: ThunkAPI) => () =>
  apiRequest<UnreadNotificationResponse>(`${USER_URL}/unread`, {
    method: 'GET',
    thunkAPI,
  });

const setSeenWelcomeScreen = (thunkAPI: ThunkAPI) => () =>
  apiRequest(`${USER_URL}/set-seen-welcome-screen`, {
    method: 'GET',
    thunkAPI,
  });

export const MeStatsAPI = {
  fetchUserStats,
  fetchUserGroupedInfo,
  fetchPrepaidBalance,
  fetchUnreadNotification,
  setSeenWelcomeScreen,
} as const;
