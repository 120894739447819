import type {
  AgainstRulesPayload,
  DeleteAdPayload,
  ExportActiveUserAdsPayload,
  FetchAdInfoResponse,
  FetchAdPayload,
  FetchAdsPositionResponse,
  FetchAdTraversePayload,
  FetchAdTraverseResponse,
  MissCatPayload,
  ParkAdPayload,
  ParkAdResponse,
  ReportSpamPayload,
  SetAllAdsDeliveryMethodsPayload,
  UnParkAdPayload,
  UnParkAdResponse,
} from '@store/ad/type';
import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';
import type {
  UserAdsSummaryResponse,
  UserSummaryPayload,
} from '@store/user/type';

const AD_URL = 'eds';

const logAdVisit =
  (thunkAPI: ThunkAPI) =>
  ({ adId }: FetchAdPayload) =>
    apiRequest(`${AD_URL}/${adId}/visit`, { method: 'GET', thunkAPI });

const fetchAdInfo =
  (thunkAPI: ThunkAPI) =>
  ({ adId }: FetchAdPayload) =>
    apiRequest<FetchAdInfoResponse>(`${AD_URL}/${adId}`, {
      method: 'GET',
      thunkAPI,
    });

const fetchAdTraverse =
  (thunkAPI: ThunkAPI) =>
  ({ adId, filterId }: FetchAdTraversePayload) =>
    apiRequest<FetchAdTraverseResponse>(
      `${AD_URL}/${adId}/traverse?filterId=${filterId || 0}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );

const followAd =
  (thunkAPI: ThunkAPI) =>
  ({ adId }: FetchAdPayload) =>
    apiRequest(`${AD_URL}/${adId}/follow`, { method: 'GET', thunkAPI });

const unfollowAd =
  (thunkAPI: ThunkAPI) =>
  ({ adId }: FetchAdPayload) =>
    apiRequest(`${AD_URL}/${adId}/unfollow`, { method: 'GET', thunkAPI });

const againstRules = (thunkAPI: ThunkAPI) => (data: AgainstRulesPayload) =>
  apiRequest(`${AD_URL}/against-rules`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const missCat = (thunkAPI: ThunkAPI) => (data: MissCatPayload) =>
  apiRequest(`${AD_URL}/miss-cat`, { method: 'POST', body: data, thunkAPI });

const reportSpam = (thunkAPI: ThunkAPI) => (data: ReportSpamPayload) =>
  apiRequest(`${AD_URL}/report-spam`, { method: 'POST', body: data, thunkAPI });

const deleteAd = (thunkAPI: ThunkAPI) => (data: DeleteAdPayload) =>
  apiRequest(`${AD_URL}/${data.adId}/delete`, {
    method: 'POST',
    body: { data },
    thunkAPI,
  });

const parkAd = (thunkAPI: ThunkAPI) => (data: ParkAdPayload) =>
  apiRequest<ParkAdResponse>(`${AD_URL}/${data.adId}/park`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const unparkAd = (thunkAPI: ThunkAPI) => (data: UnParkAdPayload) =>
  apiRequest<UnParkAdResponse>(`${AD_URL}/${data.adId}/unpark`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const exportActiveUserAds =
  (thunkAPI: ThunkAPI) =>
  ({ format }: ExportActiveUserAdsPayload) =>
    apiRequest(`export-active-user-ads/${format}`, {
      method: 'GET',
      thunkAPI,
    });

const fetchAdsPosition = (thunkAPI: ThunkAPI) => (body: string[] | any) =>
  apiRequest<FetchAdsPositionResponse>(`${AD_URL}/position`, {
    method: 'POST',
    body,
    thunkAPI,
  });

const parkAllAds = (thunkAPI: ThunkAPI) => () =>
  apiRequest(`${AD_URL}/park-user-eds`, {
    method: 'POST',
    thunkAPI,
  });

const unparkAllAds = (thunkAPI: ThunkAPI) => () =>
  apiRequest(`${AD_URL}/unpark-user-eds`, {
    method: 'POST',
    thunkAPI,
  });

const fetchUserAdsSummary =
  (thunkAPI: ThunkAPI) =>
  ({ userId }: UserSummaryPayload) =>
    apiRequest<UserAdsSummaryResponse>(`${AD_URL}/user-summary/${userId}`, {
      method: 'GET',
      thunkAPI,
    });

const setAllAdsDeliveryMethods =
  (thunkAPI: ThunkAPI) => (body: SetAllAdsDeliveryMethodsPayload) =>
    apiRequest(`${AD_URL}/activate-delivery-method-user-eds`, {
      method: 'POST',
      body,
      thunkAPI,
    });

export const AdAPI = {
  fetchAdInfo,
  logAdVisit,
  fetchAdTraverse,
  followAd,
  unfollowAd,
  againstRules,
  missCat,
  reportSpam,
  deleteAd,
  parkAd,
  unparkAd,
  exportActiveUserAds,
  fetchAdsPosition,
  parkAllAds,
  unparkAllAds,
  fetchUserAdsSummary,
  setAllAdsDeliveryMethods,
} as const;
