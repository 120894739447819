import { createAppAsyncThunk } from '@store/global/utils';

import { CategoryInfoAPI } from './api';
import { CategoryInfoPayload } from './type';

export const fetchCategoryInfo = createAppAsyncThunk(
  'categoryInfo/fetchCategoryInfo',
  async ({ categoryId }: CategoryInfoPayload, thunkAPI) => {
    const response = await CategoryInfoAPI.fetchCategoryInfo(thunkAPI)({
      categoryId,
    });

    return response.data.results;
  }
);
