import isArray from 'lodash/isArray';

import { createAppAsyncThunk } from '@store/global/utils';
import { KpIzlogAPI } from '@store/kpIzlog/api';
import { fetchPrepaid } from '@store/meStats/thunk';

import { showToast } from '../modal/slice';

import {
  KpIzlogCreatePayload,
  KpIzlogExtendPayload,
  KpIzlogIdentPayload,
} from './type';

export const fetchKpIzlog = createAppAsyncThunk(
  'kpIzlog/fetchKpIzlog',
  async (categoryId: number, thunkAPI) => {
    const { data } = await KpIzlogAPI.fetchKpIzlog(thunkAPI)(categoryId);

    return data.results;
  }
);

export const fetchKpIzlogStat = createAppAsyncThunk(
  'kpIzlog/fetchKpIzlogStat',
  async (_, thunkAPI) => {
    const { data } = await KpIzlogAPI.fetchKpIzlogStat(thunkAPI)();

    return data.results;
  }
);

export const checkKpIzlogIdent = createAppAsyncThunk(
  'kpIzlog/checkKpIzlogIdent',
  async (payload: KpIzlogIdentPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await KpIzlogAPI.checkKpIzlogIdent(thunkAPI)(payload);

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const createKpIzlog = createAppAsyncThunk(
  'kpIzlog/createKpIzlog',
  async (payload: KpIzlogCreatePayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    const { terms } = payload;
    try {
      const { data } = await KpIzlogAPI.createKpIzlog(thunkAPI)({
        ...payload,
        ...(terms && { terms: isArray ? (terms as []).join('') : terms }),
      });

      if (data.success) {
        dispatch(fetchKpIzlogStat());
        dispatch(fetchPrepaid());
        dispatch(showToast({ content: 'Uspešno ste aktivirali KP Izlog' }));
      }

      return data.results;
    } catch (error) {
      const { errors } = error.response.data;
      if (errors?.[0]?.error_code === 'funds') {
        dispatch(
          showToast({
            content: 'Nemate dovoljno KP Kredita',
            type: 'error',
          })
        );
      }
      return rejectWithValue(errors);
    }
  }
);

export const extendKpIzlog = createAppAsyncThunk(
  'kpIzlog/extendKpIzlog',
  async (payload: KpIzlogExtendPayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await KpIzlogAPI.extendKpIzlog(thunkAPI)(payload);

      if (data.success) {
        dispatch(fetchKpIzlogStat());
        dispatch(fetchPrepaid());
        dispatch(showToast({ content: 'Uspešno ste produžili KP Izlog' }));
      }

      return data.results;
    } catch (error) {
      const { errors } = error.response.data;
      if (errors?.[0]?.error_code === 'funds') {
        dispatch(
          showToast({
            content: 'Nemate dovoljno KP Kredita',
            type: 'error',
          })
        );
      }
      return rejectWithValue(errors);
    }
  }
);
