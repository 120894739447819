/* eslint-disable no-console */
import isServer from '@lib/ssrSetup/isServer';

import { LocalStorageAPI } from './localStorage';

interface SendToGTMParams {
  event: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  type?: string;
  action?: string;
  method?: string;
}

type GTM4Params<T> = T & { [key: string]: unknown };

export const sendToGTM = (
  data = {
    event: '',
    eventCategory: '',
    eventAction: '',
    eventLabel: '',
    type: '',
    action: '',
    method: '',
  } as SendToGTMParams
) => {
  try {
    const dataToPush = {
      event: '',
      eventCategory: data.eventCategory ?? data.event,
      eventAction: '',
      eventLabel: '',
      ...data,
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataToPush);

    try {
      const dataLayerDebug =
        LocalStorageAPI.getWithExpiry('data-layer-debug') || [];

      dataLayerDebug.push(dataToPush);
      LocalStorageAPI.setWithExpiry('data-layer-debug', dataLayerDebug, 10000);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

export const sendToGTM4 = (dataToPush: GTM4Params<{ event: string }>) => {
  try {
    if (isServer) return;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(dataToPush);

    try {
      const dataLayerDebug =
        LocalStorageAPI.getWithExpiry('data-layer-debug') || [];

      dataLayerDebug.push(dataToPush);
      LocalStorageAPI.setWithExpiry('data-layer-debug', dataLayerDebug, 10000);
    } catch (e) {
      console.error(e);
    }
  } catch (e) {
    console.error('dataToPush:', e);
  }
};

export const impression = (eventName: string) =>
  sendToGTM({
    event: eventName,
    eventCategory: eventName,
    eventAction: 'impression',
    type: 'impression',
  });

export const sendToFacebook = ({ event = '', action = '', type = '' }) => {
  const data = {
    ...(action && { action }),
    ...(type && { type }),
  };

  if (window?.fbq) {
    window.fbq('track', event, data);
  }
};
