/* eslint-disable no-console */
// eslint-disable-next-line consistent-return
export const getUserSystemTheme = () => {
  try {
    const preferedTheme =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;

    return preferedTheme ? 'dark' : 'light';
  } catch (error) {
    // console.error(error);
  }
};
