/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AdSaveHiddenFields,
  AdSaveStepFourFields,
  AdSaveStepOneFields,
  AdSaveStepOneGroupSuggestionFields,
  AdSaveStepThreeFields,
  AdSaveStepTwoCarFields,
  AdSaveStepTwoDeliveryMethodsFields,
  AdSaveStepTwoFields,
  AdSaveStepTwoImmediateAvailableField,
  AdSaveStepTwoJobFields,
} from '@store/adSave/type';

export const stepOneFields: AdSaveStepOneFields = {
  kind: '',
  categoryId: '',
  groupId: '',
};

export const stepOneGroupSuggestionFields: AdSaveStepOneGroupSuggestionFields =
  {
    suggestedGroups: [],
    used: 0,
    groupSuggestId: '',
    groupSuggestInputText: '',
    groupSuggestText: '',
    groupRecentId: '',
  };

export const stepTwoFields: AdSaveStepTwoFields = {
  name: '',
  type: '',
  price: '',
  currency: '',
  priceText: '',
  priceFixed: '',
  description: '',
  condition: '',
  exchange: '',
  locationId: '',
  owner: '',
  phone: '',
  photos: [],
  kpizlog: '',
  'h-captcha-response': '',
  query: '',
  phoneNumberSelected: '',
  isbn: 0,
};

export const stepTwoJobFields: AdSaveStepTwoJobFields = {
  jobApplicationType: '',
  jobApplicationEmail: '',
  jobApplicationLink: '',
  jobApplicationPhone: '',
};

export const stepTwoCarFields: AdSaveStepTwoCarFields = {
  vehicleMakeYear: '',
  vehicleKm: '',
  vehicleFuelType: '',
  carBodyType: '',
  vehiclePower: '',
  vehiclePowerH: '',
  vehicleCc: '',
  carDoors: '',
  vehicleSeats: '',
  carGearbox: '',
  vehicleAircondition: '',
  vehicleOrigin: '',
  vehicleOwnerType: '',
  vehicleExteriorColor: '',
  vehicleDrive: '',
  carModel: '',
  carModelDesc: '',
  vehicleRegisteredDate: '',
  vehicleEmissionClass: '',
  carOptions: '',
  vehicleBoughtInSerbia: '',
  vehicleFirstOwner: '',
  vehicleWarranty: '',
  vehicleDisabledPerson: '',
};

export const stepThreeFields: AdSaveStepThreeFields = {
  promoType: '',
  promoAdcreate: '',
  highlighted: '',
  promoLink: '',
  website: '',
  promoVideo: '',
  videoUrl: '',
  service_top_price_displayed: '',
  service_top_search_price_displayed: '',
};

export const stepFourFields: AdSaveStepFourFields = {
  declarationType: '',
  accept: '',
  agreement: '',
  dCompanyName: '',
  dAddress: '',
  dRegistrationNumber: '',
  swear: '',
  regNumberRealEstateAgentId: '',
  regNumberHid: '',
  regNumberHealthcareLicenseId: '',
  regNumberApartmentsCategorizationId: '',
  regNumberPetOwnerId: '',
};

export const hiddenFields: AdSaveHiddenFields = {
  adId: '',
  adClass: '',
};

export const stepTwoDeliveryMethodsFields: AdSaveStepTwoDeliveryMethodsFields =
  {
    courierDelivery: '',
    localPickup: '',
  };
export const stepTwoImmediateAvailableField: AdSaveStepTwoImmediateAvailableField =
  {
    immediateAvailable: '',
  };

export const getAdSaveSteps = (isEditAd) => {
  const declarationStep = isEditAd ? 2 : 3;

  return {
    0: stepOneFields,
    1: stepTwoFields,
    car: stepTwoCarFields,
    job: stepTwoJobFields,
    deliveryMethods: stepTwoDeliveryMethodsFields,
    immediateAvailable: stepTwoImmediateAvailableField,
    ...(!isEditAd && { 2: stepThreeFields }),
    [declarationStep]: stepFourFields,
  };
};

export const getAdSaveCheckFields = ({
  adClass,
  isEditAd,
  activeStep,
  isDeliveryMethodAllowed,
  isImmediateAvailableAllowed,
}) => {
  // Check all fields for edit ad
  const checkToStep = isEditAd ? 2 : activeStep;
  const adSaveSteps = getAdSaveSteps(isEditAd);
  const { groupSuggestInputText, ...restOneGroupSuggestionFields } =
    stepOneGroupSuggestionFields;

  let checkFields = {
    ...adSaveSteps[0],
    ...hiddenFields,
    ...(checkToStep > 0 && adSaveSteps[adClass]),
    ...(checkToStep > 0 &&
      isDeliveryMethodAllowed &&
      stepTwoDeliveryMethodsFields),
    ...(checkToStep > 0 &&
      isImmediateAvailableAllowed &&
      stepTwoImmediateAvailableField),
    ...restOneGroupSuggestionFields,
  };

  for (let i = checkToStep; i >= 0; i -= 1) {
    checkFields = {
      ...checkFields,
      ...adSaveSteps[i],
    };
  }

  return Object.keys(checkFields).reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {});
};

export const getAdSaveFieldsPosition = (isEditAd) => {
  const steps = getAdSaveSteps(isEditAd);
  const specialKeys = ['job', 'car', 'deliveryMethods'];
  return Object.entries(steps).reduce(
    (acc, [key, val]) => {
      const fields = Object.fromEntries(
        Object.entries(val).map(([k]) => [
          k,
          specialKeys.includes(key) ? 1 : Number(key),
        ])
      );
      return { ...acc, ...fields };
    },
    { captcha: 1 }
  );
};
