import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import {
  FetchShippingOrderHistoryPayload,
  FetchShippingOrderHistoryResponse,
  FetchShippingOrderStatusResponse,
  FetchShippingStatusPayload,
} from './type';

const SHIPPING_URL = 'shipping';

const fetchShippingOrderStatus =
  (thunkAPI: ThunkAPI) => (body: FetchShippingStatusPayload) => {
    return apiRequest<FetchShippingOrderStatusResponse>(
      `${SHIPPING_URL}/tracking-code-status`,
      {
        method: 'POST',
        thunkAPI,
        body,
      }
    );
  };

const fetchShippingOrderHistory =
  (thunkAPI: ThunkAPI) =>
  ({ page = '1' }: FetchShippingOrderHistoryPayload) => {
    return apiRequest<FetchShippingOrderHistoryResponse>(
      `${SHIPPING_URL}/history?page=${page}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );
  };

export const ShippingOrderStatusAPI = {
  fetchShippingOrderStatus,
  fetchShippingOrderHistory,
} as const;
