import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { fetchGeneralGroupedInfo } from '@store/generalStats/thunk';
import { CurrencyConverterInitialState, RateObject } from './type';
import { normalize } from 'src/lib/normalize';

const initialState: CurrencyConverterInitialState = {
  rates: {} as RateObject,
  isLoading: true,
};

const currencyConverterSlice = createSlice({
  name: 'currencyConverter',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[currencyConverterSlice.name])) {
        Object.assign(state, action.payload.currencyConverter);
      }
    });

    builder.addCase(fetchGeneralGroupedInfo.fulfilled, (state, action) => {
      const { nbsRates = initialState.rates } = action.payload;
      state.rates = normalize(nbsRates, 'id');
      state.isLoading = false;
    });
  },
});

export default currencyConverterSlice;
