import isEmpty from 'lodash/isEmpty';
import Bowser from 'bowser';

const isSupportedOS = (userAgent) => {
  if (isEmpty(userAgent)) {
    return false;
  }

  const browser = Bowser.getParser(userAgent);
  // The full list of aliases for OS Version: https://github.com/lancedikson/bowser/blob/f09411489ced05811c91cc6670a8e4ca9cbe39a7/src/utils.js
  const isSupported = !['XP', 'Vista'].includes(browser.getOS().versionName);

  return isSupported;
};

export default isSupportedOS;
