import { IncomingMessage } from 'http';

const getIpAddress = (req: IncomingMessage): string | undefined => {
  if (Array.isArray(req.headers['x-forwarded-for'])) {
    return req.headers['x-forwarded-for'][0];
  }
  return req.headers['x-forwarded-for'] || req.socket.remoteAddress;
};

export default getIpAddress;
