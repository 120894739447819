import { FormikValues } from 'formik';

import { Any } from '@store/global/type';

type GetAdditionalParamsByCategoryAndGroup = {
  groups: Record<string, Any>;
  categoryId: number;
  groupId: number;
};

type GetParamsByCategoryAndGroupProps =
  GetAdditionalParamsByCategoryAndGroup & {
    values: FormikValues['values'];
  };

const getAdClass = ({
  values,
  groups,
  categoryId,
  groupId,
}: GetParamsByCategoryAndGroupProps) => {
  if (categoryId && groupId) {
    return (
      groups?.[categoryId]?.[groupId]?.adClass ?? values.adClass ?? 'basic'
    );
  }
  return values.adClass ?? 'basic';
};

const getIsDeliveryMethodAllowed = ({
  groups,
  categoryId,
  groupId,
}: GetAdditionalParamsByCategoryAndGroup) => {
  if (categoryId && groupId) {
    return groups?.[categoryId]?.[groupId]?.isDeliveryMethodAllowed ?? false;
  }
  return false;
};

const getIsImmediateAvailableAllowed = ({
  groups,
  categoryId,
  groupId,
}: GetAdditionalParamsByCategoryAndGroup) => {
  if (categoryId && groupId) {
    return (
      groups?.[categoryId]?.[groupId]?.isImmediateAvailableAllowed ?? false
    );
  }
  return false;
};

export const getParamsByCategoryAndGroup = async ({
  values,
  groups,
  categoryId,
  groupId,
}: GetParamsByCategoryAndGroupProps) => {
  const adClass = getAdClass({ values, groups, categoryId, groupId });
  const isDeliveryMethodAllowed = getIsDeliveryMethodAllowed({
    groups,
    categoryId,
    groupId,
  });
  const isImmediateAvailableAllowed = getIsImmediateAvailableAllowed({
    groups,
    categoryId,
    groupId,
  });

  return { adClass, isDeliveryMethodAllowed, isImmediateAvailableAllowed };
};
