import isString from 'lodash/isString';

import { Any } from '@store/global/type';

export const createBase64 = (valueToConvert: Any) => {
  if (isString(valueToConvert)) {
    return Buffer.from(valueToConvert).toString('base64');
  }
  return Buffer.from(JSON.stringify(valueToConvert)).toString('base64');
};
