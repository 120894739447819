/* eslint-disable array-callback-return */
import { delay } from 'src/lib/delay';

import { FeedbackAPI } from '@store/feedback/api';
import { createAppAsyncThunk } from '@store/global/utils';

import { FeedbackThreadPayload, PreMappedSaveFeedbackPayload } from './type';

export const fetchFeedbackThread = createAppAsyncThunk(
  'feedback/fetchFeedbackThread',
  async (payload: FeedbackThreadPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await FeedbackAPI.fetchFeedbackThread(thunkAPI)(payload);

      return data.results;
    } catch (e) {
      if (e?.response?.data?.errors) {
        const { errors = '' } = e.response.data;
        errors.map((error) => {
          if (error.error_code === 'security_key_missmatch') {
            window.location.href = '/404';
          }
        });
      }
      return rejectWithValue(e?.response?.data?.errors);
    }
  }
);

export const fetchFeedbackSettings = createAppAsyncThunk(
  'feedback/fetchFeedbackSettings',
  async (_, thunkAPI) => {
    const { data } = await FeedbackAPI.fetchFeedbackSettings(thunkAPI)();

    return data.results;
  }
);

export const saveFeedback = createAppAsyncThunk(
  'feedback/saveFeedback',
  async (payload: PreMappedSaveFeedbackPayload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    const { files } = payload;
    try {
      const acceptedFiles = files.reduce((result, item, index) => {
        result[index + 1] = {
          [`file_name${index + 1}`]: item.file_name,
          [`file_path${index + 1}`]: item.file_path,
        };
        return result;
      }, {});

      const feedbackData = { ...payload, files: { ...acceptedFiles } };
      const { data } = await FeedbackAPI.saveFeedback(thunkAPI)(feedbackData);

      if (!payload.feedbackId) {
        dispatch(fetchFeedbackSettings());
        await delay(200);
      } else {
        dispatch(
          fetchFeedbackThread({ query: { feedbackId: payload.feedbackId } })
        );
      }

      return data.results;
    } catch (error) {
      dispatch(fetchFeedbackSettings());
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const fetchFeedbackInterceptor = createAppAsyncThunk(
  'feedback/fetchFeedbackInterceptor',
  async (_, thunkAPI) => {
    const { data } = await FeedbackAPI.fetchFeedbackInterceptor(thunkAPI)();

    return data.results;
  }
);
