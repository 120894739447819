import { apiRequest } from '@store/api/request/makeRequest';
import { formatSearchParams } from 'src/lib/formatSearchParams';
import { searchEvents } from '@lib/events/search.event';
import type { SearchPayload, SearchResponse } from './type';
import type { ThunkAPI } from '@store/configureStore';

const SEARCH_URL = 'search';

const fetchSearchedAds = (thunkAPI: ThunkAPI) => (payload: SearchPayload) => {
  const searchParamsObj = { ...formatSearchParams(payload.query) };
  const searchParams = new URLSearchParams(searchParamsObj).toString();

  searchEvents.search({ filters: searchParamsObj });

  return apiRequest<SearchResponse>(`${SEARCH_URL}?${searchParams}`, {
    method: 'GET',
    thunkAPI,
  });
};

export const searchAPI = {
  fetchSearchedAds,
} as const;
