import { FraudAPI } from '@store/fraud/api';
import { createAppAsyncThunk } from '@store/global/utils';

import { toggleModal } from '../modal/slice';

import { FraudReportPayload } from './type';

export const submitFraudReport = createAppAsyncThunk(
  'fraud/submitFraudReport',
  async (payload: FraudReportPayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await FraudAPI.report(thunkAPI)(payload);

      if (data.success) {
        dispatch(toggleModal('[FRAUD] Success Report'));
      }

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);
