import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { fetchCategoryInfo } from './thunk';
import { CategoryInfoInitialState } from './type';

export const initialState: CategoryInfoInitialState = {
  isLoading: false,
  count: {
    totalGoods: 0,
    totalService: 0,
    totalJob: 0,
    totalKpWindows: 0,
  },
};

const categoryInfoSlice = createSlice({
  name: 'categoryInfo',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[categoryInfoSlice.name])) {
        Object.assign(state, action.payload.categoryInfo);
      }
    });

    builder.addCase(fetchCategoryInfo.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCategoryInfo.fulfilled, (state, action) => {
      const {
        totalGoods = 0,
        totalServices = 0,
        totalJobs = 0,
        totalKpWindows = 0,
      } = action.payload;

      state.count = {
        totalGoods,
        totalService: totalServices,
        totalJob: totalJobs,
        totalKpWindows,
      };
      state.isLoading = false;
    });
  },
});

export default categoryInfoSlice;
