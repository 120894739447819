import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { normalize } from 'src/lib/normalize';

import { extractByKey } from '@lib/extractByKey';
import { createLocation } from '@store/location/model';
import { fetchGeneralGroupedInfo } from '@store/generalStats/thunk';
import { fetchLocationsUserFavorites } from '@store/location/thunk';
import { LocationInitialState } from './type';

const initialState: LocationInitialState = {
  byId: {},
  locationIds: [],
  byIdUserFavorites: {},
  locationIdsUserFavoritesIds: [],
  isLoading: false,
  isLoadingUserFavorites: false,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[locationSlice.name])) {
        Object.assign(state, action.payload.location);
      }
    });

    builder.addCase(fetchLocationsUserFavorites.pending, (state) => {
      state.isLoadingUserFavorites = true;
    });

    builder.addCase(fetchLocationsUserFavorites.fulfilled, (state, action) => {
      state.byIdUserFavorites = normalize(action.payload, 'id');
      state.locationIdsUserFavoritesIds = extractByKey(action.payload, 'id');
      state.isLoadingUserFavorites = false;
    });

    builder.addCase(fetchGeneralGroupedInfo.fulfilled, (state, action) => {
      const { locations = [] } = action.payload;
      const allLocations = locations.map((location) =>
        createLocation(location)
      );
      state.byId = normalize(allLocations, 'id');
      state.locationIds = extractByKey(allLocations, 'id');
      state.isLoading = false;
    });
  },
});

export default locationSlice;
