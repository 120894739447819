import { useCallback, useEffect } from 'react';

import { getUserSystemTheme } from '@lib/theme/getUserSystemTheme';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectIsUserLogged } from '@store/auth/selector';
import { selectActiveTheme } from '@store/meta/selector';
import { setActiveTheme } from '@store/meta/slice';

export default function useTheme({ isEnabled }) {
  const activeTheme = useAppSelector(selectActiveTheme);
  const isUserLogged = useAppSelector(selectIsUserLogged);
  const dispatch = useAppDispatch();

  const selectedTheme =
    activeTheme !== 'system' ? activeTheme : getUserSystemTheme();

  const initTheme = useCallback(() => {
    if (!isEnabled || !isUserLogged) {
      return;
    }

    try {
      const themeFromStorage = window.localStorage.getItem('theme') ?? 'system';
      const active = selectedTheme ?? themeFromStorage;

      document.body.dataset.theme = active;
      document.documentElement.setAttribute('data-theme', active);
      dispatch(setActiveTheme(themeFromStorage));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [isEnabled, isUserLogged, selectedTheme, dispatch]);

  useEffect(() => {
    initTheme();
  }, [initTheme]);
}
