const AppleStoreLogo = () => {
  return (
    <svg
      width="120"
      height="40"
      viewBox="0 0 120 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110.134 0.00013H9.53465C9.16795 0.00013 8.80565 0.00013 8.43992 0.00212999C8.13377 0.00412999 7.83006 0.00993997 7.52098 0.01483C6.84949 0.0227317 6.17959 0.081806 5.51708 0.191539C4.85551 0.303662 4.21466 0.515044 3.61621 0.818537C3.01849 1.1246 2.47234 1.52229 1.99756 1.99719C1.5203 2.47076 1.12246 3.01814 0.819347 3.61828C0.515399 4.21723 0.304639 4.85905 0.194349 5.52159C0.0830107 6.1833 0.0230983 6.85263 0.01515 7.52359C0.00586998 7.83018 0.00488998 8.1378 0 8.44444V31.5586C0.00488998 31.8691 0.00586998 32.1699 0.01515 32.4805C0.0231007 33.1514 0.0830131 33.8207 0.194349 34.4824C0.304335 35.1453 0.515106 35.7875 0.819347 36.3867C1.12232 36.9849 1.52022 37.5301 1.99756 38.001C2.47054 38.478 3.01704 38.876 3.61621 39.1797C4.21465 39.484 4.85543 39.6967 5.51708 39.8105C6.1797 39.9194 6.84953 39.9785 7.52098 39.9873C7.83006 39.9941 8.13377 39.998 8.43992 39.998C8.80564 40 9.16797 40 9.53465 40H110.134C110.494 40 110.859 40 111.218 39.998C111.523 39.998 111.836 39.9941 112.14 39.9873C112.81 39.9789 113.479 39.9198 114.14 39.8105C114.804 39.6959 115.447 39.4833 116.048 39.1797C116.647 38.8758 117.193 38.4779 117.666 38.001C118.142 37.5282 118.541 36.9835 118.847 36.3867C119.149 35.7871 119.358 35.145 119.466 34.4824C119.578 33.8207 119.64 33.1515 119.652 32.4805C119.656 32.1699 119.656 31.8691 119.656 31.5586C119.664 31.1953 119.664 30.834 119.664 30.4648V9.53623C119.664 9.17002 119.664 8.80674 119.656 8.44444C119.656 8.1378 119.656 7.83018 119.652 7.52355C119.64 6.85253 119.578 6.18335 119.466 5.52155C119.358 4.8594 119.149 4.21762 118.847 3.61824C118.23 2.41532 117.251 1.43615 116.048 0.818447C115.447 0.515696 114.804 0.304369 114.14 0.191449C113.479 0.0812325 112.811 0.0221377 112.14 0.01469C111.836 0.00980997 111.523 0.00394999 111.218 0.00199999C110.859 0 110.494 0 110.134 0V0.00013Z"
        fill="#A6A6A6"
      />
      <path
        d="M8.44356 39.125C8.13888 39.125 7.84156 39.1211 7.53927 39.1143C6.91306 39.1061 6.2883 39.0516 5.67014 38.9512C5.09373 38.8519 4.53535 38.6673 4.01341 38.4033C3.49625 38.1415 3.02456 37.7983 2.61641 37.3867C2.20235 36.98 1.85763 36.5083 1.5959 35.9902C1.33129 35.4688 1.14817 34.9099 1.0529 34.333C0.950029 33.7131 0.894369 33.0863 0.886405 32.458C0.880065 32.2471 0.871765 31.5449 0.871765 31.5449V8.44444C0.871765 8.44444 0.880605 7.75303 0.886455 7.5499C0.894081 6.92258 0.949417 6.29676 1.05198 5.67784C1.14742 5.09936 1.33069 4.53886 1.59544 4.01574C1.85621 3.49806 2.19902 3.02598 2.61058 2.6178C3.02168 2.20574 3.49488 1.86072 4.01292 1.59533C4.53366 1.33221 5.09099 1.14885 5.66623 1.05139C6.28643 0.949958 6.91335 0.895118 7.54173 0.887332L8.44405 0.875122H111.212L112.125 0.887822C112.748 0.895221 113.369 0.949572 113.984 1.05041C114.565 1.1491 115.128 1.33374 115.655 1.59826C116.692 2.1331 117.537 2.97928 118.07 4.01818C118.33 4.53769 118.511 5.09362 118.605 5.6671C118.709 6.2911 118.767 6.92184 118.779 7.5543C118.782 7.8375 118.782 8.1417 118.782 8.44444C118.789 8.81944 118.789 9.17636 118.789 9.53622V30.4648C118.789 30.8281 118.789 31.1826 118.782 31.54C118.782 31.8652 118.782 32.1631 118.778 32.4697C118.766 33.0909 118.709 33.7104 118.607 34.3232C118.513 34.9043 118.331 35.4675 118.067 35.9932C117.803 36.5056 117.46 36.9733 117.051 37.3789C116.643 37.7927 116.17 38.1379 115.652 38.4014C115.127 38.6674 114.564 38.8527 113.984 38.9512C113.366 39.0522 112.741 39.1067 112.115 39.1143C111.822 39.1211 111.515 39.125 111.217 39.125L110.133 39.127L8.44356 39.125Z"
        fill="black"
      />
      <path
        d="M24.7763 20.3008C24.7871 19.4661 25.0088 18.6478 25.4208 17.9218C25.8328 17.1959 26.4218 16.5859 27.1329 16.1488C26.6811 15.5036 26.0852 14.9727 25.3924 14.5981C24.6996 14.2236 23.9289 14.0157 23.1417 13.9911C21.4625 13.8148 19.8346 14.9959 18.9788 14.9959C18.1066 14.9959 16.7891 14.0086 15.3704 14.0378C14.4527 14.0674 13.5583 14.3343 12.7745 14.8123C11.9906 15.2904 11.3439 15.9633 10.8975 16.7656C8.96346 20.114 10.406 25.0351 12.2586 27.7417C13.1855 29.067 14.2688 30.5475 15.6863 30.495C17.0733 30.4374 17.5914 29.6105 19.2657 29.6105C20.9244 29.6105 21.4104 30.495 22.8566 30.4616C24.345 30.4374 25.2828 29.1303 26.1771 27.7925C26.8431 26.8481 27.3556 25.8044 27.6956 24.7C26.8309 24.3342 26.0929 23.722 25.5738 22.9398C25.0547 22.1575 24.7773 21.2397 24.7763 20.3008Z"
        fill="white"
      />
      <path
        d="M22.0461 12.2111C22.8576 11.2369 23.2574 9.98469 23.1606 8.72046C21.9208 8.85068 20.7755 9.44324 19.9531 10.3801C19.5509 10.8377 19.2429 11.3702 19.0467 11.9469C18.8504 12.5237 18.7698 13.1335 18.8093 13.7414C19.4295 13.7478 20.043 13.6134 20.6036 13.3483C21.1643 13.0832 21.6575 12.6944 22.0461 12.2111Z"
        fill="white"
      />
      <path
        d="M42.3053 27.1396H37.572L36.4352 30.4961H34.4304L38.9137 18.0781H40.9967L45.4801 30.4961H43.4411L42.3053 27.1396ZM38.0622 25.5908H41.8142L39.9646 20.1436H39.9128L38.0622 25.5908Z"
        fill="white"
      />
      <path
        d="M55.1611 25.9698C55.1611 28.7833 53.6553 30.5909 51.3828 30.5909C50.8072 30.621 50.2347 30.4884 49.7309 30.2083C49.2271 29.9281 48.8124 29.5118 48.5342 29.0069H48.4912V33.4912H46.6328V21.4425H48.4317V22.9483H48.4658C48.7568 22.4459 49.1785 22.0317 49.6862 21.7498C50.1938 21.468 50.7683 21.329 51.3486 21.3477C53.6465 21.3477 55.1611 23.1641 55.1611 25.9698ZM53.251 25.9698C53.251 24.1368 52.3037 22.9317 50.8584 22.9317C49.4385 22.9317 48.4834 24.1622 48.4834 25.9698C48.4834 27.794 49.4385 29.0157 50.8584 29.0157C52.3037 29.0157 53.251 27.8194 53.251 25.9698Z"
        fill="white"
      />
      <path
        d="M65.1268 25.9698C65.1268 28.7833 63.621 30.5909 61.3485 30.5909C60.7729 30.621 60.2004 30.4884 59.6966 30.2082C59.1928 29.9281 58.7781 29.5118 58.4999 29.0069H58.4569V33.4912H56.5985V21.4425H58.3973V22.9483H58.4315C58.7225 22.4459 59.1442 22.0317 59.6518 21.7498C60.1594 21.468 60.734 21.329 61.3143 21.3477C63.6122 21.3477 65.1268 23.1641 65.1268 25.9698ZM63.2167 25.9698C63.2167 24.1368 62.2694 22.9317 60.8241 22.9317C59.4042 22.9317 58.4491 24.1622 58.4491 25.9698C58.4491 27.794 59.4042 29.0157 60.8241 29.0157C62.2694 29.0157 63.2167 27.8194 63.2167 25.9698H63.2167Z"
        fill="white"
      />
      <path
        d="M71.7107 27.0362C71.8484 28.2676 73.0447 29.0761 74.6794 29.0761C76.2458 29.0761 77.3727 28.2676 77.3727 27.1572C77.3727 26.1933 76.6931 25.6162 75.0837 25.2207L73.4743 24.833C71.1941 24.2822 70.1355 23.2158 70.1355 21.4853C70.1355 19.3428 72.0027 17.8711 74.654 17.8711C77.278 17.8711 79.0768 19.3428 79.1374 21.4853H77.2614C77.1491 20.2461 76.1247 19.498 74.6276 19.498C73.1306 19.498 72.1061 20.2549 72.1061 21.3564C72.1061 22.2344 72.7604 22.751 74.361 23.1464L75.7292 23.4824C78.277 24.0849 79.3356 25.1084 79.3356 26.9247C79.3356 29.248 77.485 30.7031 74.5417 30.7031C71.7878 30.7031 69.9284 29.2822 69.8083 27.0361L71.7107 27.0362Z"
        fill="white"
      />
      <path
        d="M83.3479 19.2999V21.4425H85.0696V22.9142H83.3479V27.9054C83.3479 28.6808 83.6926 29.0421 84.4494 29.0421C84.6538 29.0385 84.8579 29.0242 85.0608 28.9991V30.462C84.7205 30.5255 84.3746 30.5543 84.0285 30.5479C82.1956 30.5479 81.4807 29.8594 81.4807 28.1036V22.9142H80.1643V21.4425H81.4807V19.2999H83.3479Z"
        fill="white"
      />
      <path
        d="M86.0681 25.9697C86.0681 23.1211 87.7458 21.3311 90.362 21.3311C92.987 21.3311 94.6569 23.121 94.6569 25.9697C94.6569 28.8262 92.9958 30.6084 90.362 30.6084C87.7291 30.6084 86.0681 28.8261 86.0681 25.9697ZM92.7633 25.9697C92.7633 24.0156 91.8678 22.8623 90.362 22.8623C88.8561 22.8623 87.9616 24.0244 87.9616 25.9697C87.9616 27.9316 88.8561 29.0761 90.362 29.0761C91.8678 29.0761 92.7633 27.9316 92.7633 25.9697H92.7633Z"
        fill="white"
      />
      <path
        d="M96.1881 21.4424H97.9605V22.9834H98.0035C98.1234 22.5021 98.4054 22.0768 98.802 21.7789C99.1986 21.481 99.6856 21.3287 100.181 21.3476C100.395 21.3469 100.609 21.3702 100.818 21.417V23.1553C100.548 23.0726 100.266 23.0347 99.983 23.043C99.713 23.032 99.4438 23.0796 99.194 23.1825C98.9441 23.2854 98.7195 23.4411 98.5356 23.639C98.3516 23.8369 98.2126 24.0723 98.1282 24.3289C98.0438 24.5856 98.0159 24.8575 98.0464 25.126V30.4961H96.188L96.1881 21.4424Z"
        fill="white"
      />
      <path
        d="M109.387 27.8369C109.137 29.4804 107.536 30.6084 105.489 30.6084C102.855 30.6084 101.22 28.8437 101.22 26.0127C101.22 23.1728 102.864 21.3311 105.41 21.3311C107.915 21.3311 109.49 23.0517 109.49 25.7969V26.4336H103.096V26.5459C103.066 26.8791 103.108 27.2147 103.218 27.5306C103.329 27.8464 103.505 28.1352 103.735 28.3778C103.965 28.6203 104.245 28.8111 104.554 28.9374C104.864 29.0637 105.197 29.1226 105.531 29.1103C105.971 29.1515 106.411 29.0498 106.788 28.8203C107.165 28.5909 107.457 28.246 107.622 27.8369L109.387 27.8369ZM103.105 25.1348H107.631C107.648 24.8352 107.602 24.5353 107.498 24.2541C107.393 23.9729 107.231 23.7164 107.023 23.5006C106.814 23.2849 106.563 23.1145 106.286 23.0003C106.008 22.8861 105.71 22.8305 105.41 22.8369C105.108 22.8351 104.808 22.8932 104.528 23.008C104.248 23.1227 103.993 23.2918 103.779 23.5054C103.564 23.7191 103.394 23.973 103.279 24.2527C103.163 24.5323 103.104 24.8321 103.105 25.1348V25.1348Z"
        fill="white"
      />
      <path
        d="M37.8309 8.73114C38.2205 8.70318 38.6115 8.76204 38.9756 8.90347C39.3397 9.0449 39.6679 9.26538 39.9365 9.54901C40.2051 9.83263 40.4074 10.1723 40.5288 10.5436C40.6502 10.9149 40.6877 11.3085 40.6385 11.696C40.6385 13.6022 39.6083 14.698 37.8309 14.698H35.6757V8.73114H37.8309ZM36.6024 13.8541H37.7274C38.0058 13.8708 38.2844 13.8251 38.543 13.7205C38.8015 13.6159 39.0335 13.4549 39.2219 13.2494C39.4104 13.0438 39.5507 12.7988 39.6326 12.5322C39.7144 12.2656 39.7358 11.984 39.6952 11.7081C39.7329 11.4333 39.7093 11.1535 39.6262 10.8889C39.5431 10.6242 39.4025 10.3812 39.2144 10.1773C39.0263 9.97334 38.7955 9.81353 38.5384 9.70929C38.2813 9.60506 38.0044 9.55897 37.7274 9.57434H36.6024V13.8541Z"
        fill="white"
      />
      <path
        d="M41.6853 12.4444C41.657 12.1485 41.6908 11.8499 41.7847 11.5679C41.8785 11.2858 42.0303 11.0264 42.2302 10.8065C42.4301 10.5865 42.6739 10.4107 42.9457 10.2904C43.2176 10.1702 43.5115 10.108 43.8088 10.108C44.1061 10.108 44.4001 10.1702 44.6719 10.2904C44.9438 10.4107 45.1875 10.5865 45.3874 10.8065C45.5874 11.0264 45.7391 11.2858 45.833 11.5679C45.9268 11.8499 45.9606 12.1485 45.9323 12.4444C45.9612 12.7406 45.9277 13.0396 45.8341 13.3221C45.7405 13.6047 45.5889 13.8645 45.3889 14.0849C45.1889 14.3053 44.945 14.4814 44.6729 14.602C44.4008 14.7225 44.1064 14.7848 43.8088 14.7848C43.5112 14.7848 43.2169 14.7225 42.9447 14.602C42.6726 14.4814 42.4287 14.3053 42.2287 14.0849C42.0287 13.8645 41.8771 13.6047 41.7835 13.3221C41.6899 13.0396 41.6564 12.7406 41.6853 12.4444ZM45.0183 12.4444C45.0183 11.4683 44.5798 10.8975 43.8103 10.8975C43.0378 10.8975 42.6033 11.4683 42.6033 12.4444C42.6033 13.4283 43.0378 13.9947 43.8103 13.9947C44.5798 13.9947 45.0183 13.4244 45.0183 12.4444H45.0183Z"
        fill="white"
      />
      <path
        d="M51.5778 14.6979H50.6559L49.7253 11.3815H49.655L48.7282 14.6979H47.8151L46.5739 10.1949H47.4753L48.2819 13.6309H48.3483L49.2741 10.1949H50.1266L51.0524 13.6309H51.1227L51.9255 10.1949H52.8141L51.5778 14.6979Z"
        fill="white"
      />
      <path
        d="M53.859 10.195H54.7144V10.9103H54.7808C54.8935 10.6534 55.0835 10.438 55.3244 10.2942C55.5652 10.1504 55.845 10.0853 56.1246 10.1081C56.3437 10.0916 56.5637 10.1246 56.7683 10.2047C56.9729 10.2848 57.1568 10.4099 57.3065 10.5708C57.4562 10.7316 57.5678 10.9241 57.633 11.1339C57.6982 11.3437 57.7153 11.5655 57.6832 11.7829V14.6979H56.7945V12.006C56.7945 11.2824 56.48 10.9225 55.8228 10.9225C55.6741 10.9156 55.5256 10.9409 55.3875 10.9967C55.2494 11.0526 55.1251 11.1376 55.023 11.246C54.9208 11.3544 54.8434 11.4836 54.7958 11.6247C54.7483 11.7658 54.7319 11.9156 54.7476 12.0636V14.6979H53.8589L53.859 10.195Z"
        fill="white"
      />
      <path
        d="M59.0966 8.43713H59.9852V14.6979H59.0966V8.43713Z"
        fill="white"
      />
      <path
        d="M61.2218 12.4445C61.1935 12.1485 61.2273 11.85 61.3212 11.5679C61.4151 11.2858 61.5668 11.0265 61.7668 10.8065C61.9668 10.5865 62.2105 10.4107 62.4824 10.2904C62.7542 10.1702 63.0482 10.108 63.3455 10.108C63.6428 10.108 63.9368 10.1702 64.2087 10.2904C64.4806 10.4107 64.7243 10.5865 64.9242 10.8065C65.1242 11.0265 65.276 11.2858 65.3698 11.5679C65.4637 11.85 65.4976 12.1485 65.4693 12.4445C65.4981 12.7407 65.4646 13.0397 65.371 13.3222C65.2774 13.6047 65.1257 13.8646 64.9257 14.085C64.7257 14.3054 64.4818 14.4815 64.2096 14.602C63.9375 14.7226 63.6432 14.7848 63.3455 14.7848C63.0479 14.7848 62.7536 14.7226 62.4814 14.602C62.2093 14.4815 61.9654 14.3054 61.7654 14.085C61.5654 13.8646 61.4137 13.6047 61.32 13.3222C61.2264 13.0397 61.1929 12.7407 61.2218 12.4445ZM64.5547 12.4445C64.5547 11.4684 64.1163 10.8976 63.3467 10.8976C62.5743 10.8976 62.1398 11.4684 62.1398 12.4445C62.1398 13.4284 62.5743 13.9948 63.3467 13.9948C64.1163 13.9948 64.5547 13.4245 64.5547 12.4445H64.5547Z"
        fill="white"
      />
      <path
        d="M66.4049 13.4244C66.4049 12.6139 67.0084 12.1466 68.0797 12.0802L69.2994 12.0099V11.6212C69.2994 11.1456 68.985 10.8771 68.3776 10.8771C67.8815 10.8771 67.5377 11.0592 67.4391 11.3776H66.5787C66.6696 10.6041 67.3971 10.108 68.4186 10.108C69.5475 10.108 70.1842 10.67 70.1842 11.6212V14.6979H69.3287V14.065H69.2584C69.1157 14.292 68.9153 14.4771 68.6777 14.6013C68.4401 14.7255 68.1737 14.7844 67.9059 14.772C67.7168 14.7917 67.5258 14.7715 67.345 14.7128C67.1643 14.6541 66.9978 14.5582 66.8564 14.4313C66.715 14.3043 66.6017 14.1491 66.524 13.9757C66.4462 13.8023 66.4057 13.6145 66.4049 13.4244ZM69.2994 13.0397V12.6632L68.1998 12.7335C67.5797 12.775 67.2985 12.9859 67.2985 13.3829C67.2985 13.7882 67.65 14.024 68.1334 14.024C68.2751 14.0384 68.4182 14.0241 68.5542 13.982C68.6902 13.9399 68.8163 13.8709 68.9251 13.779C69.0339 13.6872 69.1231 13.5744 69.1874 13.4474C69.2517 13.3204 69.2898 13.1817 69.2994 13.0397Z"
        fill="white"
      />
      <path
        d="M71.3504 12.4445C71.3504 11.0216 72.0819 10.1202 73.2195 10.1202C73.501 10.1073 73.7802 10.1747 74.0248 10.3146C74.2693 10.4545 74.4689 10.6611 74.6004 10.9102H74.6668V8.43713H75.5555V14.6979H74.7039V13.9864H74.6336C74.492 14.2339 74.2854 14.4379 74.0361 14.5764C73.7869 14.7149 73.5045 14.7826 73.2195 14.7721C72.074 14.7721 71.3504 13.8707 71.3504 12.4445ZM72.2684 12.4445C72.2684 13.3995 72.7186 13.9742 73.4715 13.9742C74.2205 13.9742 74.6834 13.3912 74.6834 12.4484C74.6834 11.5099 74.2157 10.9186 73.4715 10.9186C72.7235 10.9186 72.2684 11.4972 72.2684 12.4445H72.2684Z"
        fill="white"
      />
      <path
        d="M79.2367 12.4444C79.2084 12.1485 79.2423 11.8499 79.3361 11.5679C79.43 11.2858 79.5817 11.0264 79.7817 10.8065C79.9816 10.5865 80.2253 10.4107 80.4971 10.2904C80.769 10.1702 81.063 10.108 81.3603 10.108C81.6575 10.108 81.9515 10.1702 82.2234 10.2904C82.4952 10.4107 82.7389 10.5865 82.9389 10.8065C83.1388 11.0264 83.2906 11.2858 83.3844 11.5679C83.4782 11.8499 83.5121 12.1485 83.4838 12.4444C83.5126 12.7406 83.4792 13.0396 83.3856 13.3221C83.292 13.6047 83.1403 13.8645 82.9403 14.0849C82.7403 14.3053 82.4965 14.4814 82.2243 14.602C81.9522 14.7225 81.6579 14.7848 81.3603 14.7848C81.0626 14.7848 80.7683 14.7225 80.4962 14.602C80.2241 14.4814 79.9802 14.3053 79.7802 14.0849C79.5802 13.8645 79.4285 13.6047 79.3349 13.3221C79.2413 13.0396 79.2079 12.7406 79.2367 12.4444ZM82.5697 12.4444C82.5697 11.4683 82.1312 10.8975 81.3617 10.8975C80.5893 10.8975 80.1547 11.4683 80.1547 12.4444C80.1547 13.4283 80.5893 13.9947 81.3617 13.9947C82.1313 13.9947 82.5697 13.4244 82.5697 12.4444Z"
        fill="white"
      />
      <path
        d="M84.6747 10.195H85.5301V10.9103H85.5966C85.7092 10.6534 85.8992 10.438 86.1401 10.2942C86.381 10.1504 86.6607 10.0853 86.9403 10.1081C87.1594 10.0916 87.3794 10.1246 87.584 10.2047C87.7886 10.2848 87.9726 10.4099 88.1222 10.5708C88.2719 10.7316 88.3835 10.9241 88.4487 11.1339C88.5139 11.3437 88.531 11.5655 88.4989 11.7829V14.6979H87.6102V12.006C87.6102 11.2824 87.2958 10.9225 86.6386 10.9225C86.4898 10.9156 86.3413 10.9409 86.2032 10.9967C86.0652 11.0526 85.9408 11.1376 85.8387 11.246C85.7366 11.3544 85.6591 11.4836 85.6116 11.6247C85.564 11.7658 85.5476 11.9156 85.5634 12.0636V14.6979H84.6747V10.195Z"
        fill="white"
      />
      <path
        d="M93.5174 9.07385V10.2154H94.493V10.964H93.5174V13.2794C93.5174 13.7511 93.7117 13.9576 94.1541 13.9576C94.2674 13.9573 94.3805 13.9504 94.493 13.9371V14.6773C94.3334 14.7059 94.1717 14.7211 94.0096 14.7228C93.0213 14.7228 92.6277 14.3751 92.6277 13.5069V10.9639H91.9129V10.2154H92.6277V9.07385H93.5174Z"
        fill="white"
      />
      <path
        d="M95.7108 8.43713H96.5917V10.9186H96.662C96.7801 10.6593 96.9753 10.4427 97.221 10.2983C97.4667 10.154 97.7509 10.0889 98.035 10.1119C98.2529 10.1001 98.4708 10.1365 98.6731 10.2185C98.8754 10.3006 99.0571 10.4262 99.2052 10.5866C99.3533 10.7469 99.4642 10.9379 99.53 11.1461C99.5958 11.3542 99.6148 11.5743 99.5857 11.7906V14.6979H98.6962V12.0099C98.6962 11.2906 98.3612 10.9264 97.7333 10.9264C97.5806 10.9138 97.4269 10.9348 97.2832 10.9879C97.1394 11.041 97.0089 11.1248 96.9009 11.2335C96.793 11.3423 96.71 11.4733 96.658 11.6174C96.6059 11.7615 96.5859 11.9153 96.5995 12.068V14.6978H95.7109L95.7108 8.43713Z"
        fill="white"
      />
      <path
        d="M104.765 13.482C104.644 13.8935 104.383 14.2495 104.026 14.4877C103.669 14.7258 103.24 14.831 102.814 14.7847C102.517 14.7926 102.223 14.7357 101.95 14.6182C101.678 14.5007 101.434 14.3253 101.236 14.1041C101.038 13.883 100.891 13.6214 100.804 13.3376C100.717 13.0538 100.693 12.7545 100.734 12.4605C100.694 12.1656 100.719 11.8657 100.805 11.581C100.892 11.2964 101.038 11.0336 101.235 10.8105C101.432 10.5874 101.675 10.4093 101.946 10.2881C102.218 10.1669 102.513 10.1054 102.81 10.108C104.063 10.108 104.819 10.964 104.819 12.378V12.6881H101.639V12.7379C101.625 12.9031 101.646 13.0694 101.7 13.2262C101.754 13.3829 101.841 13.5266 101.953 13.6481C102.066 13.7696 102.203 13.8661 102.356 13.9315C102.508 13.9969 102.673 14.0297 102.838 14.0279C103.051 14.0534 103.266 14.0151 103.457 13.9179C103.648 13.8207 103.805 13.6689 103.91 13.482L104.765 13.482ZM101.639 12.0308H103.914C103.925 11.8797 103.904 11.7279 103.853 11.5852C103.802 11.4425 103.722 11.312 103.618 11.2021C103.513 11.0923 103.387 11.0055 103.247 10.9473C103.107 10.8891 102.957 10.8609 102.805 10.8643C102.651 10.8624 102.499 10.8913 102.357 10.9492C102.214 11.0071 102.085 11.093 101.976 11.2017C101.868 11.3104 101.782 11.4398 101.724 11.5822C101.666 11.7246 101.637 11.8771 101.639 12.0308H101.639Z"
        fill="white"
      />
    </svg>
  );
};

export default AppleStoreLogo;
