import { apiRequest } from '../api/request/makeRequest';
import type { EmojiResponse } from './type';
import type { ThunkAPI } from '@store/configureStore';

const EMOJI_URL = 'emoji';

const fetchEmoji = (thunkAPI: ThunkAPI) => () =>
  apiRequest<EmojiResponse>(`${EMOJI_URL}/get`, {
    method: 'GET',
    thunkAPI,
  });

export const EmojiAPI = {
  fetchEmoji,
} as const;
