import { formatSearchParams } from '@lib/formatSearchParams';
import { apiRequest } from '@store/api/request/makeRequest';
import type {
  CreditCardConnectionResponse,
  CreditCardFormConnectPayload,
  FetchTransactionsPayload,
  OrderInfoPayload,
  OrderInfoResponse,
  PaymentSlipPayload,
  PaymentSlipResponse,
  PrepaidInvoicePayload,
  PrepaidInvoiceResponse,
  SmsPhoneCodePayload,
  SmsPhoneCodeResponse,
  SmsPricesResponse,
  StoredCardId,
  StoredCardResponse,
  TransactionResponse,
  TransactionSettingsResponse,
} from './type';
import type { ThunkAPI } from '@store/configureStore';

const TRANSACTION_URL = 'transaction';
const PREPAID_INVOICE = 'prepaid-invoice';

const fetchUserCards = (thunkAPI: ThunkAPI) => () =>
  apiRequest<StoredCardResponse>(`${TRANSACTION_URL}/fetch-user-cards`, {
    method: 'GET',
    thunkAPI,
  });

const deleteUserCard = (thunkAPI: ThunkAPI) => (data: StoredCardId) =>
  apiRequest<StoredCardResponse>(`${TRANSACTION_URL}/delete-user-card`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const setDefaultUserCard = (thunkAPI: ThunkAPI) => (data: StoredCardId) =>
  apiRequest<StoredCardResponse>(`${TRANSACTION_URL}/set-default-user-card`, {
    method: 'POST',
    body: { data },
    thunkAPI,
  });

const fetchTransactions =
  (thunkAPI: ThunkAPI) => (payload: FetchTransactionsPayload) => {
    const searchParamsObj = formatSearchParams(payload.query);
    const searchParams = new URLSearchParams(searchParamsObj).toString();

    return apiRequest<TransactionResponse>(
      `${TRANSACTION_URL}/list?${searchParams}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );
  };

const fetchSmsPrices = (thunkAPI: ThunkAPI) => () =>
  apiRequest<SmsPricesResponse>(`${TRANSACTION_URL}/sms/prices`, {
    method: 'GET',
    thunkAPI,
  });

const fetchSmsPhoneCode =
  (thunkAPI: ThunkAPI) =>
  ({ phone }: SmsPhoneCodePayload) =>
    apiRequest<SmsPhoneCodeResponse>(
      `${TRANSACTION_URL}/sms/phone-code?phone=${phone}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );

const fetchPaymentSlip =
  (thunkAPI: ThunkAPI) =>
  ({ amount, type, position }: PaymentSlipPayload) =>
    apiRequest<PaymentSlipResponse>(
      `${TRANSACTION_URL}/payment-slip?amount=${amount}&type=${type}&position=${position}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );

const checkConnectCardForm =
  (thunkAPI: ThunkAPI) => (data: CreditCardFormConnectPayload) =>
    apiRequest(`${TRANSACTION_URL}/check-credit-card-form-connect`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

const connectCardForm =
  (thunkAPI: ThunkAPI) => (data: CreditCardFormConnectPayload) =>
    apiRequest<CreditCardConnectionResponse>(
      `${TRANSACTION_URL}/credit-card-form-connect`,
      {
        method: 'POST',
        body: data,
        thunkAPI,
      }
    );

const fetchOrderInfo = (thunkAPI: ThunkAPI) => (data: OrderInfoPayload) =>
  apiRequest<OrderInfoResponse>(`${TRANSACTION_URL}/user-card-order-info`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const fetchTransactionSettings = (thunkAPI: ThunkAPI) => () =>
  apiRequest<TransactionSettingsResponse>(`${TRANSACTION_URL}/settings`, {
    method: 'GET',
    thunkAPI,
  });

const createPrepaidInvoice =
  (thunkAPI: ThunkAPI) => (data: PrepaidInvoicePayload) =>
    apiRequest<PrepaidInvoiceResponse>(`${PREPAID_INVOICE}/create`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

export const TransactionAPI = {
  fetchUserCards,
  deleteUserCard,
  setDefaultUserCard,
  fetchTransactions,
  fetchSmsPhoneCode,
  fetchSmsPrices,
  fetchPaymentSlip,
  checkConnectCardForm,
  connectCardForm,
  fetchOrderInfo,
  fetchTransactionSettings,
  createPrepaidInvoice,
} as const;
