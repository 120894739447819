import React from 'react';
import dynamic from 'next/dynamic';

import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectModal } from '@store/modal/selector';

const ScheduleCourierEducationModal = dynamic(
  () =>
    import(
      '@components/shipping/desktop/ScheduleCourierEducationModal/ScheduleCourierEducationModal'
    )
);

const ScheduleCourierModal = dynamic(
  () =>
    import('@components/shipping/desktop/ScheduleCourier/ScheduleCourierModal')
);

const ScheduleCourierConfirmation = dynamic(
  () =>
    import(
      '@components/shipping/desktop/ScheduleCourier/StepFour/ScheduleCourierConfirmationSheet/ScheduleCourierConfirmation'
    )
);

const DesktopCoreLayoutComponents = () => {
  const activeModal = useAppSelector(selectModal);

  const componentsToRender = [
    {
      condition: activeModal === '[SCHEDULE_COURIER_EDUCATION]',
      component: <ScheduleCourierEducationModal />,
    },
    {
      condition: activeModal === '[SCHEDULE_COURIER_MODAL]',
      component: <ScheduleCourierModal />,
    },
    {
      condition: activeModal === '[SCHEDULE_COURIER_CONFIRMATION]',
      component: <ScheduleCourierConfirmation />,
    },
  ];

  return (
    <>
      {componentsToRender.map(
        (item, index) =>
          item.condition && (
            <React.Fragment key={index}>{item.component}</React.Fragment>
          )
      )}
    </>
  );
};

export default DesktopCoreLayoutComponents;
