import isEmpty from 'lodash/isEmpty';
import { NextPageContext } from 'kp';
import { URLSearchParams } from 'url';

import isBotOrExtension from '@lib/isBotOrExtension';
import { ssrRedirect } from '@lib/ssrRedirect';
import isServer from '@lib/ssrSetup/isServer';

const QUERY_PARAMS_TO_CHECK = new Set([
  'desktopRequested',
  'desktop_requested',
  'order',
  'not_user_ids',
  'list_type',
  'dummy',
]);

const excludeSpecificQueryParamsBot = async (
  ctx: NextPageContext,
  next: () => Promise<void>
) => {
  const { req, res, asPath } = ctx;
  const skipMiddleware = !req || !isServer || !isBotOrExtension(req);
  if (skipMiddleware) return next();

  const splitResult = asPath?.split('?');

  const baseUrl = splitResult[0];
  const params = new URLSearchParams(splitResult[1]);

  let hasExcludedParams = false;

  QUERY_PARAMS_TO_CHECK.forEach((param) => {
    if (params.has(param)) {
      params.delete(param);
      hasExcludedParams = true;
    }
  });

  if (!hasExcludedParams) {
    return next();
  }

  const paramsString = params.toString();
  const redirectUrl = isEmpty(paramsString)
    ? baseUrl
    : `${baseUrl}?${paramsString}`;

  ssrRedirect(res, redirectUrl);
  return true;
};

export default excludeSpecificQueryParamsBot;
