import { ENVIRONMENT } from '@lib/constants/env.constants';

import { axiosInstance } from '@api/request/axiosInstance';

import { isProduction } from './isProduction';

export const forceRefresh = async (url: Location) => {
  if (isProduction && ENVIRONMENT !== 'test') {
    try {
      let timestamp = Date.now().toString();

      if (url) {
        timestamp = timestamp.substring(0, 9);
      }

      const response = await axiosInstance.get(
        `/version.json?time=${timestamp}`
      );
      const { version } = response.data;

      if (version !== process.env.NEXT_PUBLIC_SENTRY_RELEASE) {
        if (url) {
          window.location = url;
        } else {
          window.location.reload();
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
};
