export const BROWSER_UPDATE_LINKS = {
  Chrome: 'https://www.google.com/chrome/',
  Firefox: 'https://www.mozilla.org/en-US/firefox/new/',
  'Microsoft Edge': 'https://www.microsoft.com/en-us/edge',
};

export const BROWSER_UPDATE_LINKS_ANDROID = {
  Chrome: 'https://play.google.com/store/apps/details?id=com.android.chrome',
  Firefox: 'https://play.google.com/store/apps/details?id=org.mozilla.firefox',
};

export const BROWSER_UPDATE_LINKS_APPLE = {
  Chrome: 'https://apps.apple.com/us/app/chrome/id535886823',
  Firefox:
    'https://apps.apple.com/us/app/firefox-private-safe-browser/id989804926',
};

export const OUTDATED_BROWSER_VERSIONS = {
  windows: {
    'internet explorer': '>11',
    edge: '>=88',
  },
  macos: {
    safari: '>11',
  },
  mobile: {
    safari: '>9',
    'android browser': '>=5.1',
    chrome: '>=84',
    'Samsung Internet for Android': '>=14.2',
  },
  chrome: '>=98',
  firefox: '>=79',
  opera: '>=69',
  googlebot: '>=2.1',
};
