import { formatSearchParams } from '@lib/formatSearchParams';

import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';
import type { SearchParamsObj } from '@store/global/type';

import type { FetchUserAdsPayload, FetchUserAdsResponse } from './type';

const USER_URL = 'me';

const fetchUserAds = (thunkAPI: ThunkAPI) => (payload: FetchUserAdsPayload) => {
  const searchParamsObj: SearchParamsObj = formatSearchParams(payload.query);
  const searchParams: string = new URLSearchParams(searchParamsObj).toString();

  return apiRequest<FetchUserAdsResponse>(`${USER_URL}/eds?${searchParams}`, {
    method: 'GET',
    thunkAPI,
  });
};

const ignoreUserAds = (thunkAPI: ThunkAPI) => (edId?: string | number) =>
  apiRequest(`${USER_URL}/ignore-user-eds/${edId}`, {
    method: 'GET',
    thunkAPI,
  });

const unIgnoreUserAds = (thunkAPI: ThunkAPI) => (edId: string | number) =>
  apiRequest(`${USER_URL}/un-ignore-user-eds/${edId}`, {
    method: 'GET',
    thunkAPI,
  });

export const MeAdsAPI = {
  ignoreUserAds,
  unIgnoreUserAds,
  fetchUserAds,
} as const;
