import Cookies from 'js-cookie';
import { createAppAsyncThunk } from '@store/global/utils';

import { JobAPI } from './api';
import { COOKIE_URL } from 'src/lib/constants/env.constants';

import { jobApplicationEvents } from '@lib/events/jobApplication.event';
import { toggleModal } from '@store/modal/slice';
import { MODALS } from './utils';
import {
  JobApplicationPayload,
  LogJobApplicationEventPayload,
  UnsubscribeJobReportPayload,
} from './type';

export const saveJobApplication = createAppAsyncThunk(
  'job/saveJobApplication',
  async (payload: JobApplicationPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await JobAPI.saveJobApplication(thunkAPI)(payload);

      if (response.data.success) {
        jobApplicationEvents.successAplicationEmail();
      }

      return response.data.results;
    } catch (e) {
      const { errors } = e.response.data;
      return rejectWithValue(errors);
    }
  }
);

export const logJobApplicationEvent = createAppAsyncThunk(
  'job/logJobApplicationEvent',
  async (
    { adId, jobApplicationType }: LogJobApplicationEventPayload,
    thunkAPI
  ) => {
    const EVENT_TYPE = {
      email: 'impression-email',
      link: 'impression-link',
      phone: 'impression-phone',
      linkClick: 'application-link',
      none: 'impression-none',
    };

    const data = {
      adId,
      eventType: EVENT_TYPE[jobApplicationType],
    };

    jobApplicationEvents.impressionType(data.eventType);

    const response = await JobAPI.saveJobApplication(thunkAPI)(data);

    return response.data.results;
  }
);

export const openJobApplicationModal = createAppAsyncThunk(
  'job/openJobApplicationModal',
  async (
    { adId, jobApplicationType }: LogJobApplicationEventPayload,
    { getState, dispatch }
  ) => {
    if (getState().auth.token) {
      dispatch(
        logJobApplicationEvent({
          adId,
          jobApplicationType,
        })
      );

      dispatch(toggleModal(MODALS[jobApplicationType]));
    }
  }
);

export const unsubscribeJobReport = createAppAsyncThunk(
  'jobEmailReport/unsubscribeJobReport',
  async (payload: UnsubscribeJobReportPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await JobAPI.unsubscribeJobReport(thunkAPI)(payload);
      if (data.success) {
        Cookies.set('unsubscribe', '1', {
          expires: 1000,
          path: '/',
          domain: COOKIE_URL,
        });
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);
