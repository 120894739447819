import Router from 'next/router';

import { MyKPAddressBookRoute } from '@server/routes';

import { AddressBookAPI } from '@store/addressBook/api';
import { createAppAsyncThunk } from '@store/global/utils';

import { showToast, toggleModal, toggleScreen } from '../modal/slice';

import {
  AddContactPayload,
  AddNotePayload,
  AddressBookListPayload,
  DeleteContactPayload,
} from './type';

export const fetchAddressBookList = createAppAsyncThunk(
  'addressBook/fetchAddressBookList',
  async (payload: AddressBookListPayload, thunkAPI) => {
    const { data } = await AddressBookAPI.fetchAddressBookList(thunkAPI)(
      payload
    );

    return data;
  }
);

export const addToAddressBook = createAppAsyncThunk(
  'addressBook/addToAddressBook',
  async (payload: AddContactPayload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { data } = await AddressBookAPI.addToAddressBook(thunkAPI)(payload);
    const { deviceType } = getState().meta;
    const isMobile = deviceType === 'mobile';
    if (data.success) {
      if (isMobile) {
        dispatch(toggleScreen(`[AddressBookAdd]-${payload.contact_id}`));
      }
      dispatch(toggleModal(`[AddressBookAdd]-${payload.contact_id}`));
    }

    return data.results;
  }
);

export const deleteFromAddressBook = createAppAsyncThunk(
  'addressBook/deleteFromAddressBook',
  async (payload: DeleteContactPayload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { data } = await AddressBookAPI.deleteFromAddressBook(thunkAPI)(
      payload
    );

    const {
      query: { page },
    } = Router.router;

    const { pages, listIds } = getState().addressBook;

    if (page !== '1' && listIds.length === 1) {
      Router.push(
        MyKPAddressBookRoute.generateUrl(
          {},
          {
            page: pages - 1,
          }
        )
      );
    }
    if (data.success) {
      dispatch(
        showToast({
          content: 'Kontakt uspešno obrisan',
          isToast: true,
          duration: '3000',
        })
      );
    }

    return data.results;
  }
);

export const addNote = createAppAsyncThunk(
  'addressBook/addNote',
  async (payload: AddNotePayload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { data } = await AddressBookAPI.addNote(thunkAPI)(payload);
    const { withScreen = true } = payload;
    const { deviceType } = getState().meta;
    const isMobile = deviceType === 'mobile';
    if (data.success) {
      if (isMobile && withScreen) {
        Router.back();
      } else {
        dispatch(toggleModal(null));
      }
    }

    return data.results;
  }
);
