import { AxiosHeaderValue } from 'axios';

import type { ThunkAPI } from '@store/configureStore';
import { setKpMachineId, setKpSession } from '@store/meta/slice';

const RESET_SESSION_ENDPOINTS = [
  'auth/logout',
  'auth/login',
  'auth/social/login',
  'auth/verify-2step-login-code',
  'users',
  'users/social',
];

export const setSessionHeaders = (
  thunkAPI: ThunkAPI,
  url: string,
  responseHeaders: { [key: string]: AxiosHeaderValue } = {}
) => {
  const { getState, dispatch } = thunkAPI;
  const { headers = {} } = getState().meta;
  const kpSession = responseHeaders['x-kp-session'];
  const kpMachineId = responseHeaders['x-kp-machine-id'];

  if (kpMachineId && headers.kpMachineId !== kpMachineId) {
    dispatch(setKpMachineId(kpMachineId));
  }

  const hasToSetNewSession =
    RESET_SESSION_ENDPOINTS.includes(url) || !headers.kpSession;

  if (kpSession && hasToSetNewSession) {
    dispatch(setKpSession(kpSession));
  }
};
