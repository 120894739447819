/* eslint-disable import/no-cycle */
import Router from 'next/router';

import { MyKPAccountRoute } from '@server/routes';

import { createAppAsyncThunk } from '@store/global/utils';
import { fetchUserProfile } from '@store/meProfile/thunk';
import { resetAgreementsRules, resetSoftBlockError } from '@store/meta/slice';
import { showToast } from '@store/modal/slice';

import { MeAccountAPI } from './api';
import { ForgotPasswordPayload, SaveInfoBoxPayload } from './type';

export const fetchBlockedInfo = createAppAsyncThunk(
  'meAccount/fetchBlockedInfo',
  async (_, thunkAPI) => {
    const { data } = await MeAccountAPI.fetchBlockedInfo(thunkAPI)();

    return data.results;
  }
);

export const forgotPassword = createAppAsyncThunk(
  'meAccount/forgotPassword',
  async (payload: ForgotPasswordPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await MeAccountAPI.forgotPassword(thunkAPI)(payload);

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const resendActivationEmail = createAppAsyncThunk(
  'meAccount/resendActivationEmail',
  async (payload: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await MeAccountAPI.resendActivationEmail(thunkAPI)(
        payload
      );

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const resendActivationEmailChange = createAppAsyncThunk(
  'meAccount/resendActivationEmailChange',
  async (payload: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await MeAccountAPI.resendActivationEmailChange(thunkAPI)(
        payload
      );

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const softBlockUnblock = createAppAsyncThunk(
  'meAccount/softBlockUnblock',

  async (_, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const { data } = await MeAccountAPI.softBlockUnblock(thunkAPI)();

      if (data.success) {
        dispatch(resetSoftBlockError());
        window.history.back();
      }

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const acceptRulesAgreement = createAppAsyncThunk(
  'meAccount/acceptRulesAgreement',

  async (_, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await MeAccountAPI.acceptRulesAgreement(thunkAPI)();
      if (data.success) {
        dispatch(resetAgreementsRules());
        window.history.back();
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const saveInfoBox = createAppAsyncThunk(
  'meAccount/saveInfoBox',
  async (payload: SaveInfoBoxPayload, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    try {
      const { data } = await MeAccountAPI.saveInfoBox(thunkAPI)(payload);
      const { deviceType } = getState().meta;
      const isMobile = deviceType === 'mobile';

      if (data.success && isMobile) {
        dispatch(
          showToast({
            content: 'Izmene uspešno sačuvane',
            isToast: true,
            duration: '5000',
          })
        );
        Router.push(MyKPAccountRoute.generateUrl());
        dispatch(fetchUserProfile());
      } else if (data.success) {
        Router.push(MyKPAccountRoute.generateUrl({}, { infoBox: 'success' }));
        dispatch(fetchUserProfile());
      }

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);
