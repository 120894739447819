import { useRouter } from 'next/router';
import { DefaultSeoProps } from 'next-seo';

import {
  API_URL,
  FACEBOOK_APP_ID,
  ROOT_URL,
} from '@lib/constants/env.constants';
import { isStagingEnvironment } from '@lib/isProduction';

import { useSelectedTheme } from '@hooks/meta/useSelectedTheme';
import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectIsDesktopRequested, selectIsTablet } from '@store/meta/selector';

export const useSeoConfig = () => {
  const router = useRouter();
  const isTablet = useAppSelector(selectIsTablet);
  const isDesktopRequested = useAppSelector(selectIsDesktopRequested);
  const selectedTheme = useSelectedTheme();

  const config: DefaultSeoProps = {
    dangerouslySetAllPagesToNoFollow: isStagingEnvironment,
    dangerouslySetAllPagesToNoIndex: isStagingEnvironment,
    title: 'KupujemProdajem',
    description:
      'Praktično sve... Kupi i prodaj u najvećoj online zajednici u Srbiji. Probaj na kp!',
    canonical: `${API_URL}${router.asPath}`,
    themeColor: selectedTheme === 'dark' ? '#0d0f11' : '#f4f4f4',
    openGraph: {
      url: `${ROOT_URL}${router.asPath}`,
      title: 'KupujemProdajem',
      description:
        'Praktično sve...  Kupi i prodaj u najvećoj online zajednici u Srbiji. Probaj na kp!',
      type: 'website',
      images: [
        {
          url: `${ROOT_URL}/static/images/meta/original.png`,
          alt: 'KupujemProdajem',
        },
      ],
      locale: 'sr_RS',
      site_name: 'KupujemProdajem',
    },
    facebook: {
      appId: FACEBOOK_APP_ID,
    },
    additionalMetaTags: [
      {
        name: 'viewport',
        content:
          !isDesktopRequested && !isTablet
            ? 'width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0'
            : '',
      },
      {
        name: 'google-site-verification',
        content: 'Xmb8HlnE7Zm00rXbLbOCeLL97y6GiCp6wNERgVpJsQE',
      },
      {
        name: 'format-detection',
        content: 'telephone=no',
      },
    ],
  };

  return config;
};
