import { IncomingMessage } from 'http';
import isbot from 'isbot';
import { extname } from 'path';

import isServer from './ssrSetup/isServer';

const isBotOrExtension = (req: IncomingMessage) => {
  if (!isServer) {
    return false;
  }

  try {
    const isBot = isbot(req?.headers['user-agent']);
    const isExtension = extname(req.url) !== '';

    return isBot || isExtension;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(req?.headers['user-agent'], e);
    return true;
  }
};

export default isBotOrExtension;
