/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';

import { selectDeviceOS } from '@store/meta/selector';

import { useAppSelector } from './redux/useAppSelector';

const useRemoveHoverSafariMobile = () => {
  const osName = useAppSelector(selectDeviceOS);

  useEffect(() => {
    const handleTouchStart = () => {};

    try {
      if (osName === 'iOS') {
        document.addEventListener('touchstart', handleTouchStart);
      }
    } catch (error) {}

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);
};

export default useRemoveHoverSafariMobile;
