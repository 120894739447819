import { apiRequest } from '@store/api/request/makeRequest';
import type { CategoryInfoPayload, CategoryInfoResponse } from './type';
import type { ThunkAPI } from '@store/configureStore';

const CATEGORIES_URL = 'categories';

const fetchCategoryInfo =
  (thunkAPI: ThunkAPI) =>
  ({ categoryId }: CategoryInfoPayload) =>
    apiRequest<CategoryInfoResponse>(`${CATEGORIES_URL}/${categoryId}/info`, {
      method: 'GET',
      thunkAPI,
    });

export const CategoryInfoAPI = {
  fetchCategoryInfo,
} as const;
