import sample from 'lodash/sample';

import {
  BACKUP_BANNERS_101,
  BACKUP_BANNERS_104,
  BACKUP_BANNERS_105,
  BACKUP_BANNERS_301,
  BACKUP_BANNERS_302,
  BACKUP_BANNERS_306,
  BACKUP_BANNERS_308,
} from '@lib/constants/banner.constants';

export const generateBackupBanner = () => {
  return {
    101: sample(BACKUP_BANNERS_101),
    104: sample(BACKUP_BANNERS_104),
    105: sample(BACKUP_BANNERS_105),
    301: sample(BACKUP_BANNERS_301),
    302: sample(BACKUP_BANNERS_302),
    306: sample(BACKUP_BANNERS_306),
    308: sample(BACKUP_BANNERS_308),
  };
};
