import { apiRequest } from '@store/api/request/makeRequest';
import type { AgreementResponse } from '@store/agreement/type';
import type { ThunkAPI } from '@store/configureStore';

const AGREEMENT_URL = 'agreements';

const fetchAgreements = (thunkAPI: ThunkAPI) => () =>
  apiRequest<AgreementResponse>(`${AGREEMENT_URL}`, {
    method: 'GET',
    thunkAPI,
  });

export const AgreementAPI = {
  fetchAgreements,
} as const;
