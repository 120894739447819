import type { AxiosResponse } from 'axios';

import type { ThunkAPI } from '@store/configureStore';
import { setAgreementsRules, setSoftBlockError } from '@store/meta/slice';

export const handleSoftErrors = (
  thunkAPI: ThunkAPI,
  response: AxiosResponse
) => {
  const { dispatch } = thunkAPI;
  const error = response?.data?.errors?.[0] ?? {};
  const errorCode = error?.error_code ?? '';

  if (errorCode === 'softblock') {
    dispatch(setSoftBlockError(error));
  }
  if (errorCode === 'agreement_rules') {
    dispatch(setAgreementsRules(error));
  }
};
