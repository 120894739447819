/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { routeChangeStart } from '@hooks/useRouteChangeStart';

import { logout } from '@store/auth/thunk';
import {
  editUserNotificationAlerts,
  editUserProfile,
  fetchUserProfile,
} from '@store/meProfile/thunk';

import { MeProfileInitialState } from './type';

const initialState: MeProfileInitialState = {
  isLoading: false,
  profile: {
    name: '',
    location: '',
    locationId: '',
    phone: '',
    showAdsLink: '',
    showOnline: '',
    updateAds: '',
    messageNotify: '',
    messageNotifyInterval: '',
    adRenewActive: '',
    adRenewEnd: '',
    email: '',
    newEmail: '',
    newEmailRequested: '',
    isUserPasswordEmpty: '',
    addressbookEnabled: '',
    hasUserVerifiedBankAccount: '',
    showUserVerifiedBankAccount: '',
    hasCompanyVerifiedBankAccount: '',
    showCompanyVerifiedBankAccount: '',
    hasActiveAds: false,
    infoBox: '',
    parkAllAdsInfo: {},
    errors: [],
    deliveryMethodAllAdsInfo: {
      activateDeliveryMethodAllAdsInProgress: false,
      deliveryMethodCourierDeliveryInfo: '',
      deliveryMethodLocalPickupInfo: '',
    },
    notificationAlertInfo: {
      adFavoriteEnabled: true,
    },
  },
  isSuccessfulEdited: false,
  isProfileEditLoading: false,
};

const meProfileSlice = createSlice({
  name: 'meProfile',
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[meProfileSlice.name])) {
        Object.assign(state, action.payload.meProfile);
      }
    });

    builder.addCase(routeChangeStart, (state) => {
      state.isSuccessfulEdited = false;
    });

    builder.addCase(fetchUserProfile.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.profile = action.payload;
    });

    builder.addCase(editUserProfile.pending, (state) => {
      state.profile.errors = [];
      state.isSuccessfulEdited = false;
      state.isProfileEditLoading = true;
    });

    builder.addCase(editUserProfile.fulfilled, (state, action) => {
      state.isSuccessfulEdited = true;
      state.isProfileEditLoading = false;

      state.profile = {
        ...state.profile,
        ...action.meta.arg,
      };
    });

    builder.addCase(editUserProfile.rejected, (state, action) => {
      state.isSuccessfulEdited = false;
      state.isProfileEditLoading = false;
      state.profile.errors = action.payload;
    });

    builder.addCase(editUserNotificationAlerts.pending, (state) => {
      state.profile.errors = [];
      state.isSuccessfulEdited = false;
      state.isProfileEditLoading = true;
    });

    builder.addCase(editUserNotificationAlerts.fulfilled, (state, action) => {
      state.isSuccessfulEdited = true;
      state.isProfileEditLoading = false;

      state.profile = {
        ...state.profile,
        ...action.meta.arg,
      };
    });

    builder.addCase(editUserNotificationAlerts.rejected, (state, action) => {
      state.isSuccessfulEdited = false;
      state.isProfileEditLoading = false;
      state.profile.errors = action.payload;
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.profile = initialState.profile;
    });
  },
});

export default meProfileSlice;
