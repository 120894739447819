import { createAppAsyncThunk } from '@store/global/utils';

import { SmallBusinessAPI } from '@store/smallBusiness/api';
import { smallBusinessEvents } from '@lib/events/smallBusiness.event';
import { toggleModal } from '@store/modal/slice';
import { SubmitSmallBusinessForm } from '@store/smallBusiness/type';

export const postContactForm = createAppAsyncThunk(
  'smallBusiness/postContactForm',
  async (payload: SubmitSmallBusinessForm, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const { data } = await SmallBusinessAPI.postContactForm(thunkAPI)(
        payload
      );
      if (data.success) {
        smallBusinessEvents.success();
        dispatch(toggleModal('[SMALL_BUSINESS_SUCCESS]'));
      }
      return data;
    } catch (error) {
      const { errors } = error.response.data;
      return rejectWithValue(errors);
    }
  }
);
