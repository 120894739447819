export const formatDate = (d: Date | string = new Date()) => {
  let date;

  if (d === null) {
    // eslint-disable-next-line no-param-reassign
    d = new Date();
  }

  if (typeof d === 'string') {
    date = new Date(d.replace(/-/g, '/').replace(/\.\d+/, ''));
  } else {
    date = d;
  }

  return `${[date.getDate(), date.getMonth() + 1, date.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('.')}.`;
};
