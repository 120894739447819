/* eslint-disable consistent-return */
import { NextPageContext } from 'next';
import { promises as fs } from 'fs';
import { join } from 'path';

import isBotOrExtension from '@lib/isBotOrExtension';
import isSupportedOS from '@lib/isSupportedOS';
import isServer from '@lib/ssrSetup/isServer';

const isOldOS = async (ctx: NextPageContext, next: () => Promise<void>) => {
  const { req, res } = ctx;

  const skipMiddleware = !req || !isServer || isBotOrExtension(req);
  if (skipMiddleware) return next();

  if (!isSupportedOS(req.headers['user-agent'])) {
    const filePath = join(process.cwd(), 'public', 'old-os.html');
    const fileContent = await fs.readFile(filePath, 'utf-8');
    res.setHeader('Cache-Control', 'public,max-age=31536000,immutable');
    res.setHeader('Content-Type', 'text/html');
    res.statusCode = 200;
    res.end(fileContent);

    return true;
  }
  return next();
};

export default isOldOS;
