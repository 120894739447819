import React from 'react';

import style from './ErrorUnderConstruction.module.scss';

const ErrorUnderConstructionMaintenanceMode = () => {
  return (
    <p className={`${style.mainText} ${style.centerAlign}`}>
      <span className={style.large}>Uskoro ćemo ponovo biti dostupni.</span>
      <br />
      <span className={style.small}>
        Hvala na razumevanju! Vaš <span>k</span>
        <span>p</span> tim.
      </span>
    </p>
  );
};

export default ErrorUnderConstructionMaintenanceMode;
