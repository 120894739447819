import { generateAuth } from '@store/api/request/generateSignature';
import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import type {
  ChangePasswordResponse,
  CheckVerificationCodePayload,
  GetTokenResponse,
  LoginPayload,
  LoginResponse,
  LoginViaOAuthPayload,
  LoginViaOAuthResponse,
  ResetPasswordResponse,
  SendVerificationCodePayload,
  VerificationInfoResponse,
} from './type';

const AUTH_URL = 'auth';

const login =
  (thunkAPI: ThunkAPI) =>
  ({
    email,
    password,
    remember = 'yes',
    hCaptchaResponse = '',
  }: LoginPayload) => {
    return apiRequest<LoginResponse>(`${AUTH_URL}/login`, {
      method: 'POST',
      body: {
        email,
        password,
        remember,
        'h-captcha-response': hCaptchaResponse,
      },
      thunkAPI,
    });
  };

const loginViaOAuth =
  (thunkAPI: ThunkAPI) =>
  ({ accessToken, socialNetwork }: LoginViaOAuthPayload) => {
    return apiRequest<LoginViaOAuthResponse>(`${AUTH_URL}/social/login`, {
      method: 'POST',
      body: { accessToken, socialNetwork },
      thunkAPI,
    });
  };

const logout = (thunkAPI: ThunkAPI) => () =>
  apiRequest(`${AUTH_URL}/logout`, {
    method: 'GET',
    thunkAPI,
  });

const getToken = (thunkAPI: ThunkAPI) => () =>
  apiRequest<GetTokenResponse>(`${AUTH_URL}/get-token?key=${generateAuth()}`, {
    method: 'GET',
    thunkAPI,
  });

const checkStatus = (thunkAPI: ThunkAPI) => () =>
  apiRequest(`${AUTH_URL}/check-status`, {
    method: 'GET',
    thunkAPI,
  });

const changePassword =
  (thunkAPI: ThunkAPI) =>
  ({ oldPassword, password, repeatedPassword }: ChangePasswordResponse) =>
    apiRequest(`${AUTH_URL}/change-password`, {
      method: 'POST',
      body: { oldPassword, password, repeatedPassword },
      thunkAPI,
    });

const resetPassword =
  (thunkAPI: ThunkAPI) =>
  ({
    passwordRequestHash,
    password,
    repeatedPassword,
  }: ResetPasswordResponse) =>
    apiRequest(`${AUTH_URL}/reset-password`, {
      method: 'POST',
      body: { passwordRequestHash, password, repeatedPassword },
      thunkAPI,
    });

const sendVerificationCode =
  (thunkAPI: ThunkAPI) =>
  ({ email }: SendVerificationCodePayload) =>
    apiRequest(`${AUTH_URL}/send-2step-login-code?email=${email}`, {
      method: 'GET',
      thunkAPI,
    });

const checkVerificationCode =
  (thunkAPI: ThunkAPI) =>
  ({
    email,
    password,
    remember,
    verificationCode,
  }: CheckVerificationCodePayload) =>
    apiRequest(`${AUTH_URL}/verify-2step-login-code`, {
      method: 'POST',
      body: { email, password, remember, verificationCode },
      thunkAPI,
    });

const verificationInfo = (thunkAPI: ThunkAPI) => () =>
  apiRequest<VerificationInfoResponse>(
    `${AUTH_URL}/2step-login-verification-info`,
    {
      method: 'GET',
      thunkAPI,
    }
  );

export const AuthAPI = {
  login,
  logout,
  getToken,
  checkStatus,
  loginViaOAuth,
  changePassword,
  resetPassword,
  sendVerificationCode,
  checkVerificationCode,
  verificationInfo,
} as const;
