/* eslint-disable no-console */
import { useEffect } from 'react';

import { ROOT_URL } from '@lib/constants/env.constants';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';

import { setAdBlockDetected } from '@store/banner/slice';

type AdBlockProps = {
  id: string;
  url: string;
};

const detectAdblock = () => {
  const adblockTests = {
    // https://github.com/uBlockOrigin/uAssets/blob/master/filters/filters-2022.txt
    // url: 'https://incolumitas.com/data/exosrv.js',
    uBlockOrigin: {
      url: `${ROOT_URL}/static/data/exosrv.js`,
      id: '837jlaBksSjd9jh',
    },
    // https://github.com/easylist/easylist/blob/master/easylist/easylist_general_block.txt
    // url: 'https://incolumitas.com/data/neutral.js?&adserver=',
    adblockPlus: {
      url: `${ROOT_URL}/static/data/neutral.js?&adserver=`,
      id: 'hfuBadsf3hFAk',
    },
  };

  const canLoadRemoteScript = (obj: AdBlockProps) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');

      script.onload = () => {
        if (document.getElementById(obj.id)) {
          resolve(false);
        } else {
          resolve(true);
        }
      };

      script.onerror = () => {
        resolve(true);
      };

      script.src = obj.url;
      document.body.appendChild(script);
    });
  };

  return new Promise((resolve, reject) => {
    const promises = [
      canLoadRemoteScript(adblockTests.uBlockOrigin),
      canLoadRemoteScript(adblockTests.adblockPlus),
    ];

    Promise.all(promises)
      .then((results) => {
        resolve({
          uBlockOrigin: results[0],
          adblockPlus: results[1],
          usingAdblock: results[0] === true || results[1] === true,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const useDetectAdBlock = () => {
  const dispatch = useAppDispatch();

  const checkAdBlock = () => {
    try {
      detectAdblock()
        .then(({ usingAdblock = false }) => {
          dispatch(setAdBlockDetected(usingAdblock));
          return usingAdblock;
        })
        .then((usingAdblock) => {
          if (!usingAdblock) {
            // grab a domain from https://github1s.com/gorhill/uBlock/blob/master/docs/tests/hostname-pool.js
            const url = 'https://www3.doubleclick.com';
            fetch(url, {
              method: 'HEAD',
              mode: 'no-cors',
              cache: 'no-store',
            }).catch(() => {
              try {
                dispatch(setAdBlockDetected(true));
              } catch (error) {
                console.error(error);
              }
            });
          }
        })
        .catch(() => {
          try {
            dispatch(setAdBlockDetected(true));
          } catch (error) {
            console.error(error);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    checkAdBlock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
