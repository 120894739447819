export const THEME_TYPES = {
  dark: 'dark',
  light: 'light',
  system: 'system',
};
export const MODE_TYPES = {
  cars: 'cars',
  jobs: 'jobs',
  properties: 'properties',
  default: 'default',
  category: 'category',
};

export const PAGE_TYPES = {
  index: 'index',
  adView: 'ad-view',
  search: 'search',
  category: 'category',
  group: 'group',
  allUserAds: 'allUserAds',
};

export const CHANNEL = {
  desktop: 'desktop_react',
  mobile: 'mobile_web_react',
  srrMobile: 'mobile_web_ssr',
  default: 'ssr_react',
};

export const DEVICE_TYPE = {
  desktop: 'desktop',
  mobile: 'mobile',
  default: 'desktop',
};
