/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames/bind';

import Spinner from '@components/common/responsive/Spinner/Spinner';

import Button, { ButtonProps } from './Button';

import style from './ButtonPrimaryBlue.module.scss';

const setClass = classNames.bind(style);

type ButtonPrimaryBlueProps = {
  className?: string;
};

const ButtonPrimaryBlue = ({
  className,
  ...props
}: ButtonProps & ButtonPrimaryBlueProps) => {
  return (
    <Button
      {...props}
      className={setClass(
        { primaryBlue: true, isDisabled: props.isDisabled, small: props.small },
        className
      )}
      loader={<Spinner />}
    />
  );
};

export default ButtonPrimaryBlue;
