import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { fetchHeadlineCategories } from './thunk';
import { CategoryHeadlineInitialState } from './type';

const initialState: CategoryHeadlineInitialState = {
  isLoading: false,
  headlineCategories: [],
};

const categoryHeadlineSlice = createSlice({
  name: 'categoryHeadline',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[categoryHeadlineSlice.name])) {
        Object.assign(state, action.payload.categoryHeadline);
      }
    });

    builder.addCase(fetchHeadlineCategories.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchHeadlineCategories.fulfilled, (state, action) => {
      state.headlineCategories = action.payload ?? [];
      state.isLoading = false;
    });
  },
});

export default categoryHeadlineSlice;
