import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import isEqual from 'react-fast-compare';
import { fetchPollData } from '@store/poll/thunk';
import { PollInitialState } from './type';

const initialState: PollInitialState = {
  isVoted: false,
  title: '',
  id: '',
  options: [],
  hasPoll: false,
};

const pollSlice = createSlice({
  name: 'poll',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[pollSlice.name])) {
        Object.assign(state, action.payload.poll);
      }
    });

    builder.addCase(fetchPollData.fulfilled, (state, action) => {
      if (action.payload) {
        state.isVoted = action.payload.isVoted;
        state.title = action.payload.title;
        state.options = action.payload.options;
        state.id = action.payload.id;
        state.hasPoll = true;
      }
    });
  },
});

export default pollSlice;
