import { decodeHTML } from 'src/lib/decodeHTML';
import { formatCurrency } from 'src/lib/localization/formatCurrency';
import { formatDate } from 'src/lib/localization/formatDate';

export type User = {
  username: string;
  created: string;
  userLocation: string;
  reviewsPositive: string | number;
  reviewsNegative: string | number;
  hasPhone: boolean;
  phone: string;
  allowInAddressBook: boolean;
  isUserInAddressBook: boolean;
  isOnline: boolean;
  isShowOnline: boolean;
  isShowAdsLink: boolean;
  userId: number;
  kpizlogUrl: string;
  userActiveAdCount: number;
  infoBox: string;
  messageResponseInfo: string;
  hasUserVerifiedBankAccount: boolean;
  userVerifiedBankAccountUrl: string;
  hasCompanyVerifiedBankAccount: boolean;
  companyVerifiedBankAccountUrl: string;
  companyPib: string;
  companyMbr: string;
};
export const createUser = (user: any): User => {
  return {
    username: decodeHTML(user?.name_decoded ?? user?.name) || '',
    created: formatDate(user?.created) || '',
    userLocation: user?.location_name || '',
    reviewsPositive: formatCurrency(user?.reviews_positive) || '0',
    reviewsNegative: formatCurrency(user?.reviews_negative) || '0',
    hasPhone: user?.has_phone || false,
    phone: user?.phone || '',
    allowInAddressBook: user?.allow_in_addressbook || false,
    isUserInAddressBook: user?.user_in_addressbook || false,
    isOnline: user?.is_online === 'yes',
    isShowOnline: user?.show_online || false,
    isShowAdsLink: user?.show_ads_link || false,
    userId: user?.user_id || '',
    kpizlogUrl: user?.kpizlog_url || '',
    userActiveAdCount: user?.user_active_ad_count || 0,
    infoBox: user?.info_box || '',
    messageResponseInfo: user?.message_response_info || '',
    hasUserVerifiedBankAccount: user?.has_user_verified_bank_account || false,
    userVerifiedBankAccountUrl: user?.user_verified_bank_account_url || '/',
    hasCompanyVerifiedBankAccount:
      user?.has_company_verified_bank_account || false,
    companyVerifiedBankAccountUrl:
      user?.company_verified_bank_account_url || '/',
    companyPib: user?.company_pib || '',
    companyMbr: user?.company_mbr || '',
  };
};
