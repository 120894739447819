import type { ThunkAPI } from '@store/configureStore';

import { apiRequest } from '../api/request/makeRequest';

import type { CategoryResponse, UserFavoriteCategoriesResponse } from './type';

const CATEGORIES_URL = 'categories';

const fetchCategories = (thunkAPI: ThunkAPI) => () =>
  apiRequest<CategoryResponse>(
    `${CATEGORIES_URL}?filters[banner_price_rank_exclude]=-1&filters[active]=visible`,
    { method: 'GET', thunkAPI }
  );

const fetchUserFavoriteCategories = (thunkAPI: ThunkAPI) => () =>
  apiRequest<UserFavoriteCategoriesResponse>(
    `${CATEGORIES_URL}/user-favorites`,
    {
      method: 'GET',
      thunkAPI,
    }
  );

export const CategoryAPI = {
  fetchCategories,
  fetchUserFavoriteCategories,
} as const;
