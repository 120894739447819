/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
import Router from 'next/router';
import { HYDRATE } from 'next-redux-wrapper';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import last from 'lodash/last';
import merge from 'lodash/merge';
import values from 'lodash/values';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { byPostedTimeAndMessageId } from '@lib/sort/byPostedTimeAndMessageId';

import { routeChangeComplete } from '@hooks/useResetState';

import {
  addToAddressBook,
  deleteFromAddressBook,
} from '@store/addressBook/thunk';
import { fetchInbox } from '@store/messageInbox/thunk';
import { createMessagePhoto } from '@store/messageThread/model';
import {
  fetchThread,
  fetchThreadPhotos,
  removeSpam,
  reportSpam,
  saveAddressMessage,
  saveMessage,
  savePhotoMessage,
  saveTrackingCodeMessage,
  userBlock,
  userUnblock,
} from '@store/messageThread/thunk';

import { MessageFetchedPhoto, MessageThreadInitialState } from './type';

const initialState: MessageThreadInitialState = {
  thread: {},
  photos: {
    activeMessagePhotos: [],
    activeMessagePhotosCount: 0,
  },
  isExpandedTextField: false,
  textFieldCaretPosition: 0,
  showAdditionalActions: false,
  newMessagesCount: 0,
  isSaveMessageLoading: false,
  chatScrollOffset: 0,
  photoToResend: '',
  isAddressMessageSend: false,
  isMessageFileSend: false,
  interceptorHeight: 0,
  showMessageInterceptor: true,
  showEmoji: false,
  isTrackingCodeMessageSend: false,
  isTrackingCodeNudgeVisible: false,
  isDeliverable: false,
  hasSentMessageThisSessionInThreads: {},
  isDExpressDeliveryGloballyAvailable: false,
  courierId: '',
  courierDeliveryMessageButtonInfo: {
    title: '',
    text: '',
  },
};

const messageThreadSlice = createSlice({
  name: 'messageThread',
  initialState,

  reducers: {
    setIsExpandedTextField(state, action) {
      state.isExpandedTextField = action.payload;
    },
    setTextFieldCaretPosition(state, action) {
      state.textFieldCaretPosition = action.payload;
    },
    setInterceptorHeight(state, action) {
      state.interceptorHeight = action.payload;
    },
    setShowAdditionalActions(state, action) {
      state.showAdditionalActions = action.payload;
    },
    setNewMessagesCount(state, action) {
      state.newMessagesCount = action.payload;
    },
    setChatScrollOffset(state, action) {
      state.chatScrollOffset = action.payload;
    },
    setShowEmoji(state, action) {
      state.showEmoji = action.payload;
    },
    pushUploadedPhotosAsMessages(state, action) {
      const { threadId, uploadingPhotoMessage } = action.payload;
      const duplicateFreeMessages = state.thread[threadId].messages.filter(
        (message) => message.messageId !== uploadingPhotoMessage.messageId
      );
      state.thread[threadId].messages = [
        uploadingPhotoMessage,
        ...duplicateFreeMessages,
      ];
    },
    changeErrorMessageItem(state, action) {
      const { photoMessage, threadId } = action?.payload;
      state.photoToResend = '';
      state.thread[threadId].messages = state.thread[threadId].messages.map(
        (message) =>
          message.messageId !== photoMessage.messageId ? message : photoMessage
      );
    },
    changeMessageItem(state, action) {
      const { photoMessage, threadId } = action.payload;
      state.thread[threadId].messages = state.thread[threadId].messages
        .map((message) =>
          message.messageId !== photoMessage?.id
            ? message
            : photoMessage.message
        )
        .sort(byPostedTimeAndMessageId);
    },
    setPhotoToResend(state, action) {
      state.photoToResend = action.payload;
    },
    setProgressPercent(state, action) {
      const { progressEvent, file, threadId } = action.payload;
      const percent = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      state.thread[threadId].messages = state.thread[threadId].messages.map(
        (message) =>
          message.messageId !== file.id
            ? message
            : {
                ...message,
                photo: {
                  ...file,
                  percent,
                  status: 'loading',
                },
              }
      );
    },
    setHasOldMessages(state, action) {
      const { hasOldMessages, threadId } = action?.payload;
      state.thread[threadId].hasOldMessages = hasOldMessages;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[messageThreadSlice.name])) {
        Object.assign(state, action.payload.message);
      }
    });

    builder.addCase(routeChangeComplete, (state) => {
      state.isExpandedTextField = false;
      state.textFieldCaretPosition = 0;
      state.showAdditionalActions = false;
      state.showMessageInterceptor = true;
    });

    builder.addCase(fetchThreadPhotos.fulfilled, (state, action) => {
      const { activePhotosCount = 0, photos = [] as MessageFetchedPhoto[] } =
        action.payload;
      state.photos = {
        activeMessagePhotos: photos.map((photo) => {
          return createMessagePhoto(photo);
        }),
        activeMessagePhotosCount: activePhotosCount,
      };
    });

    builder.addCase(fetchThread.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const {
        query,
        query: { adId, user2Id, conversationId, feedbackId },
      } = Router.router;
      if (meta?.arg?.query?.user2Id !== query?.user2Id) return;
      const threadId = `${adId}-${user2Id}-${feedbackId}-${conversationId}`;

      const {
        messages,
        conversationInfo,
        newMessagesCount = 0,
        moreMessages,
        isLastPostedQuery,
        isDExpressDeliveryGloballyAvailable,
        courierId,
        courierDeliveryMessageButtonInfo,
        ...rest
      } = payload;

      state.courierId = courierId;
      state.isDExpressDeliveryGloballyAvailable =
        isDExpressDeliveryGloballyAvailable;
      state.courierDeliveryMessageButtonInfo = courierDeliveryMessageButtonInfo;

      state.thread[threadId] = {
        ...state.thread[threadId],
        ...rest,
      };
      state.thread[threadId].isLoading = false;

      if (!state.thread[threadId].repeatFetch || meta?.arg?.query?.oldMessage) {
        state.thread[threadId].hasOldMessages = moreMessages;
      }
      state.thread[threadId].repeatFetch = true;

      const merged = merge(
        keyBy(state.thread[threadId].messages, 'messageId'),
        keyBy(messages, 'messageId')
      );
      const newMessages = values(merged).sort(byPostedTimeAndMessageId);
      state.thread[threadId].messages = newMessages;
      state.newMessagesCount += newMessagesCount;

      state.thread[threadId].conversationInfo = {
        ...state.thread[threadId].conversationInfo,
        ...conversationInfo,
      };

      const maxThreadsReadTime = messages
        .map((message) => message.user2ReadTime)
        .sort();
      const noReadTimeStart =
        state.thread[threadId].readTimeStart === undefined ||
        state.thread[threadId].readTimeStart === '0000-00-00 00:00:00';
      if (!isEmpty(messages) && isLastPostedQuery) {
        state.thread[threadId].readTimeStart = !noReadTimeStart
          ? last(maxThreadsReadTime)
          : state.thread[threadId].lastPosted;
      } else if (!isEmpty(messages)) {
        state.thread[threadId].firstPostedMessage =
          last(messages).postedDatetime;
        state.thread[threadId].readTimeStart = !noReadTimeStart
          ? last(maxThreadsReadTime)
          : state.thread[threadId].lastPosted;
      }
    });

    builder.addCase(fetchInbox.fulfilled, (state) => {
      state.showAdditionalActions = false;
      state.showEmoji = false;
    });

    builder.addCase(reportSpam.fulfilled, (state) => {
      const {
        query: { adId, user2Id, conversationId, feedbackId },
      } = Router.router;
      const threadId = `${adId}-${user2Id}-${feedbackId}-${conversationId}`;
      state.thread[threadId].conversationInfo.isReportedSpam = true;
    });

    builder.addCase(removeSpam.fulfilled, (state) => {
      const {
        query: { adId, user2Id, conversationId, feedbackId },
      } = Router.router;
      const threadId = `${adId}-${user2Id}-${feedbackId}-${conversationId}`;
      state.thread[threadId].conversationInfo.isReportedSpam = false;
    });

    builder.addCase(saveMessage.pending, (state) => {
      state.isSaveMessageLoading = true;
    });

    builder.addCase(saveMessage.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const { threadId = '' } = meta.arg;
      const { message = {} } = payload;
      state.thread[threadId].messages = [
        message,
        ...state.thread[threadId].messages,
      ].sort(byPostedTimeAndMessageId);
      state.hasSentMessageThisSessionInThreads[threadId] = true;

      state.isSaveMessageLoading = false;
    });

    builder.addCase(savePhotoMessage.fulfilled, (state) => {
      state.isMessageFileSend = true;
    });

    builder.addCase(saveMessage.rejected, (state) => {
      state.isSaveMessageLoading = false;
    });

    builder.addCase(saveAddressMessage.pending, (state) => {
      state.isSaveMessageLoading = true;
    });

    builder.addCase(saveAddressMessage.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const { threadId = '' } = meta.arg;
      const { message } = payload;
      state.thread[threadId].messages = [
        message,
        ...state.thread[threadId].messages,
      ].sort(byPostedTimeAndMessageId);

      state.isSaveMessageLoading = false;
      state.isAddressMessageSend = true;
      state.showMessageInterceptor = false;
    });

    builder.addCase(saveAddressMessage.rejected, (state) => {
      state.isSaveMessageLoading = false;
    });

    builder.addCase(saveTrackingCodeMessage.pending, (state) => {
      state.isSaveMessageLoading = true;
    });

    builder.addCase(saveTrackingCodeMessage.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const { threadId = '' } = meta.arg;
      const { message } = payload;
      state.thread[threadId].messages = [
        message,
        ...state.thread[threadId]?.messages,
      ].sort(byPostedTimeAndMessageId);

      state.isSaveMessageLoading = false;
      state.isTrackingCodeMessageSend = true;
    });

    builder.addCase(saveTrackingCodeMessage.rejected, (state) => {
      state.isSaveMessageLoading = false;
    });

    builder.addCase(userUnblock.fulfilled, (state, action) => {
      const { meta } = action;
      const { userId } = meta.arg;

      Object.values(state.thread).forEach((thread) => {
        if (thread.conversationInfo.user2Id === userId) {
          thread.conversationInfo.user2Blocked = false;
        }
      });
    });

    builder.addCase(userBlock.fulfilled, (state, action) => {
      const { meta } = action;
      const { userId } = meta.arg;

      Object.values(state.thread).forEach((thread) => {
        if (thread.conversationInfo.user2Id === userId) {
          thread.conversationInfo.user2Blocked = true;
        }
      });
    });

    builder.addCase(deleteFromAddressBook.fulfilled, (state, action) => {
      const { meta } = action;
      const { threadId } = meta.arg;
      if (threadId) {
        state.thread[threadId].conversationInfo.isUserInAddressBook = false;
      }
    });

    builder.addCase(addToAddressBook.fulfilled, (state, action) => {
      const { meta } = action;
      const { threadId = '' } = meta.arg;
      if (threadId) {
        state.thread[threadId].conversationInfo.isUserInAddressBook = true;
      }
    });
  },
});

export const {
  setIsExpandedTextField,
  setShowAdditionalActions,
  setTextFieldCaretPosition,
  setNewMessagesCount,
  setChatScrollOffset,
  pushUploadedPhotosAsMessages,
  changeErrorMessageItem,
  setPhotoToResend,
  setHasOldMessages,
  setProgressPercent,
  changeMessageItem,
  setInterceptorHeight,
  setShowEmoji,
} = messageThreadSlice.actions;

export default messageThreadSlice;
