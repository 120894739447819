const cyrillic =
  'А Б В Г Д Ђ Е Ё Ж З И Й Ј К Л Љ М Н Њ О П Р С Т Ћ У Ф Х Ц Ч Џ Ш Щ Ъ Ы Ь Э Ю Я а б в г д ђ е ё ж з и й ј к л љ м н њ о п р с т ћ у ф х ц ч џ ш щ ъ ы ь э ю я'.split(
    ' '
  );
const latin =
  'A B V G D Đ E Ë Ž Z I J J K L Lj M N Nj O P R S T Ć U F H C Č Dž Š Ŝ ʺ Y ʹ È Û Â a b v g d đ e ë ž z i j j k l lj m n nj o p r s t ć u f h c č dž š ŝ ʺ y ʹ è û â'.split(
    ' '
  );
const latinNonUnicode = 'Š Đ Č Ć Ž ǋ ǈ ǅ š đ č ć ž ǌ ǉ ǆ '.split(' ');
const latinUnicode = 'S DJ C C Z NJ LJ DZ s dj c c z nj lj dz'.split(' ');

const toLatin = (string) =>
  string
    .split('')
    .map((char) => {
      const index = cyrillic.indexOf(char);

      if (!~index) {
        return char;
      }

      return latin[index];
    })
    .join('');

const toUrlLatin = (string) =>
  string
    .split('')
    .map((char) => {
      const index = latinNonUnicode.indexOf(char);

      if (!~index) {
        return char;
      }

      return latinUnicode[index];
    })
    .join('');

const toLowerCase = (string) => string.toLowerCase();

module.exports = (string) => toLowerCase(toUrlLatin(toLatin(string)));
