import { createAppAsyncThunk } from '@store/global/utils';
import { ReviewAPI } from './api';

import { reviewEvents } from '@lib/events/review.event';
import { toggleModal } from '@store/modal/slice';
import {
  PostReviewPayload,
  PostReviewReplyPayload,
  ReviewListPayload,
} from './type';

export const fetchReviewForAd = createAppAsyncThunk(
  'review/fetchReviewForAd',
  async (payload: string, thunkAPI) => {
    const { data } = await ReviewAPI.fetchReviewForAd(thunkAPI)(payload);

    return data.results.reviews;
  }
);

export const fetchReviewList = createAppAsyncThunk(
  'review/fetchReviewList',
  async (payload: ReviewListPayload, thunkAPI) => {
    const { data } = await ReviewAPI.fetchReviewList(thunkAPI)(payload);

    return data.results;
  }
);

export const fetchUserCheck = createAppAsyncThunk(
  'review/fetchUserCheck',
  async (payload: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ReviewAPI.fetchUserCheck(thunkAPI)(payload);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const postReview = createAppAsyncThunk(
  'review/postReview',
  async (payload: PostReviewPayload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const { data } = await ReviewAPI.postReview(thunkAPI)({ data: payload });

      if (data.success) {
        reviewEvents.successReview(data.results.user_type);
        dispatch(fetchReviewList(payload.query));
      }

      return data.results;
    } catch (error) {
      dispatch(toggleModal(null));

      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const postReviewReply = createAppAsyncThunk(
  'review/postReviewReply',
  async (payload: PostReviewReplyPayload, thunkAPI) => {
    const { data } = await ReviewAPI.postReviewReply(thunkAPI)({
      data: payload,
    });
    return data.results;
  }
);
