import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { fetchAntiBotHumanCheck, saveAreYouHuman } from '@store/antiBot/thunk';

import {
  AntiBotHumanErrors,
  AntiBotInitialState,
  AntiBotResults,
} from './type';

const initialState: AntiBotInitialState = {
  antiBotHumanCheck: {} as AntiBotResults,
  areYouHumanError: {} as AntiBotHumanErrors,
  captchaSiteKey: '',
};

const antiBotSlice = createSlice({
  name: 'antiBot',
  initialState,

  reducers: {
    setCaptchaSiteKey: (state, action) => {
      state.captchaSiteKey = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[antiBotSlice.name])) {
        Object.assign(state, action.payload.antiBot);
      }
    });

    builder.addCase(fetchAntiBotHumanCheck.fulfilled, (state, action) => {
      state.antiBotHumanCheck = action.payload;
    });

    builder.addCase(saveAreYouHuman.rejected, (state, action) => {
      state.areYouHumanError = action.payload;
    });
  },
});

export const { setCaptchaSiteKey } = antiBotSlice.actions;

export default antiBotSlice;
