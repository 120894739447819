import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import { BookInfoPayload, BookInfoResponse } from './type';

const BOOK_URL = 'book';

const fetchBookInfo =
  (thunkAPI: ThunkAPI) =>
  ({ ean }: BookInfoPayload) =>
    apiRequest<BookInfoResponse>(`${BOOK_URL}/get-book-by-ean?ean=${ean}`, {
      method: 'GET',
      thunkAPI,
    });

export const BookAPI = {
  fetchBookInfo,
} as const;
