import { apiRequest } from '@store/api/request/makeRequest';
import type {
  BlockedInfoResponse,
  ChangeEmailPayload,
  CreatePasswordPayload,
  ForgotPasswordPayload,
  SaveInfoBoxPayload,
} from './type';
import type { ThunkAPI } from '@store/configureStore';

const USER_URL = 'me';

const fetchBlockedInfo = (thunkAPI: ThunkAPI) => () =>
  apiRequest<BlockedInfoResponse>(`${USER_URL}/blocked-info`, {
    method: 'GET',
    thunkAPI,
  });

const forgotPassword =
  (thunkAPI: ThunkAPI) =>
  ({ email }: ForgotPasswordPayload) =>
    apiRequest(`${USER_URL}/forgot-password`, {
      method: 'POST',
      body: { email },
      thunkAPI,
    });

const resendActivationEmail = (thunkAPI: ThunkAPI) => (email: string) =>
  apiRequest(`${USER_URL}/resend-activation-email`, {
    method: 'POST',
    body: { email },
    thunkAPI,
  });

const resendActivationEmailChange = (thunkAPI: ThunkAPI) => (email: string) =>
  apiRequest(`${USER_URL}/resend-email-change-email`, {
    method: 'POST',
    body: { email },
    thunkAPI,
  });

const softBlockUnblock = (thunkAPI: ThunkAPI) => () =>
  apiRequest(`${USER_URL}/softblock/unblock`, { method: 'GET', thunkAPI });

const changeEmailRequest =
  (thunkAPI: ThunkAPI) =>
  ({ newEmail, password }: ChangeEmailPayload) =>
    apiRequest(`${USER_URL}/change-email-request`, {
      method: 'POST',
      body: { newEmail, password },
      thunkAPI,
    });

const acceptRulesAgreement = (thunkAPI: ThunkAPI) => () =>
  apiRequest(`${USER_URL}/accept-rules-agreement`, {
    method: 'POST',
    thunkAPI,
  });

const changeEmail = (thunkAPI: ThunkAPI) => (body: ChangeEmailPayload) =>
  apiRequest(`${USER_URL}/change-email`, {
    method: 'POST',
    body,
    thunkAPI,
  });

const createPassword =
  (thunkAPI: ThunkAPI) =>
  ({ password, repeatedPassword }: CreatePasswordPayload) =>
    apiRequest(`${USER_URL}/create-password`, {
      method: 'POST',
      body: { password, repeatedPassword },
      thunkAPI,
    });

const saveInfoBox = (thunkAPI: ThunkAPI) => (data: SaveInfoBoxPayload) =>
  apiRequest(`${USER_URL}/info-box/save`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

export const MeAccountAPI = {
  fetchBlockedInfo,
  forgotPassword,
  resendActivationEmail,
  resendActivationEmailChange,
  softBlockUnblock,
  acceptRulesAgreement,
  changeEmailRequest,
  changeEmail,
  createPassword,
  saveInfoBox,
} as const;
