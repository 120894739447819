import { toLatin } from 'src/lib/localization/cyrillicToLatin';

import type { ThunkAPI } from '@store/configureStore';

import { apiRequest } from '../api/request/makeRequest';

import type {
  SuggestedGroupsResponse,
  SuggestedSearchPayload,
  SuggestedSearchResponse,
} from './type';

const CLASSIFIER_URL = 'niri';

const fetchSuggestedGroups = (thunkAPI: ThunkAPI) => (payload: string) =>
  apiRequest<SuggestedGroupsResponse>(
    `${CLASSIFIER_URL}?query=${toLatin(
      encodeURI(payload)
    )}&include[bannerPriceRank]=true`,
    {
      method: 'GET',
      thunkAPI,
    }
  );

const fetchSuggestedSearch =
  (thunkAPI: ThunkAPI) => (data: SuggestedSearchPayload) =>
    apiRequest<SuggestedSearchResponse>(`${CLASSIFIER_URL}/suggested-search`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

export const ClassifierAPI = {
  fetchSuggestedGroups,
  fetchSuggestedSearch,
} as const;
