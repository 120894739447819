/* eslint-disable import/no-cycle */
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { createAppAsyncThunk } from '@store/global/utils';
import { toggleModal } from '@store/modal/slice';

import { MeAdsAPI } from './api';
import { FetchUserAdsPayload } from './type';

const debugQuery = [
  'node_js_pid',
  'node_js_hostname',
  'node_js_session',
  'node_js_ip',
  'node_js_uuid',
  'node_js_url',
];

export const fetchUserAds = createAppAsyncThunk(
  'meAds/fetchUserAds',

  async (payload: FetchUserAdsPayload, thunkAPI) => {
    const { query } = payload;
    const {
      data: { results },
    } = await MeAdsAPI.fetchUserAds(thunkAPI)(payload);

    const filters = omit(query, [
      'order',
      'status',
      'page',
      'infoBox',
      ...debugQuery,
    ]);
    results.isNotFound = !isEmpty(filters) && results.totalPages === 0;

    return results;
  }
);

export const ignoreUserAds = createAppAsyncThunk(
  'meAds/ignoreUserAds',
  async (payload: string | number, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await MeAdsAPI.ignoreUserAds(thunkAPI)(payload);
    dispatch(toggleModal(null));

    return data.results;
  }
);

export const unIgnoreUserAds = createAppAsyncThunk(
  'meAds/unIgnoreUserAds',
  async (payload: string | number, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await MeAdsAPI.unIgnoreUserAds(thunkAPI)(payload);
    dispatch(toggleModal(null));

    return data.results;
  }
);
