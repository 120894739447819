import enc from 'crypto-js/enc-hex';
import sha1 from 'crypto-js/sha1';

import isServer from '@lib/ssrSetup/isServer';

const genSigFirstPart = (s) => {
  return s
    .split('')
    .map((ss) => {
      return ss + ss + ss + ss + ss;
    })
    .join('');
};

const genSigSecondPart = (s) => {
  return s.slice(2) + s.slice(0, 2);
};

const genSigThirdPart = (s) => {
  return s.replace(/(..)./g, '$1|');
};

const getSigFirstPart = () => {
  const s = 'Nvt3ZRK';
  return genSigFirstPart(s);
};

const getSigSecondPart = () => {
  const s = 'f64nGz7';
  return genSigSecondPart(s);
};

const getSigThirdPart = () => {
  const s = 'GdxpEd5';
  return genSigThirdPart(s);
};

export const generateSignature = (requestUrl, requestBody) => {
  const isFormData = !isServer && requestBody instanceof FormData;
  const serialized =
    requestBody instanceof Object && !isFormData
      ? JSON.stringify(requestBody)
      : '';

  const key = `${requestUrl}${serialized}${getSigFirstPart()}${getSigSecondPart()}${getSigThirdPart()}`;
  return enc.stringify(sha1(key));
};

export const generateAuth = () => (+new Date()).toString(36).slice(-10);
