export const formatDateWithHours = (d = new Date()) => {
  let date: Date;

  if (d === null) {
    // eslint-disable-next-line no-param-reassign
    d = new Date();
  }

  if (typeof d === 'string') {
    date = new Date((d as string).replace(/-/g, '/'));
  } else {
    date = d;
  }

  let hours = date.getHours();
  let minutes: string | number = date.getMinutes();
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const time = `${hours}:${minutes}h`;

  return `${[date.getDate(), date.getMonth() + 1, date.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('.')} ${time}`;
};
