/* eslint-disable consistent-return */
import { NextPageContext } from 'next';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { ssrRedirect } from '@lib/ssrRedirect';
import isServer from '@lib/ssrSetup/isServer';

const sanitizeRoute = async (
  ctx: NextPageContext,
  next: () => Promise<void>
) => {
  const { req, res, asPath } = ctx;

  if (!req || !isServer) return next();

  const splitResult = asPath?.split('?');

  const baseUrl = splitResult[0];

  const sanitizedBaseUrl = baseUrl.replace(/\/{2,}/g, '/');

  if (!isEqual(sanitizedBaseUrl, baseUrl)) {
    const redirectUrl = isEmpty(splitResult?.[1])
      ? sanitizedBaseUrl
      : `${sanitizedBaseUrl}?${splitResult?.[1]}`;

    ssrRedirect(res, redirectUrl);
    return true;
  }

  return next();
};

export default sanitizeRoute;
