/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-html-link-for-pages */
import React from 'react';

import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectIsMobile } from '@store/meta/selector';

import ErrorUnderConstructionBlacklistMode from './ErrorUnderConstructionBlacklistMode';
import ErrorUnderConstructionMaintenanceMode from './ErrorUnderConstructionMaintenanceMode';

import style from './ErrorUnderConstruction.module.scss';

type ErrorUnderConstructionProps = {
  isBlacklistMode: boolean;
};

const ErrorUnderConstruction = ({
  isBlacklistMode,
}: ErrorUnderConstructionProps) => {
  const isMobile = useAppSelector(selectIsMobile);
  const isBlacklistModeAndMobile = isBlacklistMode && isMobile;

  return (
    <div className={style.container}>
      <div className={`${style.content} ${style.centerAlign}`}>
        <a href="/" title="KupujemProdajem" className={style.logo}>
          <img
            src="https://www.kupujemprodajem.com/images/kp-logo.svg"
            alt="KupujemProdajem"
          />
        </a>

        {!isBlacklistModeAndMobile ? (
          <ErrorUnderConstructionMaintenanceMode />
        ) : (
          <ErrorUnderConstructionBlacklistMode />
        )}

        <div className={`${style.animationHolder} ${style.noselect}`}>
          <img
            className={style.gifAnimation}
            data-name="gif"
            src="https://www.kupujemprodajem.com/images/maintenance/maintenance-construction.gif"
            alt="Uskoro ćemo ponovo biti dostupni. Hvala na razumevanju! Vaš kp tim."
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorUnderConstruction;
