export type Condition = 'new' | 'as-new' | 'used' | 'damaged';
export type ConditionName =
  | 'Novo'
  | 'Kao novo - nekorišćeno'
  | 'Korišćeno'
  | 'Neispravno ili oštećeno';

export const getCondition = (condition: Condition): ConditionName => {
  const conditionsMap = {
    new: 'Novo',
    'as-new': 'Nekorišćeno (polovno)',
    used: 'Korišćeno (polovno)',
    damaged: 'Oštećeno ili neispravno',
  };
  return conditionsMap[condition] as ConditionName;
};
