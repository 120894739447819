import isEmpty from 'lodash/isEmpty';
import { AxiosHeaderValue } from 'axios';

import type { ThunkAPI } from '@store/configureStore';
import { setBlacklistMode, setMaintenanceMode } from '@store/meta/slice';

export const handleMaintenanceMode = (
  thunkAPI: ThunkAPI,
  responseHeaders: { [key: string]: AxiosHeaderValue } = {}
) => {
  const { getState, dispatch } = thunkAPI;
  const { isMaintenanceMode, isBlacklistMode } = getState().meta;
  const responseType = responseHeaders['x-kp-response-type'];
  const blacklistResponseType = responseHeaders['x-kp-maintenance-type'];

  const shouldSetMaintenanceMode =
    !isMaintenanceMode &&
    responseType === 'Maintenance' &&
    isEmpty(blacklistResponseType);

  const shouldSetBlacklistMode =
    !isBlacklistMode && !isEmpty(blacklistResponseType);

  if (shouldSetMaintenanceMode) {
    dispatch(setMaintenanceMode(true));
  }

  if (shouldSetBlacklistMode) {
    dispatch(setBlacklistMode(true));
  }
};
