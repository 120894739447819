import { formatStringToNumber } from './localization/formatStringToNumber';

type GetWordPluralFormProps = {
  count: number | string;
  singularWord: string;
  fewWord: string;
  manyWord: string;
  zeroWord: string;
};

export const getWordPluralForm = ({
  count,
  singularWord,
  fewWord,
  manyWord,
  zeroWord,
}: GetWordPluralFormProps) => {
  const countNum = formatStringToNumber(count);
  if (countNum === 0 && zeroWord) return zeroWord;

  const lastDigit = countNum % 10;
  const lastTwoDigits = countNum % 100;

  const singularWordCondition = lastDigit === 1 && lastTwoDigits !== 11;
  const fewWordCondition =
    lastDigit >= 2 &&
    lastDigit <= 4 &&
    (lastTwoDigits < 10 || lastTwoDigits >= 20);

  if (singularWordCondition) return singularWord;
  if (fewWordCondition) return fewWord;

  return manyWord;
};
