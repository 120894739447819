import { createSelector } from 'reselect';

import { authSelector } from '../rootSelector';

import { AuthInitialState } from './type';

export const selectIsUserLogged = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => !!slicedState.token
);

export const selectIsTokenFetched = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.token !== '0'
);

export const selectToken = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.token
);

export const selectAuthErrors = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.errors
);

export const selectSuccessfulLogin = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.isSuccessfulLogin
);

export const selectIsLoading = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.isLoading
);

export const selectLoading = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.loading
);

export const selectIsRequestedChangeEmail = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.isRequestedChangeEmail
);

export const selectResetPasswordActiveStep = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.resetPassword.activeStep
);

export const selectQueryParams = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.queryParams
);

export const selectVerificationErrors = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.verification.errors
);

export const selectValidationCheckPayload = createSelector(
  authSelector,
  (slicedState: AuthInitialState) =>
    slicedState.verification.validationCheckPayload
);

export const selectValidationErrors = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.verification.errorCode
);

export const selectValidationInfo = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.verification.cancelationInfo
);

export const selectLoginReasonInfo = createSelector(
  authSelector,
  (slicedState: AuthInitialState) => slicedState.loginReasonInfo
);
