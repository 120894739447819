import type { ThunkAPI } from '@store/configureStore';

import { apiRequest } from '../api/request/makeRequest';

import type { GroupCarResponse, GroupPayload, GroupResponse } from './type';

const GROUPS_URL = 'groups';

const fetchGroups =
  (thunkAPI: ThunkAPI) =>
  ({ categoryId, filters }: GroupPayload) => {
    const searchParamsObj = {
      'filters[banner_price_rank_exclude]': '-1',
      'filters[active]': 'visible',
      ...filters,
    };
    const searchParams = new URLSearchParams(searchParamsObj).toString();

    return apiRequest<GroupResponse>(
      `categories/${categoryId}/${GROUPS_URL}?${searchParams}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );
  };

const fetchCarModels = (thunkAPI: ThunkAPI) => (groupId: string) =>
  apiRequest<GroupCarResponse>(`categories/car/${groupId}`, {
    method: 'GET',
    thunkAPI,
  });

export const GroupAPI = {
  fetchGroups,
  fetchCarModels,
} as const;
