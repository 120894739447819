import { createAppAsyncThunk } from '@store/global/utils';
import { toggleModal } from '@store/modal/slice';

import { ClassifierAPI } from './api';
import { SuggestedSearchPayload } from './type';

export const fetchSuggestedGroups = createAppAsyncThunk(
  'classifier/fetchSuggestedGroups',
  async (payload: string, thunkAPI) => {
    const { data } = await ClassifierAPI.fetchSuggestedGroups(thunkAPI)(
      payload
    );

    return data.categories;
  }
);

export const fetchSuggestedSearch = createAppAsyncThunk(
  'classifier/fetchSuggestedSearch',
  async (payload: SuggestedSearchPayload, thunkAPI) => {
    const { data } = await ClassifierAPI.fetchSuggestedSearch(thunkAPI)(
      payload
    );

    return data.results;
  }
);

export const setGroupFromSuggestions = createAppAsyncThunk(
  'classifier/setGroupFromSuggestions',
  async (_, { dispatch }) => {
    dispatch(toggleModal(null));
  }
);
