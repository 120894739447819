export type Location = {
  id: number;
  name: string;
  big: boolean;
};

export const createLocation = (location: Location) => {
  return {
    id: location.id,
    name: location.name,
    big: location.big,
  };
};
