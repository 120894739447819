export const maintenanceModeMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (
      action.type === 'meta/setMaintenanceMode' ||
      action.type.includes('pending') ||
      !getState().meta.isMaintenanceMode
    ) {
      next(action);
    }
  };
