import snakeToCamel from '@lib/string/snakeToCamel';

const makeQueryString = (searchParams: URLSearchParams): string => {
  const pairs: string[] = [];

  searchParams.forEach((value, key) => {
    const flatKey = key.replace(/^data\[(.*)\]$/, '$1');

    pairs.push(`${snakeToCamel(flatKey)}=${value}`);
  });

  return pairs.join('&');
};

export default makeQueryString;
