import isEmpty from 'lodash/isEmpty';
import { formatDate } from 'src/lib/localization/formatDate';
import { NotificationItemAd, NotificationItemType } from './type';

export const createNotificationAd = (notificationAds: any) => {
  return notificationAds.map((notificationAd: any): NotificationItemAd => {
    return {
      adId: notificationAd.ad_id,
      adName: notificationAd.ad_name,
      adUrl: notificationAd.ad_url,
      adViewCount: notificationAd.ad_view_count,
      categoryName: notificationAd.category_name,
      groupName: notificationAd.group_name,
    };
  });
};

export const createNotification = (notification: any): NotificationItemType => {
  return {
    id: notification.notification_id,
    type: notification.notification_type,
    subtype: notification.notification_subtype,
    title: notification.notification_title,
    text: notification.notification_text,
    link: notification.notification_link,
    clickUrl: notification.clickUrl,
    linkParams: notification.link_params,
    status: notification.status,
    message: notification.message,
    info: notification.info,
    isTopSearchGold: notification.is_top_search_gold,
    posted: formatDate(notification.posted),
    changed: formatDate(notification.changed),
    statusChanged: notification.status_changed,
    readTime: notification.read_time,
    adsCount: notification.ads_count,
    ads: !isEmpty(notification.ads)
      ? createNotificationAd(notification.ads)
      : [],
    reviews: notification.reviews,
    reviewsCount: notification.reviews_count,
    fullType: `${notification.notification_type}_${notification.notification_subtype}`,
  };
};
