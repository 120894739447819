/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import Router from 'next/router';
import isEqual from 'lodash/isEqual';
import Cookies from 'js-cookie';

import { COOKIE_URL } from 'src/lib/constants/env.constants';
import { getRecentFilterIds } from 'src/lib/getRecentFilterIds';
import { normalize } from 'src/lib/normalize';

import { AreYouHumanRoute } from '@server/routes';

import { setCaptchaSiteKey } from '@store/antiBot/slice';
import { GeneralStatsAPI } from '@store/generalStats/api';
import { FiltersInfoPayload } from '@store/generalStats/type';
import { Error } from '@store/global/type';
import { createAppAsyncThunk } from '@store/global/utils';
import { toggleModal } from '@store/modal/slice';
import { searchAPI } from '@store/search/api';
import { seoTextAPI } from '@store/seoText/api';

import { SearchPayload } from './type';

export const fetchSearchRecent = createAppAsyncThunk(
  'search/fetchSearchRecent',
  async (payload: FiltersInfoPayload, thunkAPI) => {
    const response = await GeneralStatsAPI.fetchFiltersInfo(thunkAPI)(payload);
    return response.data.results;
  }
);

export const fetchSearchedAds = createAppAsyncThunk(
  'search/fetchSearchedAds',
  async (payload: SearchPayload, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    try {
      const { follow, order } = payload.query;

      const lastSearchQuery = getState()?.search?.lastSearchQuery?.query;

      const isLastSearchEqual = isEqual(lastSearchQuery, payload?.query);

      if (isLastSearchEqual && follow !== 'yes') {
        return getState()?.search?.lastSearchResult;
      }

      const response = await searchAPI.fetchSearchedAds(thunkAPI)(payload);

      const { filterId } = response.data.results;
      const canSaveToRecent = follow !== 'yes' && order !== 'newest';
      if (canSaveToRecent) {
        let filterIds = getRecentFilterIds();
        const filteredFilterIds = filterIds.filter((item) => item !== filterId);
        filterIds = [...filteredFilterIds, filterId];
        filterIds = filterIds.slice(Math.max(filterIds.length - 11, 0));
        Cookies.set('recentSearchFilterIds', JSON.stringify(filterIds), {
          expires: 1000,
          path: '/',
          domain: COOKIE_URL,
        });
      }

      if (response.data?.results?.captcha === 'soft') {
        dispatch(setCaptchaSiteKey(response.data.results.captchaSiteKey));
        dispatch(toggleModal('areYouHuman'));
      }

      return response.data.results;
    } catch (error) {
      const response = error.response.data;
      response.errors.map((e: Error) => {
        if (e.error_code === 'areYouHuman') {
          dispatch(setCaptchaSiteKey(response.captchaSiteKey));
          if (e.error_description === 'hard')
            Router.push(AreYouHumanRoute.generateUrl());
        }
      });
      return rejectWithValue(response);
    }
  }
);

export const fetchSeoCategoryText = createAppAsyncThunk(
  'search/fetchSeoCategoryText',
  async (payload: number, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await seoTextAPI.fetchSeoCategoryText(thunkAPI)(payload);
      return res.data.results;
    } catch (error) {
      return rejectWithValue(
        normalize(error.response.data.errors, 'error_code')
      );
    }
  }
);
