import { createSelector } from 'reselect';

import { MetaInitialState } from './type';

const metaStateSelector = (state: { meta: MetaInitialState }) => state.meta;

export const selectIsDeviceSet = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => !!slicedState.device
);

export const selectIsMobile = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => {
    try {
      return slicedState.deviceType === 'mobile';
    } catch (e) {
      return false;
    }
  }
);

export const selectIsMobileDevice = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => {
    try {
      return slicedState.device.platform.type === 'mobile';
    } catch (e) {
      return false;
    }
  }
);

export const selectIsTablet = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => {
    try {
      return slicedState.device.platform.type === 'tablet';
    } catch (e) {
      return false;
    }
  }
);

export const selectIsInternetExplorer = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => {
    try {
      return slicedState.device.browser.name === 'Internet Explorer';
    } catch (e) {
      return false;
    }
  }
);

export const selectMode = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => slicedState.mode
);

export const selectPageType = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => slicedState.pageType
);

export const selectMetaCategoryId = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => slicedState.categoryId
);

export const selectIsMaintenance = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => {
    return slicedState.isMaintenanceMode;
  }
);

export const selectIsBlacklist = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => {
    return slicedState.isBlacklistMode;
  }
);

export const selectIsIframe = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => {
    return slicedState.isIframe;
  }
);

export const selectActiveTheme = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => {
    return slicedState.activeTheme;
  }
);

export const selectIsDesktopRequested = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => {
    return slicedState.isDesktopRequested;
  }
);

export const selectScreenWidth = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => {
    return slicedState.screenWidth;
  }
);

export const selectIsGoogleBot = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => {
    const browserName = slicedState.device.browser.name;
    return browserName.includes('Googlebot');
  }
);

export const selectSoftBlockedInfo = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => slicedState.softBlockError
);

export const selectAgreementRules = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => slicedState.agreementRules
);

export const selectMetaPageUrl = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => slicedState.pageUrl
);

export const selectDeviceOS = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => slicedState.device.os.name
);

export const selectUserAgent = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => slicedState.locals.userAgent
);

export const selectBrowserName = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) => slicedState.device.browser.name
);

export const selectBrowserVersion = createSelector(
  metaStateSelector,
  (slicedState: MetaInitialState) =>
    slicedState.device?.browser?.version?.split('.')[0] ?? ''
);
