import adSlice from '@store/ad/slice';
import addressSlice from '@store/address/slice';
import addressBookSlice from '@store/addressBook/slice';
import adSaveSlice from '@store/adSave/slice';
import agreementSlice from '@store/agreement/slice';
import antiBotSlice from '@store/antiBot/slice';
import authSlice from '@store/auth/slice';
import bannerSlice from '@store/banner/slice';
import bookSlice from '@store/book/slice';
import categorySlice from '@store/category/slice';
import categoryBoxSlice from '@store/categoryBox/slice';
import categoryHeadlineSlice from '@store/categoryHeadline/slice';
import categoryInfoSlice from '@store/categoryInfo/slice';
import classifierSlice from '@store/classifier/slice';
import currencyConverterSlice from '@store/currencyConverter/slice';
import customValueSlice from '@store/customValue/slice';
import emojiSlice from '@store/emoji/slice';
import feedbackSlice from '@store/feedback/slice';
import fileSlice from '@store/file/slice';
import fraudSlice from '@store/fraud/slice';
import gdprSlice from '@store/gdpr/slice';
import statsSlice from '@store/generalStats/slice';
import groupSlice from '@store/group/slice';
import invoiceSlice from '@store/invoice/slice';
import jobSlice from '@store/job/slice';
import kpIzlogSlice from '@store/kpIzlog/slice';
import locationSlice from '@store/location/slice';
import meAccountSlice from '@store/meAccount/slice';
import meAdsSlice from '@store/meAds/slice';
import mediaKitSlice from '@store/mediakit/slice';
import meProfileSlice from '@store/meProfile/slice';
import messageInboxSlice from '@store/messageInbox/slice';
import messageThreadSlice from '@store/messageThread/slice';
import meStatsSlice from '@store/meStats/slice';
import metaSlice from '@store/meta/slice';
import modalSlice from '@store/modal/slice';
import myKpHeaderSlice from '@store/myKpHeader/slice';
import notificationSlice from '@store/notification/slice';
import pollSlice from '@store/poll/slice';
import promotionSlice from '@store/promotion/slice';
import rapidMessageSlice from '@store/rapidMessage/slice';
import recommendedSlice from '@store/recommended/slice';
import renewerSlice from '@store/renewer/slice';
import reviewSlice from '@store/review/slice';
import savedSearchSlice from '@store/savedSearch/slice';
import searchSlice from '@store/search/slice';
import searchFormSlice from '@store/searchForm/slice';
import shipmentTrackCourierSlice from '@store/shipmentTrackCourier/slice';
import shippingOrderStatusSlice from '@store/shippingOrderStatus/slice';
import shippingScheduleCourierSlice from '@store/shippingScheduleCourier/slice';
import smallBusinessSlice from '@store/smallBusiness/slice';
import staticPagesSlice from '@store/staticPages/slice';
import transactionSlice from '@store/transaction/slice';
import userSlice from '@store/user/slice';

const slices = {
  adSlice,
  adSaveSlice,
  addressSlice,
  addressBookSlice,
  agreementSlice,
  antiBotSlice,
  authSlice,
  bannerSlice,
  bookSlice,
  categorySlice,
  categoryBoxSlice,
  categoryHeadlineSlice,
  categoryInfoSlice,
  classifierSlice,
  currencyConverterSlice,
  customValueSlice,
  emojiSlice,
  feedbackSlice,
  fileSlice,
  fraudSlice,
  gdprSlice,
  groupSlice,
  invoiceSlice,
  jobSlice,
  kpIzlogSlice,
  locationSlice,
  meAccountSlice,
  meAdsSlice,
  mediaKitSlice,
  meProfileSlice,
  messageInboxSlice,
  messageThreadSlice,
  meStatsSlice,
  modalSlice,
  metaSlice,
  myKpHeaderSlice,
  notificationSlice,
  pollSlice,
  promotionSlice,
  rapidMessageSlice,
  recommendedSlice,
  renewerSlice,
  reviewSlice,
  savedSearchSlice,
  searchSlice,
  searchFormSlice,
  shipmentTrackCourierSlice,
  shippingScheduleCourierSlice,
  shippingOrderStatusSlice,
  smallBusinessSlice,
  staticPagesSlice,
  statsSlice,
  transactionSlice,
  userSlice,
};

export default slices;
