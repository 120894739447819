import { LocalStorageAPI } from '@lib/localStorage';

import { createAppAsyncThunk } from '@store/global/utils';
import { fetchUserGroupedInfo } from '@store/meStats/thunk';
import { showToast, toggleModal } from '@store/modal/slice';

import { RenewerAPI } from './api';
import { RenewerActivationPayload } from './type';

export const fetchRenewerInfo = createAppAsyncThunk(
  'renewer/fetchRenewerInfo',
  async (_, thunkAPI) => {
    const { data } = await RenewerAPI.fetchRenewerInfo(thunkAPI)();

    return data.results;
  }
);

export const fetchRenewerPrices = createAppAsyncThunk(
  'renewer/fetchRenewerPrices',
  async (_, thunkAPI) => {
    const { data } = await RenewerAPI.fetchRenewerPrices(thunkAPI)();

    return data.results;
  }
);

export const activateRenewer = createAppAsyncThunk(
  'renewer/activateRenewer',
  async (payload: RenewerActivationPayload, thunkAPI) => {
    const { dispatch, getState, rejectWithValue } = thunkAPI;
    try {
      LocalStorageAPI.removeItem('meStats/groupedInfo');
      const { data } = await RenewerAPI.activateRenewer(thunkAPI)(payload);

      const { isActive } = getState().renewer.info;

      if (data.success) {
        dispatch(toggleModal(null));
        dispatch(fetchRenewerInfo());
        dispatch(
          showToast({
            content: `Uspešno ste ${
              isActive ? 'produžili' : 'aktivirali'
            } KP Obnavljač`,
          })
        );
        dispatch(fetchUserGroupedInfo());
      }
      return data.results;
    } catch (error) {
      const { errors } = error.response.data;
      dispatch(toggleModal(null));
      dispatch(
        showToast({ type: 'warning', content: errors[0].error_description })
      );
      return rejectWithValue(errors);
    }
  }
);
