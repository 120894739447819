import { normalize } from '@lib/normalize';
import { API_URL } from '@lib/constants/env.constants';

import { toggleModal } from '@store/modal/slice';
import { invoiceAPI } from '@store/invoice/api';
import { createAppAsyncThunk } from '@store/global/utils';

import { InvoicePayload, InvoicePdfPayload } from './type';

export const fetchInvoices = createAppAsyncThunk(
  'invoice/fetchInvoices',
  async (payload: InvoicePayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await invoiceAPI.fetchInvoices(thunkAPI)(payload);

      return data;
    } catch (error) {
      return rejectWithValue(
        normalize(error.response.data.errors, 'error_code')
      );
    }
  }
);

export const fetchInvoicePdf = createAppAsyncThunk(
  'invoice/fetchInvoicePdf',
  async (payload: InvoicePdfPayload, thunkAPI) => {
    const { getState } = thunkAPI;
    const { data } = await invoiceAPI.fetchInvoicePdf(thunkAPI)(payload);

    const isMobile = getState().meta.deviceType === 'mobile';

    const target = isMobile ? '_top' : '_blank';

    setTimeout(() => {
      window.open(`${API_URL}${data.pdfLink}`, target);
    });

    return data;
  }
);

export const requestInvoiceZip = createAppAsyncThunk(
  'invoice/requestInvoiceZip',
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await invoiceAPI.requestInvoiceZip(thunkAPI)();

    if (data?.status === 'ready') {
      setTimeout(() => {
        window.open(`${API_URL}${data.link}`, '_top');
      });
    } else if (data.success) {
      dispatch(toggleModal('[INVOICE_ZIP_REQUEST]'));
    }

    return data;
  }
);
