import { formatSearchParams } from '@lib/formatSearchParams';

import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';
import { SaveTrackingCodePayload } from '@store/shipmentTrackCourier/type';

import type {
  BlockUserPayload,
  MessageThreadPayload,
  MessageThreadPhotoResponse,
  MessageTreadResponse,
  SaveAddressMessagePayload,
  SaveMessagePayload,
  SaveMessageResponse,
  SaveTrackingCodeResponse,
  SpamPayload,
  UnblockUserPayload,
} from './type';

const MESSAGE_URL = 'message';

const fetchThread = (thunkAPI: ThunkAPI) => (payload: MessageThreadPayload) => {
  const query = {
    ...payload.query,
    ...(payload.query.userId && { user2Id: payload.query.userId }),
  };
  const searchParamsObj = formatSearchParams(query);
  const searchParams = new URLSearchParams(searchParamsObj).toString();

  return apiRequest<MessageTreadResponse>(
    `${MESSAGE_URL}/thread?${searchParams}`,
    {
      method: 'GET',
      thunkAPI,
    }
  );
};

const fetchThreadPhotos =
  (thunkAPI: ThunkAPI) => (payload: MessageThreadPayload) => {
    const query = {
      ...payload.query,
      ...(payload.query.userId && { user2Id: payload.query.userId }),
    };
    const searchParamsObj = formatSearchParams(query);
    const searchParams = new URLSearchParams(searchParamsObj).toString();

    return apiRequest<MessageThreadPhotoResponse>(
      `${MESSAGE_URL}/thread-photos?${searchParams}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );
  };

const saveMessage = (thunkAPI: ThunkAPI) => (body: SaveMessagePayload) => {
  const { message = '', ...rest } = body;
  return apiRequest<SaveMessageResponse>(`${MESSAGE_URL}/save`, {
    method: 'POST',
    body: { message: message.trim(), ...rest },
    thunkAPI,
  });
};

const savePhotoMessage =
  (thunkAPI: ThunkAPI) =>
  (body: FormData, onUploadProgress?: (progressEvent: any) => void) => {
    return apiRequest<SaveMessageResponse>(`${MESSAGE_URL}/photo-save`, {
      method: 'POST',
      body,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
      thunkAPI,
    });
  };

const reportSpam = (thunkAPI: ThunkAPI) => (body: SpamPayload) =>
  apiRequest(`${MESSAGE_URL}/report-spam`, {
    method: 'POST',
    body,
    thunkAPI,
  });

const removeSpam = (thunkAPI: ThunkAPI) => (body: SpamPayload) =>
  apiRequest(`${MESSAGE_URL}/remove-spam`, {
    method: 'POST',
    body,
    thunkAPI,
  });

const userBlock = (thunkAPI: ThunkAPI) => (body: BlockUserPayload) =>
  apiRequest(`${MESSAGE_URL}/user-block`, {
    method: 'POST',
    body,
    thunkAPI,
  });

const userUnblock = (thunkAPI: ThunkAPI) => (body: UnblockUserPayload) =>
  apiRequest(`${MESSAGE_URL}/user-unblock`, {
    method: 'POST',
    body,
    thunkAPI,
  });

const saveAddressMessage =
  (thunkAPI: ThunkAPI) => (body: SaveAddressMessagePayload) =>
    apiRequest<SaveMessageResponse>(`${MESSAGE_URL}/address-save`, {
      method: 'POST',
      body,
      thunkAPI,
    });

const saveTrackingCodeMessage =
  (thunkAPI: ThunkAPI) => (body: SaveTrackingCodePayload) =>
    apiRequest<SaveTrackingCodeResponse>(`${MESSAGE_URL}/tracking-code-save`, {
      method: 'POST',
      body,
      thunkAPI,
    });

export const MessageAPI = {
  fetchThread,
  fetchThreadPhotos,
  saveMessage,
  savePhotoMessage,
  reportSpam,
  removeSpam,
  userBlock,
  userUnblock,
  saveAddressMessage,
  saveTrackingCodeMessage,
} as const;
