import { API_URL } from 'src/lib/constants/env.constants';
import { getPriceText } from 'src/lib/getPriceText';
import { formatCurrency } from 'src/lib/localization/formatCurrency';

import { CategoryBoxAd, CategoryBoxItem } from './type';

export const createCategoryBoxAd = (ad: any): CategoryBoxAd => {
  const getPriceCurrency = (priceCurrency: string) => {
    return priceCurrency === 'rsd' ? 'din' : '€';
  };
  const currency = getPriceCurrency(ad.currency);

  return {
    id: ad.id,
    name: ad.name,
    type: ad.ad_type,
    adKind: ad.ad_kind,
    price: formatCurrency(ad.price),
    currency,
    priceText:
      ad.price_text || getPriceText(ad.ad_type, ad.ad_kind, ad.price, currency),

    groupName: ad.group_name,
    categoryName: ad.category_name,
    carModelDesc: ad.car_model_desc,
    locationName: ad.location_name || '',
  };
};

export const createCategoryBox = (categoryBox: any): CategoryBoxItem => {
  return {
    id: categoryBox.category_id,
    name: categoryBox.name,
    adCount: formatCurrency(categoryBox.ad_count),
    ads: categoryBox.ads.map((ad: any) => createCategoryBoxAd(ad)),
    imageUrl: categoryBox.photo ? `${API_URL}/${categoryBox.photo}` : '',
    orderNo: categoryBox.order_no || 0,
    adKind: categoryBox.ad_kind || 'goods',
    parentId: categoryBox.parent_id || 0,
    parentName: categoryBox.parent_name || '',
  };
};
