import { createAppAsyncThunk } from '@store/global/utils';
import { CategoryBoxAPI } from './api';
import { CategoryBoxPayload } from './type';

export const fetchCategoryBox = createAppAsyncThunk(
  'categoryBox/fetchCategoryBox',
  async ({ kind }: CategoryBoxPayload, thunkAPI) => {
    const response = await CategoryBoxAPI.fetchCategoryBox(thunkAPI)({ kind });

    return { data: response.data.results, kind };
  }
);

export const fetchCategoryBoxById = createAppAsyncThunk(
  'categoryBox/fetchCategoryBoxById',
  async ({ categoryId, kind }: CategoryBoxPayload, thunkAPI) => {
    const response = await CategoryBoxAPI.fetchCategoryBoxById(thunkAPI)({
      categoryId,
      kind,
    });

    return { data: response.data.results, kind, categoryId };
  }
);
