/* eslint-disable import/no-cycle */
import Router from 'next/router';
import countBy from 'lodash/countBy';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';

import { CookiesAPI } from '@lib/common/cookies';
import { COOKIE_URL } from '@lib/constants/env.constants';
import { delay } from '@lib/delay';
import { isDocumentFile } from '@lib/message/isDocumentFile';

import { MyKPMessagesRoute } from '@server/routes';

import { createAppAsyncThunk } from '@store/global/utils';
import { logTrackingCode } from '@store/logging/thunk';
import { showToast, toggleModal } from '@store/modal/slice';
import {
  TrackingCodeEventAction,
  TrackingCodeSource,
} from '@store/shipmentTrackCourier/type';

import { MessageAPI } from './api';
import { setProgressPercent } from './slice';
import {
  BlockUserPayload,
  MessageThreadItem,
  MessageThreadPayload,
  RejectedPhotoError,
  SaveAddressMessagePayload,
  SaveMessagePayload,
  SavePhotoMessagePayload,
  SaveTrackingCodeMessagePayload,
  SpamPayload,
  UnblockUserPayload,
} from './type';

export const fetchThread = createAppAsyncThunk(
  'message/fetchThread',
  async (payload: MessageThreadPayload, thunkAPI) => {
    const { data } = await MessageAPI.fetchThread(thunkAPI)(payload);

    let newMessagesCount = 0;
    let isLastPostedQuery = false;
    if (payload.query.lastPosted) {
      newMessagesCount = countBy(
        data.results.messages,
        (message) => message.senderIsOwner === false
      ).true;
      isLastPostedQuery = true;
    }

    return { ...data.results, newMessagesCount, isLastPostedQuery };
  }
);

export const fetchThreadPhotos = createAppAsyncThunk(
  'message/fetchThreadPhotos',
  async (payload: MessageThreadPayload, thunkAPI) => {
    const { data } = await MessageAPI.fetchThreadPhotos(thunkAPI)(payload);

    return data.results;
  }
);

export const saveMessage = createAppAsyncThunk(
  'message/saveMessage',
  async (payload: SaveMessagePayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await MessageAPI.saveMessage(thunkAPI)(payload);
      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const savePhotoMessage = createAppAsyncThunk<
  { id: string; error: string; percent: number } & MessageThreadItem,
  SavePhotoMessagePayload,
  { rejectValue: RejectedPhotoError }
>('message/savePhotoMessage', async (payload, thunkAPI) => {
  const { rejectWithValue, dispatch, getState } = thunkAPI;
  const { file, adId, receiverId, threadId } = payload;
  const isDocument = isDocumentFile(file?.name);
  try {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('adId', String(adId));
    formData.append('receiverId', String(receiverId));

    const { data } = await MessageAPI.savePhotoMessage(thunkAPI)(
      formData,
      (progressEvent) => {
        return dispatch(setProgressPercent({ progressEvent, file, threadId }));
      }
    );
    if (data.success && isDocument) {
      Cookies.set('message_upload_type_education_new_label', '1', {
        expires: 1000,
        path: '/',
        domain: COOKIE_URL,
      });
    }
    return { id: file.id, error: '', percent: 100, ...data.results };
  } catch (error) {
    const { photoToResend } = getState().messageThread;

    if (error?.response === undefined) {
      const errorDescription: string = isDocument
        ? 'Dokument nije poslat'
        : 'Slika nije poslata';
      return rejectWithValue({
        file: {
          ...file,
          status: 'notSent',
          resendingTried: !isEmpty(photoToResend),
        },
        errors: [{ error_code: 'file', error_description: errorDescription }],
      });
    }
    return rejectWithValue({
      file,
      errors: error.response.data.errors,
      percent: 100,
    });
  }
});

export const reportSpam = createAppAsyncThunk(
  'message/reportSpam',
  async (payload: SpamPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await MessageAPI.reportSpam(thunkAPI)(payload);

    if (data.success) {
      dispatch(toggleModal(null));
      await delay(300);
      dispatch(showToast({ content: 'Uspešno ste prijavili SPAM' }));
    }

    return data.results;
  }
);

export const removeSpam = createAppAsyncThunk(
  'message/removeSpam',
  async (payload: SpamPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await MessageAPI.removeSpam(thunkAPI)(payload);

    if (data.success) {
      dispatch(toggleModal(null));
      await delay(300);
      dispatch(showToast({ content: 'Uspešno ste poništili SPAM' }));
    }

    return data.results;
  }
);

export const userBlock = createAppAsyncThunk(
  'message/userBlock',
  async (payload: BlockUserPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await MessageAPI.userBlock(thunkAPI)(payload);

    if (data.success) {
      dispatch(toggleModal(null));

      Router.push(MyKPMessagesRoute.generateUrl());
      await delay(300);
      dispatch(showToast({ content: 'Uspešno ste blokirali korisnika' }));
    }

    return data.results;
  }
);

export const userUnblock = createAppAsyncThunk(
  'message/userUnblock',
  async (payload: UnblockUserPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { userId } = payload;
    const { data } = await MessageAPI.userUnblock(thunkAPI)({ userId });

    if (data.success) {
      dispatch(toggleModal(null));
      await delay(300);
      dispatch(showToast({ content: 'Uspešno ste odblokirali korisnika' }));
    }

    return data.results;
  }
);

export const saveAddressMessage = createAppAsyncThunk(
  'message/saveAddressMessage',
  async (payload: SaveAddressMessagePayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await MessageAPI.saveAddressMessage(thunkAPI)(payload);

      if (data.success) {
        dispatch(toggleModal(null));
      }

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const saveTrackingCodeMessage = createAppAsyncThunk(
  'message/saveTrackingCodeMessage',
  async (payload: SaveTrackingCodeMessagePayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { data } = await MessageAPI.saveTrackingCodeMessage(thunkAPI)(
        payload
      );

      if (data.success) {
        CookiesAPI.set('trackingCodeLabelNew', '1');

        dispatch(
          logTrackingCode({
            eventAction: TrackingCodeEventAction.sent,
            messageShipmentTrackCourierId: Number(
              payload?.messageShipmentTrackCourierId
            ),
            source: TrackingCodeSource.message,
            receiverId: Number(payload?.receiverId),
            adId: payload?.adId,
            messageId: data?.results?.message?.messageId,
            isScanned: payload?.isScanned,
          })
        );
      }

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);
