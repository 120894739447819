import { createAppAsyncThunk } from '@store/global/utils';
import { RapidMessageAPI } from '@store/rapidMessage/api';
import { normalize } from 'src/lib/normalize';
import { CloseRapidMessagePayload, RapidMessagesPayload } from './type';

export const fetchRapidMessages = createAppAsyncThunk(
  'rapidMessage/fetchRapidMessages',
  async (payload: RapidMessagesPayload, thunkAPI) => {
    const { data } = await RapidMessageAPI.fetchRapidMessages(thunkAPI)(
      payload
    );

    const rapidMessages = normalize(
      data.results.rapidNotifications,
      'rapidMessageId'
    );

    return {
      rapidMessages,
      lastRequestTime: data.results.lastRequestTime,
      lastNotificationUpdateTime: data.results.lastNotificationUpdateTime,
    };
  }
);

export const closeRapidMessage = createAppAsyncThunk(
  'rapidMessage/closeRapidMessage',
  async (payload: CloseRapidMessagePayload, thunkAPI) => {
    const { data } = await RapidMessageAPI.closeRapidMessage(thunkAPI)(payload);

    return data.results;
  }
);
