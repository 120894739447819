import type { ThunkAPI } from '@store/configureStore';

import { apiRequest } from '../api/request/makeRequest';

import type { AntiBotFeedbackResponse, SaveAreYouHumanPayload } from './type';

const ANTI_BOT_URL = 'anti-bot';

const fetchAntiBotHumanCheck = (thunkAPI: ThunkAPI) => () =>
  apiRequest<AntiBotFeedbackResponse>(`${ANTI_BOT_URL}/are-you-human`, {
    method: 'GET',
    thunkAPI,
  });

const saveAreYouHuman =
  (thunkAPI: ThunkAPI) => (data: SaveAreYouHumanPayload) =>
    apiRequest(`${ANTI_BOT_URL}/are-you-human/check-answer`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

export const AntiBotAPI = {
  fetchAntiBotHumanCheck,
  saveAreYouHuman,
} as const;
