import React from 'react';
import NextLink from 'next/link';
import classNames from 'classnames/bind';

import styles from './Link.module.scss';

const setClass = classNames.bind(styles);

interface LinkProps {
  href: string;
  children?: React.ReactNode;
  className?: string;
  primary?: boolean;
  inherit?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
  id?: string | number;
  rel?: string;
  isDisabled?: boolean;
  doLog?: () => void;
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
  style?: React.CSSProperties;
  download?: any;
  testId?: string;
  ariaLabel?: string;
}

const Link = ({
  href,
  children,
  className,
  primary = false,
  inherit = false,
  target,
  id = '',
  rel,
  isDisabled = false,
  doLog,
  leading,
  trailing,
  style,
  download,
  testId,
  ariaLabel = 'link',
}: LinkProps) => {
  const linkClassName = setClass(
    {
      link: true,
      primary,
      inherit,
      isDisabled,
    },
    className
  );

  return (
    <NextLink href={href} prefetch={false}>
      <a
        aria-label={typeof children === 'string' ? children : ariaLabel}
        className={linkClassName}
        target={target}
        id={String(id)}
        rel={rel}
        onClick={doLog}
        onKeyDown={doLog}
        role="button"
        tabIndex={-1}
        download={download}
        style={style}
        data-test={testId}
      >
        {leading && <span className={styles.leading}>{leading}</span>}
        {children}
        {trailing && <span className={styles.trailing}>{trailing}</span>}
      </a>
    </NextLink>
  );
};

Link.displayName = 'Link';

export default Link;
