import isServer from './ssrSetup/isServer';

const setWithExpiry = (key: string, value: unknown, ttl: number) => {
  if (isServer) return null;

  try {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
    return undefined;
  } catch (error) {
    return undefined;
  }
};

const getWithExpiry = (key: string) => {
  if (isServer) return null;

  try {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  } catch (error) {
    return null;
  }
};

const removeItem = (key: string) => {
  if (isServer) return null;

  try {
    localStorage.removeItem(key);
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const LocalStorageAPI = {
  setWithExpiry,
  getWithExpiry,
  removeItem,
};
