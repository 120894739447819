import { LOGIN_REASON_INFO_LIST } from '@lib/constants/loginReason.constants';
import { addNewEd, followAd } from '@store/ad/thunk';
import {
  addToAddressBook,
  deleteFromAddressBook,
} from '@store/addressBook/thunk';
import { setLoginReasonInfo } from '@store/auth/slice';
import { openJobApplicationModal } from '@store/job/thunk';
import { ignoreUserAds, unIgnoreUserAds } from '@store/meAds/thunk';
import { showPollResult } from '@store/poll/thunk';
import { saveSearch } from '@store/savedSearch/thunk';
import { openCourierForm } from '@store/shippingScheduleCourier/thunk';
import { clickOnPhoneButton, openMessageThread } from '@store/user/thunk';

const authAsyncThunk = {
  'ad/followAd/pending': followAd,
  'ad/addNewEd/pending': addNewEd,
  'user/openMessageThread/pending': openMessageThread,
  'meAds/ignoreUserAds/pending': ignoreUserAds,
  'meAds/unIgnoreUserAds/pending': unIgnoreUserAds,
  'addressBook/addToAddressBook/pending': addToAddressBook,
  'addressBook/deleteFromAddressBook/pending': deleteFromAddressBook,
  'search/saveSearch/pending': saveSearch,
  'job/openJobApplicationModal/pending': openJobApplicationModal,
  'poll/showPollResult/pending': showPollResult,
  'user/clickOnPhoneButton/pending': clickOnPhoneButton,
  'shipping/openCourierForm/pending': openCourierForm,
};

const loginReasonList = {
  'ad/followAd/pending': LOGIN_REASON_INFO_LIST.followAd,
  'user/openMessageThread/pending': LOGIN_REASON_INFO_LIST.openMessageThread,
  'user/clickOnPhoneButton/pending': LOGIN_REASON_INFO_LIST.clickOnPhoneButton,
  'search/saveSearch/pending': LOGIN_REASON_INFO_LIST.saveSearch,
  'job/openJobApplicationModal/pending': LOGIN_REASON_INFO_LIST.applyJob,
};

export const userAuthMiddleware =
  ({ dispatch, getState }) =>
  (next) => {
    const queuedActions = [];

    const afterLogin = () => {
      if (queuedActions.length && getState().auth.token) {
        const action = queuedActions.shift();
        dispatch(authAsyncThunk[action.type](action.meta.arg));
      }
    };

    return (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      try {
        if (!getState().auth.token && action.type in authAsyncThunk) {
          const loginReason = loginReasonList?.[action?.type];
          if (loginReason) {
            dispatch(setLoginReasonInfo(loginReason));
          }

          const newAction = {
            type: 'modal/toggleModal',
            payload: '[LOGIN]',
          };

          queuedActions[0] = action;
          next(newAction);
        } else {
          next(action);
        }
      } catch (error) {
        next(action);
      }

      return afterLogin();
    };
  };
