import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import { FetchMessageShipmentTrackCourierResponse } from './type';

const MESSAGE_URL = 'message-shipment-track-courier';

const fetchMessageShipmentTrackCourier = (thunkAPI: ThunkAPI) => () =>
  apiRequest<FetchMessageShipmentTrackCourierResponse>(`${MESSAGE_URL}`, {
    method: 'GET',
    thunkAPI,
  });

export const ShipmentTrackCourierAPI = {
  fetchMessageShipmentTrackCourier,
} as const;
