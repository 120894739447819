import { createAppAsyncThunk } from '@store/global/utils';
import { CategoryHeadlineAPI } from './api';

export const fetchHeadlineCategories = createAppAsyncThunk(
  'categoryHeadline/fetchHeadlineCategories',
  async (_, thunkAPI) => {
    const response = await CategoryHeadlineAPI.fetchHeadlineCategories(
      thunkAPI
    )();

    return response.data.results;
  }
);
