import { apiRequest } from '../api/request/makeRequest';
import type { CategoryHeadlineListResponse } from './type';
import type { ThunkAPI } from '@store/configureStore';

const CATEGORY_HEADLINE_URL = 'category-headline';

const fetchHeadlineCategories = (thunkAPI: ThunkAPI) => () =>
  apiRequest<CategoryHeadlineListResponse>(`${CATEGORY_HEADLINE_URL}/list`, {
    method: 'GET',
    thunkAPI,
  });

export const CategoryHeadlineAPI = {
  fetchHeadlineCategories,
} as const;
