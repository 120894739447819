/* eslint-disable no-restricted-syntax */
import isEmpty from 'lodash/isEmpty';
import { NextPageContext } from 'kp';

import { REDIRECT_ROUTES } from '@lib/constants/routes.constants';
import { ssrRedirect } from '@lib/ssrRedirect';
import isServer from '@lib/ssrSetup/isServer';

const redirectRoutes = async (
  ctx: NextPageContext,
  next: () => Promise<void>
) => {
  const { req, res, asPath } = ctx;

  if (!req || !isServer) return next();

  const splitResult = asPath?.split('?');
  const baseUrl = splitResult[0];
  const queryParams = new URLSearchParams(splitResult[1]);

  for (const { pattern, handler } of REDIRECT_ROUTES) {
    const match = baseUrl.match(pattern);
    if (match) {
      const matchedParams = match.groups || {};
      const newUrl = handler({
        url: baseUrl,
        matchedParams,
        query: queryParams,
      });

      if (!isEmpty(newUrl)) {
        ssrRedirect(res, newUrl);
        return true;
      }
    }
  }

  return next();
};

export default redirectRoutes;
