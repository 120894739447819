import { formatSearchParams } from '@lib/formatSearchParams';

import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import {
  AddContactPayload,
  AddNotePayload,
  AddressBookListPayload,
  AddressBookListResponse,
  DeleteContactPayload,
} from './type';

const ADDRESS_BOOK_URL = 'addressbook';

const fetchAddressBookList =
  (thunkAPI: ThunkAPI) => (payload: AddressBookListPayload) => {
    const searchParamsObj = formatSearchParams(payload.query);
    const searchParams = new URLSearchParams(searchParamsObj).toString();

    return apiRequest<AddressBookListResponse>(
      `${ADDRESS_BOOK_URL}/list?${searchParams}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );
  };

const addToAddressBook = (thunkAPI: ThunkAPI) => (data: AddContactPayload) =>
  apiRequest(`${ADDRESS_BOOK_URL}/add-contact`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const deleteFromAddressBook =
  (thunkAPI: ThunkAPI) => (data: DeleteContactPayload) =>
    apiRequest(`${ADDRESS_BOOK_URL}/delete-contact`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

const addNote = (thunkAPI: ThunkAPI) => (data: AddNotePayload) =>
  apiRequest(`${ADDRESS_BOOK_URL}/add-note`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

export const AddressBookAPI = {
  fetchAddressBookList,
  addToAddressBook,
  deleteFromAddressBook,
  addNote,
} as const;
