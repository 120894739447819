import { FormikValues } from 'formik';

import { getAdSaveCheckFields } from '@store/adSave/model';
import { Any, EmptyObject } from '@store/global/type';

type GetFilteredDataAndCheckFieldsProps = {
  values: FormikValues['values'];
  adClass: string;
  isEditAd: boolean;
  activeStep: number;
  isDeliveryMethodAllowed: boolean;
  isImmediateAvailableAllowed: boolean;
};

export const getFilteredDataAndCheckFields = async ({
  values,
  adClass,
  isEditAd,
  activeStep,
  isDeliveryMethodAllowed,
  isImmediateAvailableAllowed,
}: GetFilteredDataAndCheckFieldsProps) => {
  const checkFields = getAdSaveCheckFields({
    adClass,
    isEditAd,
    activeStep,
    isDeliveryMethodAllowed,
    isImmediateAvailableAllowed,
  });

  const filteredData = Object.entries(values).reduce(
    (acc: EmptyObject, [key, val]) => {
      let value: Any = val;
      if (checkFields[key]) {
        if (
          Array.isArray(value) &&
          !['photos', 'agreement', 'suggestedGroups'].includes(key)
        ) {
          value = value.join(',');
        }

        if (key === 'agreement') {
          acc.agreements = value;
        }

        if (key.toLowerCase().includes('phone')) {
          value = value.replace(/\s+/g, '');
        }

        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  return { filteredData, checkFields };
};
