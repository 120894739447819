import { createAppAsyncThunk } from '@store/global/utils';

import { ShipmentTrackCourierAPI } from './api';

export const fetchMessageShipmentTrackCourier = createAppAsyncThunk(
  'fetchMessageShipmentTrackCourier',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } =
        await ShipmentTrackCourierAPI.fetchMessageShipmentTrackCourier(
          thunkAPI
        )();

      return data.results;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors);
    }
  }
);
