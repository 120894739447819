import { createAppAsyncThunk } from '@store/global/utils';
import { StaticPagesAPI } from '@store/staticPages/api';

export const fetchStaticPages = createAppAsyncThunk(
  'staticPages/fetchStaticPages',
  async (payload: string, thunkAPI) => {
    const { data } = await StaticPagesAPI.fetchStaticPages(thunkAPI)(payload);
    return data.results;
  }
);
