import React, { ReactNode, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import isEmpty from 'lodash/isEmpty';
import AuthSyncContextProvider from 'src/main/context/AuthSyncContext';
import PrefetchContextProvider from 'src/main/context/PrefetchContext';

import { isDevelopment, isTestEnvironment } from '@lib/isProduction';

import useTheme from '@hooks/meta/useTheme';
import { useAppSelector } from '@hooks/redux/useAppSelector';
import useOpenModalWithUrl from '@hooks/useOpenModalWithUrl';
import useRemoveScroll from '@hooks/useRemoveScroll';

import {
  selectIsBlacklist,
  selectIsIframe,
  selectIsMaintenance,
  selectIsMobile,
} from '@store/meta/selector';
import { selectModal, selectToast } from '@store/modal/selector';

import ErrorUnderConstruction from '@pages/responsive/ErrorUnderConstruction/ErrorUnderConstruction';

import DesktopCoreLayoutComponents from '@components/common/desktop/DesktopCoreLayoutComponents/DesktopCoreLayoutComponents';
import MobileCoreLayoutComponents from '@components/common/mobile/MobileCoreLayoutComponents/MobileCoreLayoutComponents';
import ThemeToggle from '@components/common/responsive/ThemeToggle/ThemeToggle';

const Toast = dynamic(
  () => import('@components/common/responsive/Toast/Toast')
);

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const isMaintenanceMode = useAppSelector(selectIsMaintenance);
  const isBlacklistMode = useAppSelector(selectIsBlacklist);
  const isIframe = useAppSelector(selectIsIframe);
  const isMobile = useAppSelector(selectIsMobile);
  const activeModal = useAppSelector(selectModal);
  const { isActive } = useAppSelector(selectToast);
  const [showChild, setShowChild] = useState(false);
  const isDevelopmentOrTesting = isDevelopment || isTestEnvironment;

  useEffect(() => {
    const timeout = setTimeout(() => setShowChild(true), 1);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const hideOnMobile = isMobile && !isEmpty(activeModal);
  const hideOnDesktop = ['[LOGIN]', '[PREPAID]'].includes(activeModal);
  useTheme({ isEnabled: showChild && !isIframe });
  useRemoveScroll(hideOnMobile || hideOnDesktop);
  useOpenModalWithUrl();

  const shouldShowThemeToggle =
    showChild && !isIframe && !isMobile && isDevelopmentOrTesting;

  return isMaintenanceMode || isBlacklistMode ? (
    <ErrorUnderConstruction isBlacklistMode={isBlacklistMode} />
  ) : (
    <AuthSyncContextProvider>
      <PrefetchContextProvider>
        {shouldShowThemeToggle && <ThemeToggle />}
        {children}
        {isActive && <Toast />}
        {isMobile ? (
          <MobileCoreLayoutComponents />
        ) : (
          <DesktopCoreLayoutComponents />
        )}
      </PrefetchContextProvider>
    </AuthSyncContextProvider>
  );
};

export default Layout;

export const emptyLayout = (page: ReactNode) => <Layout>{page}</Layout>;
