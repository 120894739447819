import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';
import type {
  PromotionActivatePayload,
  PromotionPricePayload,
  PromotionPriceResponse,
  PromotionUpdateUrlPayload,
} from '@store/promotion/type';

const PROMOTION_URL = 'promotions';

const fetchPrices =
  (thunkAPI: ThunkAPI) =>
  ({ groupId, name = '', adId = '' }: PromotionPricePayload) => {
    const params = new URLSearchParams({
      groupId,
      name,
      ...(adId && { adId }),
    }).toString();

    return apiRequest<PromotionPriceResponse>(
      `${PROMOTION_URL}/list?${params}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );
  };

const activatePromo =
  (thunkAPI: ThunkAPI) => (data: PromotionActivatePayload) => {
    return apiRequest(`${PROMOTION_URL}/activate`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });
  };

const updatePromoUrl =
  (thunkAPI: ThunkAPI) => (data: PromotionUpdateUrlPayload) => {
    return apiRequest(`${PROMOTION_URL}/edit`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });
  };

export const PromotionAPI = {
  fetchPrices,
  activatePromo,
  updatePromoUrl,
} as const;
