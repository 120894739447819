const checkIsFieldMultiSelect = (item) => {
  return item && Array.isArray(item);
};
export const formatSearchParams = (params) => {
  const mapParamsValues = Object.entries(params).map((entry) => {
    const isMultiSelect = checkIsFieldMultiSelect(entry[1]);
    if (isMultiSelect) {
      entry[1] = [entry[1]].join();
    }
    return entry;
  });
  const paramsValues = Object.fromEntries(mapParamsValues);

  return paramsValues as { [key: string]: string };
};
