export function formatCurrency(amount: string | number, currency = '') {
  const amountNumber = Number(amount);
  let decimalCount = 0;
  const decimal = ',';
  const thousands = '.';

  if (currency.toUpperCase() === 'EUR' || currency === '€') {
    decimalCount = 2;
  }

  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amountNumber < 0 ? '-' : '';

    const i = parseInt(
      // eslint-disable-next-line no-param-reassign
      (amount = Math.abs(amountNumber || 0).toFixed(decimalCount)),
      10
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amountNumber - Number(i))
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return amount;
}
