import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { uploadJobFiles } from '@store/file/thunk';
import { saveJobApplication, unsubscribeJobReport } from '@store/job/thunk';
import { JobInitialState } from './type';

const initialState: JobInitialState = {
  errors: [],
  emailFormActiveStep: 0,
  emailFormDisableSubmit: false,
  isLoading: false,
  isUnsubscribed: false,
  pageTitle:
    'Da li želite da se odjavite od pregleda svih oglasa navedenih u e-mailu?',
  unsubscribeErrors: [],
};

const jobSlice = createSlice({
  name: 'job',
  initialState,

  reducers: {
    setEmailFormActiveStep: (state, action) => {
      state.emailFormActiveStep = action.payload;
    },
    updateUnsubscribePageTitle(state, action) {
      state.pageTitle = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[jobSlice.name])) {
        Object.assign(state, action.payload.jobSlice);
      }
    });

    builder.addCase(saveJobApplication.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(saveJobApplication.fulfilled, (state) => {
      state.isLoading = false;
      state.emailFormActiveStep = 1;
    });

    builder.addCase(saveJobApplication.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(uploadJobFiles.pending, (state) => {
      state.emailFormDisableSubmit = true;
    });

    builder.addCase(uploadJobFiles.fulfilled, (state) => {
      state.emailFormDisableSubmit = false;
    });

    builder.addCase(unsubscribeJobReport.fulfilled, (state) => {
      state.isLoading = false;
      state.isUnsubscribed = true;
      state.pageTitle =
        'Uspešno ste se odjavili od pregleda svih oglasa navedenih u e-mailu!';
    });

    builder.addCase(unsubscribeJobReport.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(unsubscribeJobReport.rejected, (state, action) => {
      state.isLoading = false;
      state.unsubscribeErrors = action.payload;
      state.pageTitle = `Došlo je do greške. ${state.unsubscribeErrors[0].error_description}.`;
    });
  },
});

export const { setEmailFormActiveStep, updateUnsubscribePageTitle } =
  jobSlice.actions;

export default jobSlice;
