import { createAppAsyncThunk } from '@store/global/utils';
import { GdprAPI } from './api';

export const addGdpr = createAppAsyncThunk(
  'gdpr/addGdpr',

  async (payload: string, thunkAPI) => {
    const { data } = await GdprAPI.addGdpr(thunkAPI)(payload);

    return data.results;
  }
);

export const requestGdpr = createAppAsyncThunk(
  'gdpr/requestGdpr',

  async (_, thunkAPI) => {
    const { data } = await GdprAPI.requestGdpr(thunkAPI)();

    return data.results;
  }
);
