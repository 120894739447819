/* eslint-disable import/no-cycle */
import { getShippingEducation } from '@lib/shipping/getShippingEducation';

import { fetchAddress } from '@store/address/thunk';
import { AddressSource } from '@store/address/type';
import { Any, RequestStatus } from '@store/global/type';
import { createAppAsyncThunk } from '@store/global/utils';
import { logOrderCourier } from '@store/logging/thunk';
import { ScheduleCourierEventAction } from '@store/logging/type';
import { toggleModal } from '@store/modal/slice';

import { ShippingAPI } from './api';
import { setReceiverAddress } from './slice';
import {
  CalculateShippingPricePayload,
  CheckCourierFieldsPayload,
  FetchCourierSettingsPayload,
  OpenCourierModalProps,
  ScheduleCourierPayload,
} from './type';

export const scheduleCourier = createAppAsyncThunk(
  'shipping/scheduleCourier',
  async (payload: ScheduleCourierPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ShippingAPI.scheduleCourier(thunkAPI)(payload);
      return data.results;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors);
    }
  }
);

export const fetchCourierSettings = createAppAsyncThunk(
  'shipping/fetchCourierSettings',
  async (payload: FetchCourierSettingsPayload, thunkAPI) => {
    const { data } = await ShippingAPI.fetchCourierSettings(thunkAPI)(payload);

    return data.results;
  }
);

export const calculateShippingPrice = createAppAsyncThunk(
  'shipping/calculateShippingPrice',
  async (payload: CalculateShippingPricePayload, thunkAPI) => {
    const { data } = await ShippingAPI.calculateShippingPrice(thunkAPI)(
      payload
    );

    return data.results;
  }
);

export const checkCourierFields = createAppAsyncThunk(
  'shipping/checkCourierFields',
  async (payload: CheckCourierFieldsPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await ShippingAPI.checkCourierFields(thunkAPI)(payload);
      return data.results;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors);
    }
  }
);

export const openCourierForm = createAppAsyncThunk(
  'shipping/openCourierForm',
  async (payload: OpenCourierModalProps, { dispatch }) => {
    const {
      courierId,
      adId,
      source,
      messageId,
      address,
      safeOrderEducationalModalInfo,
      isNewSource,
    } = payload;

    dispatch(
      fetchAddress({ skipToggleModal: true, source: AddressSource.courier })
    );

    const action: Any = await dispatch(
      fetchCourierSettings({ courierId, adId, source })
    );

    if (action.meta.requestStatus === RequestStatus.rejected) return;

    const shouldDisplayCourierEducation = getShippingEducation({
      safeOrderEducationalModalInfo,
    });

    dispatch(
      setReceiverAddress({ ...address, ...(messageId && { messageId }) })
    );

    if (isNewSource) {
      dispatch(
        logOrderCourier({
          eventAction: ScheduleCourierEventAction.formOpen,
          courierId,
          source,
        })
      );
    }

    if (shouldDisplayCourierEducation) {
      dispatch(toggleModal('[SCHEDULE_COURIER_EDUCATION]'));
      dispatch(
        logOrderCourier({
          eventAction: ScheduleCourierEventAction.eduModalOpened,
          courierId,
          source,
        })
      );
      return;
    }

    dispatch(toggleModal('[SCHEDULE_COURIER_MODAL]'));
  }
);
