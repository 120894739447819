/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { routeChangeStart } from '@hooks/useRouteChangeStart';

import {
  fetchBlockedInfo,
  forgotPassword,
  resendActivationEmail,
  resendActivationEmailChange,
} from '@store/meAccount/thunk';

import { MeAccountInitialState } from './type';

const initialState: MeAccountInitialState = {
  isLoading: false,
  forgotPassword: {
    errors: [],
    activeStep: 0,
    isLoading: false,
  },
  blockedInfo: {},
  activationEmail: {
    sent: false,
    email: '',
    errors: [],
  },
};

const meAccountSlice = createSlice({
  name: 'meAccount',
  initialState,

  reducers: {
    setActivationEmail(state, action) {
      state.activationEmail.email = action.payload;
    },
    resetActivationEmailStats(state) {
      state.activationEmail.sent = false;
      state.activationEmail.errors = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[meAccountSlice.name])) {
        Object.assign(state, action.payload.meAccount);
      }
    });

    builder.addCase(routeChangeStart, (state) => {
      state.forgotPassword = initialState.forgotPassword;
    });

    builder.addCase(forgotPassword.pending, (state) => {
      state.forgotPassword.isLoading = true;
    });

    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.forgotPassword.activeStep = 1;
      state.forgotPassword.isLoading = false;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.forgotPassword.errors = action.payload;
      state.forgotPassword.isLoading = false;
    });

    builder.addCase(fetchBlockedInfo.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchBlockedInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.blockedInfo = action.payload;
    });

    builder.addCase(resendActivationEmail.fulfilled, (state) => {
      state.activationEmail.sent = true;
    });

    builder.addCase(resendActivationEmail.rejected, (state, action) => {
      state.activationEmail.errors = action.payload;
    });

    builder.addCase(resendActivationEmailChange.fulfilled, (state) => {
      state.activationEmail.sent = true;
    });

    builder.addCase(resendActivationEmailChange.rejected, (state, action) => {
      state.activationEmail.errors = action.payload;
    });
  },
});

export const { setActivationEmail, resetActivationEmailStats } =
  meAccountSlice.actions;

export default meAccountSlice;
