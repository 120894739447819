import { formatSearchParams } from '@lib/formatSearchParams';

import type { ThunkAPI } from '@store/configureStore';

import { apiRequest } from '../api/request/makeRequest';

import type {
  FeedbackInterceptorResponse,
  FeedbackSettingsResponse,
  FeedbackThreadPayload,
  FeedbackThreadResponse,
  SaveFeedbackPayload,
} from './type';

const FEEDBACK_URL = 'feedback';

const saveFeedback = (thunkAPI: ThunkAPI) => (data: SaveFeedbackPayload) =>
  apiRequest(`${FEEDBACK_URL}/save`, { method: 'POST', body: data, thunkAPI });

const fetchFeedbackInterceptor = (thunkAPI: ThunkAPI) => () =>
  apiRequest<FeedbackInterceptorResponse>(`${FEEDBACK_URL}/interceptor`, {
    method: 'GET',
    thunkAPI,
  });

const fetchFeedbackThread =
  (thunkAPI: ThunkAPI) => (payload: FeedbackThreadPayload) => {
    const searchParamsObj = formatSearchParams(payload.query);
    const searchParams = new URLSearchParams(searchParamsObj).toString();

    return apiRequest<FeedbackThreadResponse>(
      `${FEEDBACK_URL}/view/${payload.query.feedbackId}?${searchParams}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );
  };

const fetchFeedbackSettings = (thunkAPI: ThunkAPI) => () =>
  apiRequest<FeedbackSettingsResponse>(`${FEEDBACK_URL}/settings`, {
    method: 'GET',
    thunkAPI,
  });

export const FeedbackAPI = {
  saveFeedback,
  fetchFeedbackThread,
  fetchFeedbackInterceptor,
  fetchFeedbackSettings,
} as const;
