import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import type {
  RenewerActivationPayload,
  RenewerInfoResponse,
  RenewerPricesResponse,
} from './type';

const RENEWER_URL = 'renewer';

const fetchRenewerInfo = (thunkAPI: ThunkAPI) => () => {
  return apiRequest<RenewerInfoResponse>(`${RENEWER_URL}/info`, {
    method: 'GET',
    thunkAPI,
  });
};

const fetchRenewerPrices = (thunkAPI: ThunkAPI) => () => {
  return apiRequest<RenewerPricesResponse>(`${RENEWER_URL}/prices`, {
    method: 'GET',
    thunkAPI,
  });
};

const activateRenewer =
  (thunkAPI: ThunkAPI) => (data: RenewerActivationPayload) => {
    return apiRequest(`${RENEWER_URL}/activate`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });
  };

export const RenewerAPI = {
  fetchRenewerInfo,
  fetchRenewerPrices,
  activateRenewer,
} as const;
