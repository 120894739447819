import type { ThunkAPI } from '@store/configureStore';

import { apiRequest } from '../api/request/makeRequest';

import type {
  FileRotateResponse,
  FileUploadResponse,
  RotatePayload,
} from './type';

const FILE_URL = 'file';

const upload =
  (thunkAPI: ThunkAPI) =>
  (data: FormData, onUploadProgress?: (progressEvent: any) => void) =>
    apiRequest<FileUploadResponse>(FILE_URL, {
      method: 'POST',
      body: data,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
      thunkAPI,
    });

const rotate = (thunkAPI: ThunkAPI) => (data: RotatePayload) =>
  apiRequest<FileRotateResponse>(`${FILE_URL}/rotate`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

export const FileAPI = {
  upload,
  rotate,
} as const;
