import isEmpty from 'lodash/isEmpty';

import { BannerAPI } from '@store/banner/api';
import { createAppAsyncThunk } from '@store/global/utils';

import { BannerShowTrackPayload, BannersPayload } from './types';

export const fetchBanners = createAppAsyncThunk(
  'banner/fetchBanners',
  async (payload: BannersPayload, thunkAPI) => {
    const { excludeBannerTypes } = thunkAPI.getState().banner;

    const { data } = await BannerAPI.fetchBanners(thunkAPI)({
      excludeBannerTypes,
      ...payload,
      bannerPositionId: [1, ...payload.bannerPositionId],
    });

    return data.results;
  }
);

export const postBannerShowTrack = createAppAsyncThunk(
  'banner/postBannerShowTrack',
  async (payload: BannerShowTrackPayload, thunkAPI) => {
    if (!isEmpty(payload)) {
      await BannerAPI.postBannerShowTrack(thunkAPI)(payload);
    }
    return payload;
  }
);
