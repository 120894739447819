import values from 'lodash/values';
import { createSelector } from 'reselect';

import { rapidMessageSelector } from '../rootSelector';

export const selectRapidMessages = createSelector(
  rapidMessageSelector,
  (slicedState) => values(slicedState.rapidMessages)
);

export const selectRapidLastRequestTime = createSelector(
  rapidMessageSelector,
  (slicedState) => slicedState.lastRequestTime
);

export const selectLastNotificationUpdateTime = createSelector(
  rapidMessageSelector,
  (slicedState) => slicedState.lastNotificationUpdateTime
);

export const selectRapidMessageHeight = createSelector(
  rapidMessageSelector,
  (slicedState) => slicedState.height
);
