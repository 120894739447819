import { impression, sendToGTM } from '@lib/gtag';

export const signUpEvents = {
  impression: () => impression('sign_up'),

  clickLogin: () =>
    sendToGTM({
      event: 'sign_up',
      eventAction: 'click',
      eventLabel: 'login',
      type: 'click',
      action: 'login',
    }),

  clickRegister: (socialNetwork?: string) =>
    sendToGTM({
      event: 'sign_up',
      eventAction: 'click',
      eventLabel: socialNetwork,
      type: 'click',
      action: 'sign_up',
      method: socialNetwork,
    }),

  successRegister: (socialNetwork: string) =>
    sendToGTM({
      event: 'sign_up',
      eventCategory: 'sign_up',
      eventAction: 'success',
      eventLabel: socialNetwork,
      type: 'sign_up',
      action: 'success',
      method: socialNetwork,
    }),

  successLogin: (socialNetwork: string) =>
    sendToGTM({
      event: 'sign_up',
      eventCategory: 'sign_up',
      eventAction: 'login',
      eventLabel: socialNetwork,
      type: 'sign_up',
      action: 'login',
      method: socialNetwork,
    }),

  successRegisterActivation: (socialNetwork: string) =>
    sendToGTM({
      event: 'sign_up',
      eventCategory: 'sign_up',
      eventAction: 'activation',
      eventLabel: socialNetwork,
      type: 'sign_up',
      action: 'activation',
      method: socialNetwork,
    }),

  activationImpression: (status: string) =>
    sendToGTM({
      event: 'sign_up',
      eventCategory: status,
      eventAction: 'impression',
      type: 'impression',
    }),

  failFbNoEmail: () =>
    sendToGTM({
      event: 'sign_up',
      eventAction: 'fail',
      eventLabel: 'fb_no_email',
      type: 'sign_up',
      action: 'fail',
      method: 'fb_no_email',
    }),

  errorDecline: () =>
    sendToGTM({
      event: 'sign_up',
      eventAction: 'error',
      eventLabel: 'declined',
      type: 'sign_up',
      action: 'error',
      method: 'declined',
    }),

  signUpSuggestImpression: () =>
    sendToGTM({
      event: 'sign_up',
      eventCategory: 'sign_up_suggest',
      eventAction: 'impression',
      type: 'sign_up_suggest',
      method: 'impression',
    }),

  signUpSuggestSuccess: (socialNetwork?: string) =>
    sendToGTM({
      event: 'sign_up',
      eventCategory: 'sign_up_suggest',
      eventAction: 'success',
      eventLabel: socialNetwork,
      type: 'sign_up_suggest',
      action: 'success',
      method: socialNetwork,
    }),
};
