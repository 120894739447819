import { normalize } from '@lib/normalize';

import { AntiBotAPI } from '@store/antiBot/api';
import { createAppAsyncThunk } from '@store/global/utils';
import { toggleModal } from '@store/modal/slice';

import {
  AntiBotInitialState,
  RejectedAntiBotError,
  SaveAreYouHumanPayload,
} from './type';

export const fetchAntiBotHumanCheck = createAppAsyncThunk(
  'antiBot/fetchAntiBotHumanCheck',
  async (_, thunkAPI) => {
    const response = await AntiBotAPI.fetchAntiBotHumanCheck(thunkAPI)();
    return response.data.results;
  }
);

export const saveAreYouHuman = createAppAsyncThunk<
  AntiBotInitialState,
  SaveAreYouHumanPayload,
  { rejectValue: RejectedAntiBotError }
>('antiBot/saveAreYouHuman', async (payload, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  const { captcha, source } = payload;

  try {
    const { data } = await AntiBotAPI.saveAreYouHuman(thunkAPI)({ captcha });

    if (data.success) {
      if (source === 'page') {
        window.history.back();
      } else {
        dispatch(toggleModal(null));
      }
    }

    return data.results;
  } catch (error) {
    dispatch(fetchAntiBotHumanCheck());
    return rejectWithValue(normalize(error.response.data.errors, 'error_code'));
  }
});
