export const getYearsOption = () => {
  const now = new Date().getUTCFullYear();
  const years = Array(now - (now - 55))
    .fill('')
    .map((v, idx) => {
      const year = now - idx;
      return { name: `${year}.`, id: `${year}.` };
    });

  return years;
};
