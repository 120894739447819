import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import type {
  BannerResponse,
  BannerShowTrackPayload,
  BannersPayload,
} from './types';

const BANNER_URL = 'banners';

const fetchBanners = (thunkAPI: ThunkAPI) => (data: BannersPayload) =>
  apiRequest<BannerResponse>(BANNER_URL, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const postBannerShowTrack =
  (thunkAPI: ThunkAPI) => (data: BannerShowTrackPayload) =>
    apiRequest(`${BANNER_URL}/show-track`, {
      method: 'POST',
      body: { bShowTrack: data },
      thunkAPI,
    });

export const BannerAPI = {
  fetchBanners,
  postBannerShowTrack,
} as const;
