import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@store/global/type';

import { fetchAddress, fetchStreets, fetchTowns, saveAddress } from './thunk';
import { Address, AddressInfoInitialState } from './type';

const initialState: AddressInfoInitialState = {
  list: [],
  userInfo: {},
  countries: [],
  isLimitReached: false,
  maxAddressNumPerUser: 0,
  notificationTitle: '',
  notificationDescription: '',
  matchedAddress: {} as Address,
  selectedAddress: {} as Address,
  loading: RequestStatus.idle,
  streets: [],
  towns: [],
  isLoadingStreets: false,
  isLoadingTowns: false,
  lastStreetsPayload: null,
  lastTownsPayload: null,
};

const addressSlice = createSlice({
  name: 'address',
  initialState,

  reducers: {
    setMatchedAddress(state, action) {
      state.matchedAddress = action.payload;
    },
    setSelectedAddress(state, action) {
      state.selectedAddress = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAddress.fulfilled, (state, action) => {
      const {
        list,
        userInfo,
        countries,
        isLimitReached,
        notificationTitle,
        notificationDescription,
      } = action.payload;
      state.list = list;
      state.userInfo = userInfo;
      state.countries = countries;
      state.isLimitReached = isLimitReached;
      state.notificationTitle = notificationTitle;
      state.notificationDescription = notificationDescription;
      state.loading = RequestStatus.fulfilled;
    });

    builder.addCase(fetchAddress.rejected, (state) => {
      state.loading = RequestStatus.rejected;
    });

    builder.addCase(saveAddress.rejected, (state) => {
      state.loading = RequestStatus.rejected;
    });

    builder.addCase(saveAddress.pending, (state) => {
      state.loading = RequestStatus.pending;
    });

    builder.addCase(saveAddress.fulfilled, (state, action) => {
      const { addressId = '', isPrimary = false } = action.meta.arg;
      if (addressId) {
        state.list = state.list.map((address) => {
          if (address.addressId === addressId && isPrimary) {
            return { ...address, isPrimary: true };
          }
          return { ...address, isPrimary: false };
        });
      }
      state.loading = RequestStatus.fulfilled;
    });

    builder.addCase(fetchStreets.fulfilled, (state, action) => {
      state.streets = action.payload;
      state.lastStreetsPayload = action?.meta?.arg;
      state.isLoadingStreets = false;
    });

    builder.addCase(fetchStreets.pending, (state) => {
      state.isLoadingStreets = true;
    });

    builder.addCase(fetchStreets.rejected, (state) => {
      state.isLoadingStreets = false;
    });

    builder.addCase(fetchTowns.fulfilled, (state, action) => {
      state.towns = action.payload;
      state.lastTownsPayload = action?.meta?.arg;
      state.isLoadingTowns = false;
    });

    builder.addCase(fetchTowns.pending, (state) => {
      state.isLoadingTowns = true;
    });

    builder.addCase(fetchTowns.rejected, (state) => {
      state.isLoadingTowns = false;
    });
  },
});

export const { setMatchedAddress, setSelectedAddress } = addressSlice.actions;

export default addressSlice;
