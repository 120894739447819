import { useEffect } from 'react';

const useRemoveScroll = (isEnabledScroll: boolean) => {
  useEffect(() => {
    if (isEnabledScroll) {
      document.body.classList.add('kp-overflow-hidden');
    } else {
      document.body.classList.remove('kp-overflow-hidden');
    }
    return () => {
      document.body.classList.remove('kp-overflow-hidden');
    };
  }, [isEnabledScroll]);
};

export default useRemoveScroll;
