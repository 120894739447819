import { apiRequest } from '@store/api/request/makeRequest';
import type { PollPayload, PollResponse, PollVotePayload } from './type';
import type { ThunkAPI } from '@store/configureStore';

const POLL_URL = 'poll';

const fetchPollData =
  (thunkAPI: ThunkAPI) =>
  ({ pageType }: PollPayload) =>
    apiRequest<PollResponse>(`${POLL_URL}/${pageType}`, {
      method: 'GET',
      thunkAPI,
    });

const vote =
  (thunkAPI: ThunkAPI) =>
  ({ pollId, optionId }: PollVotePayload) =>
    apiRequest(`${POLL_URL}/${pollId}/${optionId}`, {
      method: 'GET',
      thunkAPI,
    });

export const PollAPI = {
  fetchPollData,
  vote,
} as const;
