import { setToken } from '@store/auth/thunk';
import isArray from 'lodash/isArray';

export const userAuthRedirectMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    try {
      if (
        action?.meta?.rejectedWithValue &&
        isArray(action?.payload) &&
        action?.payload?.find((e) => e.error_code === 'not_logged_in')
      ) {
        dispatch(setToken(''));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    return next(action);
  };
