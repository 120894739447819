import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { createCategoryBox } from '@store/categoryBox/model';

import { fetchCategoryBox, fetchCategoryBoxById } from './thunk';
import { CategoryBoxInitialState } from './type';

const initialState: CategoryBoxInitialState = {
  byId: [],
  isLoading: true,
};

const categoryBoxSlice = createSlice({
  name: 'categoryBox',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[categoryBoxSlice.name])) {
        Object.assign(state, action.payload.categoryBox);
      }
    });

    builder.addCase(fetchCategoryBox.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCategoryBox.fulfilled, (state, action) => {
      const { data } = action.payload;
      if (data?.categories) {
        state.byId = data.categories.map((categoryBox: any) =>
          createCategoryBox(categoryBox)
        );
      }
      state.isLoading = false;
    });

    builder.addCase(fetchCategoryBoxById.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCategoryBoxById.fulfilled, (state, action) => {
      const { data } = action.payload;
      if (data?.categories) {
        state.byId = data.categories.map((categoryBox: any) =>
          createCategoryBox(categoryBox)
        );
      }
      state.isLoading = false;
    });
  },
});

export default categoryBoxSlice;
