/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */
import Router from 'next/router';

import { MyKPAdsRoute } from '@server/routes';

import { Error } from '@store/global/type';
import { createAppAsyncThunk } from '@store/global/utils';
import { PromotionAPI } from '@store/promotion/api';
import { redirectToMyAds } from '@store/promotion/utils';

import { showToast, toggleModal } from '../modal/slice';

import { resetUpdateUrlSuccessMessage } from './slice';
import {
  PromotionActivatePayload,
  PromotionPricePayload,
  PromotionUpdateUrlPayload,
} from './type';

export const fetchPrices = createAppAsyncThunk(
  'promotion/fetchPrices',
  async (payload: PromotionPricePayload, thunkAPI) => {
    const { data } = await PromotionAPI.fetchPrices(thunkAPI)(payload);

    return data.results;
  }
);

export const activatePromo = createAppAsyncThunk(
  'promotion/activatePromo',
  async (payload: PromotionActivatePayload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    const isPromoRenew = payload.promoRenew;
    try {
      const { data } = await PromotionAPI.activatePromo(thunkAPI)(payload);
      if (data.success) {
        redirectToMyAds({ isPromoRenew });
        if (isPromoRenew) {
          dispatch(showToast({ content: 'Uspešno ste obnovili oglas' }));
        } else {
          dispatch(
            showToast({
              content: 'Promocija će biti aktivirana u narednih 10 minuta.',
              duration: '3000',
            })
          );
        }
      }

      return data.results;
    } catch (error) {
      if (isPromoRenew) {
        Router.push(
          MyKPAdsRoute.generateUrl({}, { infoBox: 'activeRenewError' })
        );
      }

      const { errors = '' } = error.response.data;
      errors.map((e: Error) => {
        if (e.error_code === 'service_top_full') {
          dispatch(toggleModal('[PROMO_NO_SLOTS]'));
        }
      });

      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const updatePromoUrl = createAppAsyncThunk(
  'promotion/updatePromoUrl',
  async (payload: PromotionUpdateUrlPayload, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const { deviceType } = getState().meta;
    const isMobile = deviceType === 'mobile';
    try {
      const { data } = await PromotionAPI.updatePromoUrl(thunkAPI)(payload);
      if (data.success) {
        if (isMobile) {
          dispatch(showToast({ content: 'Izmene sačuvane' }));
        } else {
          setTimeout(() => {
            dispatch(toggleModal(null));
            dispatch(resetUpdateUrlSuccessMessage());
          }, 1000);
        }
      }

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);
