import omit from 'lodash/omit';

import { sendToGTM4 } from '@lib/gtag';

interface SearchParams {
  filters: { [key: string]: unknown };
}

export const searchEvents = {
  search: ({ filters }: SearchParams) => {
    sendToGTM4({
      event: 'filter',
      category: filters.categoryId ?? null,
      group: filters.groupId ?? null,
      filter_type: omit(filters, ['firstParam', 'group', 'category']),
    });
  },

  impression: () =>
    sendToGTM4({
      event: 'search',
      eventCategory: 'search',
      eventAction: 'impression',
      type: 'impression',
      eventLabel: 'noAdsSearch',
    }),

  clickNoAdsSearch: () =>
    sendToGTM4({
      event: 'search',
      eventCategory: 'search',
      eventAction: 'click',
      type: 'click',
      eventLabel: 'noAdsSearch',
    }),
};
