import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { toggleModal, toggleScreen } from '@store/modal/slice';

const useOpenModalWithUrl = () => {
  const { query } = useRouter();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (query.modal) {
      dispatch(toggleModal(query.modal));
    }
  }, [query, dispatch]);

  useEffect(() => {
    if (query.screen) {
      dispatch(toggleScreen(query.screen));
    }
  }, [query, dispatch]);
};

export default useOpenModalWithUrl;
