import { useEffect } from 'react';

const useGoogleAdManagerScript = () => {
  const gptSrc = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

  useEffect(() => {
    const gptScript = document.createElement('script');
    gptScript.id = 'GptScript';
    gptScript.src = gptSrc;
    gptScript.async = true;

    document.head.appendChild(gptScript);

    return () => {
      document.head.removeChild(gptScript);
    };
  }, []);
};

export default useGoogleAdManagerScript;
