import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { postContactForm } from '@store/smallBusiness/thunk';
import { SmallBusinessInitialState } from '@store/smallBusiness/type';

const initialState: SmallBusinessInitialState = {
  isLoading: false,
  errors: [],
};

const smallBusinessSlice = createSlice({
  name: 'smallBusiness',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[smallBusinessSlice.name])) {
        Object.assign(state, action.payload.smallBusiness);
      }
    });

    builder.addCase(postContactForm.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(postContactForm.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(postContactForm.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    });
  },
});

export default smallBusinessSlice;
