import { createAppAsyncThunk } from '@store/global/utils';
import { LocationAPI } from './api';

export const fetchLocationsUserFavorites = createAppAsyncThunk(
  'location/fetchLocationsUserFavorites',
  async (_, thunkAPI) => {
    const response = await LocationAPI.fetchLocationsUserFavorites(thunkAPI)();

    return response.data.results;
  }
);
