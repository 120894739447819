import Router from 'next/router';

import { UPDATE_PROFILE_NOTIFICATION_CONTENT } from '@lib/constants/profile.constants';

import { MyKPAccountRoute } from '@server/routes';

import { Any } from '@store/global/type';
import { fetchUserProfile } from '@store/meProfile/thunk';
import { showToast } from '@store/modal/slice';

type HandleParkAllAdsResponseProps = {
  dispatch: Any;
  getState: Any;
  unpark?: boolean;
};

const handleParkAllAdsSuccessResponse = ({
  dispatch,
  getState,
  unpark = false,
}: HandleParkAllAdsResponseProps) => {
  const { deviceType } = getState().meta;
  const isMobile = deviceType === 'mobile';

  const content = unpark
    ? UPDATE_PROFILE_NOTIFICATION_CONTENT.successUnparkAllAds.content
    : UPDATE_PROFILE_NOTIFICATION_CONTENT.successParkAllAds.content;
  const description = unpark
    ? UPDATE_PROFILE_NOTIFICATION_CONTENT.successUnparkAllAds.description
    : UPDATE_PROFILE_NOTIFICATION_CONTENT.successParkAllAds.description;
  const infoBox = unpark ? 'successUnparkAllAds' : 'successParkAllAds';

  if (isMobile) {
    dispatch(
      showToast({
        content,
        description,
        isToast: true,
        duration: '5000',
      })
    );

    Router.push(MyKPAccountRoute.generateUrl());
  }

  if (!isMobile) {
    Router.push(MyKPAccountRoute.generateUrl({}, { infoBox }));
  }

  dispatch(fetchUserProfile());
};

export default handleParkAllAdsSuccessResponse;
