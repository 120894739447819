import {
  AdCheckResponse,
  AdSavePayload,
  AdSaveResponse,
  FetchUserOwnedAdResponse,
} from '@store/adSave/type';
import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

const AD_URL = 'eds';

const fetchUserOwnedAd = (thunkAPI: ThunkAPI) => (id: string) =>
  apiRequest<FetchUserOwnedAdResponse>(`${AD_URL}/user-owned-ed/get?id=${id}`, {
    method: 'GET',
    thunkAPI,
  });

const checkAd = (thunkAPI: ThunkAPI) => (data: AdSavePayload) =>
  apiRequest<AdCheckResponse>(`${AD_URL}/check`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const saveAd = (thunkAPI: ThunkAPI) => (data: AdSavePayload) =>
  apiRequest<AdSaveResponse>(`${AD_URL}/save`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const saveAdSpecificFields = (thunkAPI: ThunkAPI) => (data: AdSavePayload) =>
  apiRequest<AdSaveResponse>(`${AD_URL}/save-specific-fields`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

export const AdAPI = {
  checkAd,
  saveAd,
  fetchUserOwnedAd,
  saveAdSpecificFields,
} as const;
