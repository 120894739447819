import { createAppAsyncThunk } from '@store/global/utils';
import { CategoryAPI } from './api';

import { normalize } from 'src/lib/normalize';

export const fetchCategories = createAppAsyncThunk(
  'category/fetchCategories',
  async (_, thunkAPI) => {
    const { data } = await CategoryAPI.fetchCategories(thunkAPI)();

    return normalize(data.categories, 'id');
  }
);

export const fetchUserFavoriteCategories = createAppAsyncThunk(
  'category/fetchUserFavoriteCategories',
  async (_, thunkAPI) => {
    const { data } = await CategoryAPI.fetchUserFavoriteCategories(thunkAPI)();

    return data.results;
  }
);
