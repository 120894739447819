import Router from 'next/router';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { AddressAPI } from '@store/address/api';
import { createAppAsyncThunk } from '@store/global/utils';
import { logAddress } from '@store/logging/thunk';
import { AddressEventAction } from '@store/logging/type';
import { toggleModal } from '@store/modal/slice';
import { SearchPageQuery } from '@store/search/type';

import {
  AddressSource,
  DeleteAddressPayload,
  FetchAddressPayload,
  FetchStreetsPayload,
  FetchTownsPayload,
  SaveAddressPayload,
} from './type';

export const saveAddress = createAppAsyncThunk(
  'address/saveAddress',
  async (payload: SaveAddressPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await AddressAPI.saveAddress(thunkAPI)(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const fetchAddress = createAppAsyncThunk(
  'address/fetchAddress',
  async (payload: FetchAddressPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await AddressAPI.fetchAddress(thunkAPI)(payload);
    const { source = AddressSource.folder } = payload;
    const { query } = Router.router;
    const { adId = '', user2Id = '' } = query as SearchPageQuery;

    if (data.success && !payload.skipToggleModal) {
      if (isEmpty(data?.results?.list)) {
        dispatch(toggleModal(`SAVE_ADDRESS_MODAL_${source}`));
        dispatch(
          logAddress({
            eventAction: AddressEventAction.formOpen,
            source: AddressSource[source],
            receiverId: user2Id,
            adId,
            addressCount: data?.results?.list?.length ?? 0,
          })
        );
      } else {
        dispatch(toggleModal('SAVED_ADDRESS_MODAL'));
        dispatch(
          logAddress({
            eventAction: AddressEventAction.seen,
            source: AddressSource[source],
            receiverId: user2Id,
            adId,
            addressCount: data?.results?.list?.length ?? 0,
          })
        );
      }
    }

    return data.results;
  }
);

export const deleteAddress = createAppAsyncThunk(
  'address/deleteAddress',
  async (payload: DeleteAddressPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await AddressAPI.deleteAddress(thunkAPI)(payload);
    if (data.success) {
      dispatch(toggleModal(null));
      dispatch(fetchAddress({ adId: payload.adId, skipToggleModal: true }));
    }

    return data.results;
  }
);

export const fetchStreets = createAppAsyncThunk(
  'address/fetchStreets',
  async (payload: FetchStreetsPayload, thunkAPI) => {
    const { getState } = thunkAPI;

    const lastStreetsPayload = getState()?.address?.lastStreetsPayload;

    const isLastStreetsPayloadEqual = isEqual(lastStreetsPayload, payload);

    if (isLastStreetsPayloadEqual) {
      return getState()?.address?.streets;
    }

    const { data } = await AddressAPI.fetchStreets(thunkAPI)(payload);

    return data.results;
  }
);

export const fetchTowns = createAppAsyncThunk(
  'address/fetchTowns',
  async (payload: FetchTownsPayload, thunkAPI) => {
    const { getState } = thunkAPI;

    const lastTownsPayload = getState()?.address?.lastTownsPayload;

    const isLastTownsPayloadEqual = isEqual(lastTownsPayload, payload);

    if (isLastTownsPayloadEqual) {
      return getState()?.address?.towns;
    }

    const { data } = await AddressAPI.fetchTowns(thunkAPI)(payload);

    return data.results;
  }
);
