import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';
import { fetchRapidMessages } from '@store/rapidMessage/thunk';
import { RapidMessageInitialState } from './type';

const initialState: RapidMessageInitialState = {
  rapidMessages: [],
  lastNotificationUpdateTime: '',
  lastRequestTime: '',
  isLoading: false,
  height: 0,
};

const rapidMessageSlice = createSlice({
  name: 'rapidMessage',
  initialState,

  reducers: {
    setRapidMessage: (state, action) => {
      const { rapidMessageId } = action.payload;
      state.rapidMessages[rapidMessageId] = {
        ...action.payload,
        isMatched: true,
      };
    },
    setRapidMessageInactiveStatus: (state, action) => {
      const { rapidMessageId } = action.payload;
      state.rapidMessages[rapidMessageId] = {
        ...action.payload,
        status: 'inactive',
      };
    },
    setRapidMessageHeight: (state, action) => {
      state.height = action.payload / 10;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[rapidMessageSlice.name])) {
        Object.assign(state, action.payload.rapidMessage);
      }
    });

    builder.addCase(fetchRapidMessages.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchRapidMessages.fulfilled, (state, action) => {
      const { lastNotificationUpdateTime, lastRequestTime, rapidMessages } =
        action.payload;

      if (lastNotificationUpdateTime) {
        state.lastNotificationUpdateTime = lastNotificationUpdateTime;
      }
      if (lastRequestTime) {
        state.lastRequestTime = lastRequestTime;
      }

      const newRapidMessages = action.meta.arg?.updatedSinceTime
        ? {
            ...state.rapidMessages,
            ...action.payload.rapidMessages,
          }
        : rapidMessages;

      state.rapidMessages = newRapidMessages;
      state.isLoading = false;
    });
  },
});

export const {
  setRapidMessage,
  setRapidMessageInactiveStatus,
  setRapidMessageHeight,
} = rapidMessageSlice.actions;

export default rapidMessageSlice;
