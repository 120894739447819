import Router from 'next/router';
import size from 'lodash/size';

import { normalize } from 'src/lib/normalize';

import { MyKpSavedSearchesRoute } from '@server/routes';

import { createAppAsyncThunk } from '@store/global/utils';
import { toggleModal } from '@store/modal/slice';

import { SaveSearchAPI } from './api';
import {
  DeleteSavedSearchPayload,
  FetchSavedSearchesPayload,
  RegisterNotificationClickAndSearchFiltersByHashPayload,
  SaveSearchPayload,
  SearchFilterByHashPayload,
  UpdateSaveSearchesPayload,
} from './type';

export const fetchSavedSearches = createAppAsyncThunk(
  'savedSearch/fetchSavedSearches',
  async (payload: FetchSavedSearchesPayload, thunkAPI) => {
    const { data } = await SaveSearchAPI.fetchSavedSearches(thunkAPI)(payload);

    return data;
  }
);

export const updateSavedSearches = createAppAsyncThunk(
  'savedSearch/updateSavedSearches',
  async (payload: UpdateSaveSearchesPayload, thunkAPI) => {
    const { data } = await SaveSearchAPI.updateSavedSearches(thunkAPI)(payload);

    return data.results;
  }
);

export const deleteSavedSearches = createAppAsyncThunk(
  'savedSearch/deleteSavedSearches',
  async (payload: DeleteSavedSearchPayload, thunkAPI) => {
    const { getState } = thunkAPI;
    const { data } = await SaveSearchAPI.deleteSavedSearches(thunkAPI)(payload);

    const {
      query: { page },
    } = Router.router;

    const { list, pages } = getState().savedSearch;

    if (page !== '1' && size(list) === 1) {
      Router.push(
        MyKpSavedSearchesRoute.generateUrl(
          {},
          {
            page: pages - 1,
          }
        )
      );
    }

    return data.results;
  }
);

export const saveSearch = createAppAsyncThunk(
  'search/saveSearch',

  async (payload: SaveSearchPayload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const { data } = await SaveSearchAPI.saveSearch(thunkAPI)(payload);
      if (data.success) {
        dispatch(toggleModal('[successfulSavedSearch]'));
      }
      return data.results;
    } catch (error) {
      return rejectWithValue(
        normalize(error.response.data.errors, 'error_code')
      );
    }
  }
);

export const getSearchFiltersByHash = createAppAsyncThunk(
  'search/getSearchFiltersByHash',
  async (payload: SearchFilterByHashPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await SaveSearchAPI.getSearchFiltersByHash(thunkAPI)(payload);
      return res.data.results;
    } catch (error) {
      return rejectWithValue(
        normalize(error.response.data.errors, 'error_code')
      );
    }
  }
);

export const registerNotificationClickAndGetSearchFiltersByHash =
  createAppAsyncThunk(
    'search/registerNotificationClickAndGetSearchFiltersByHash',
    async (
      payload: RegisterNotificationClickAndSearchFiltersByHashPayload,
      thunkAPI
    ) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const res =
          await SaveSearchAPI.registerNotificationClickAndGetSearchFiltersByHash(
            thunkAPI
          )(payload);
        return res.data.results;
      } catch (error) {
        return rejectWithValue(
          normalize(error.response.data.errors, 'error_code')
        );
      }
    }
  );
