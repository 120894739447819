import Router from 'next/router';
import { createAppAsyncThunk } from '@store/global/utils';
import { MessageAPI } from './api';
import { MyKPMessagesRoute } from '@server/routes';
import { delay } from '@lib/delay';
import { includeOldQueryParams } from './utils';

import { showToast, toggleModal } from '@store/modal/slice';
import { DeleteMessagePayload, MessageInboxPayload } from './type';

export const fetchInbox = createAppAsyncThunk(
  'message/fetchInbox',
  async (payload: MessageInboxPayload, thunkAPI) => {
    const response = await MessageAPI.fetchInbox(thunkAPI)(payload);

    return response.data.results;
  }
);

export const fetchMessageUsers = createAppAsyncThunk(
  'message/fetchMessageUsers',
  async (payload: MessageInboxPayload, thunkAPI) => {
    const { data } = await MessageAPI.fetchMessageUsers(thunkAPI)(
      includeOldQueryParams(payload)
    );

    return data.results;
  }
);

export const fetchMessageAds = createAppAsyncThunk(
  'message/fetchMessageAds',
  async (payload: MessageInboxPayload, thunkAPI) => {
    const { data } = await MessageAPI.fetchMessageAds(thunkAPI)(
      includeOldQueryParams(payload)
    );

    return data.results;
  }
);

export const deleteMessage = createAppAsyncThunk(
  'message/deleteMessage',
  async (payload: DeleteMessagePayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await MessageAPI.deleteMessage(thunkAPI)(payload);

    const { threads = [] } = payload;

    const conversation = threads.length > 1 ? 'konverzacije' : 'konverzaciju';

    if (data.success) {
      dispatch(toggleModal(null));

      if (threads.length) {
        const { query } = Router.router;
        Router.push(MyKPMessagesRoute.generateUrl({}, { ...query }));
      } else {
        Router.push(MyKPMessagesRoute.generateUrl());
      }
      await delay(300);
      dispatch(showToast({ content: `Uspešno ste obrisali ${conversation}` }));
    }

    return data.results;
  }
);
