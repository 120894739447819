import Router from 'next/router';

import { UPDATE_PROFILE_NOTIFICATION_CONTENT } from '@lib/constants/profile.constants';

import { MyKPAccountRoute } from '@server/routes';

import { createAppAsyncThunk } from '@store/global/utils';
import { showToast } from '@store/modal/slice';

import { MeProfileAPI } from './api';
import {
  EditUserNotificationAlertsPayload,
  EditUserProfilePayload,
} from './type';

export const fetchUserProfile = createAppAsyncThunk(
  'meProfile/fetchUserProfile',
  async (_, thunkAPI) => {
    const { data } = await MeProfileAPI.fetchUserProfile(thunkAPI)();

    return data.results;
  }
);

export const editUserProfile = createAppAsyncThunk(
  'meProfile/editUserProfile',
  async (payload: EditUserProfilePayload, thunkAPI) => {
    const { rejectWithValue, dispatch, getState } = thunkAPI;
    try {
      const { data } = await MeProfileAPI.editUserProfile(thunkAPI)(payload);
      const { deviceType } = getState().meta;
      const isMobile = deviceType === 'mobile';

      const infoType =
        payload?.updateAds === 'yes' ? 'successUpdateAds' : 'success';

      if (data.success && isMobile) {
        dispatch(
          showToast({
            content: UPDATE_PROFILE_NOTIFICATION_CONTENT[infoType].content,
            description:
              UPDATE_PROFILE_NOTIFICATION_CONTENT[infoType].description,
            isToast: true,
            duration: '5000',
          })
        );

        Router.push(MyKPAccountRoute.generateUrl());
      }

      if (data.success && !isMobile) {
        Router.push(MyKPAccountRoute.generateUrl({}, { infoBox: infoType }));
      }

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const editUserNotificationAlerts = createAppAsyncThunk(
  'meProfile/editUserNotificationAlerts',
  async (payload: EditUserNotificationAlertsPayload, thunkAPI) => {
    const { rejectWithValue, dispatch, getState } = thunkAPI;
    try {
      const { data } = await MeProfileAPI.editUserNotificationAlerts(thunkAPI)(
        payload
      );
      const { deviceType } = getState().meta;
      const isMobile = deviceType === 'mobile';

      if (data.success && isMobile) {
        dispatch(
          showToast({
            content: UPDATE_PROFILE_NOTIFICATION_CONTENT.success.content,
            description:
              UPDATE_PROFILE_NOTIFICATION_CONTENT.success.description,
            isToast: true,
            duration: '5000',
          })
        );

        Router.push(MyKPAccountRoute.generateUrl());
      }

      if (data.success && !isMobile) {
        Router.push(MyKPAccountRoute.generateUrl({}, { infoBox: 'success' }));
      }

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);
