import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';

import Link from '@components/controls/Link/Link';

import styles from './Button.module.scss';

const setClass = classNames.bind(styles);

export interface ButtonProps {
  autoFocus?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  isLoading?: boolean;
  isDisabled?: boolean;
  loader?: React.ReactNode;
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
  doLog?: () => void;
  type?: 'submit' | 'reset' | 'button';
  href?: string;
  download?: any;
  target?: React.HTMLAttributeAnchorTarget;
  rel?: string;
  inherit?: boolean;
  big?: boolean;
  medium?: boolean;
  small?: boolean;
  noPadding?: boolean;
  testId?: string;
  ariaLabel?: string;
  tabIndex?: number;
}

const Button = ({
  autoFocus = false,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onKeyDown,
  className,
  style,
  isLoading,
  isDisabled,
  loader,
  leading,
  trailing,
  doLog,
  type = 'button',
  href,
  download,
  target,
  rel,
  inherit,
  big,
  medium,
  small,
  noPadding,
  testId,
  ariaLabel = type,
  tabIndex = 0,
}: ButtonProps) => {
  const ref = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current.focus();
    }
  }, [autoFocus]);

  const handleClick = (event) => {
    if (doLog) doLog();
    if (onClick) {
      event.stopPropagation();
      onClick(event);
    }
  };

  const content = (
    <>
      {leading && <span className={styles.leading}>{leading}</span>}
      {isLoading && <span className={styles.loader}>{loader}</span>}
      {children && <span className={styles.children}>{children}</span>}
      {trailing && <span className={styles.trailing}>{trailing}</span>}
    </>
  );

  if (href) {
    return (
      <Link
        ariaLabel={typeof children === 'string' ? children : ariaLabel}
        href={href}
        rel={rel}
        className={setClass(
          {
            base: true,
            isLoading,
            inherit: true,
            isDisabled: isDisabled || isLoading,
            big: !medium && !small && !noPadding,
            medium,
            small,
            noPadding,
          },
          className
        )}
        style={style}
        download={download}
        target={target}
        testId={testId}
        doLog={doLog}
      >
        {content}
      </Link>
    );
  }

  return (
    <button
      aria-label={typeof children === 'string' ? children : ariaLabel}
      type={type}
      ref={ref}
      className={setClass(
        {
          base: true,
          isLoading,
          big: !medium && !small && !noPadding,
          medium,
          small,
          noPadding,
        },
        className
      )}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onKeyDown={onKeyDown}
      disabled={isDisabled}
      style={style}
      data-test={testId}
      tabIndex={tabIndex}
    >
      {content}
    </button>
  );
};

export default Button;
