import { apiRequest } from '../api/request/makeRequest';
import type {
  KpIzlogCreatePayload,
  KpIzlogExtendPayload,
  KpIzlogIdentPayload,
  KpIzlogResponse,
  KpIzlogStatResponse,
} from './type';
import type { ThunkAPI } from '@store/configureStore';

const KP_IZLOG_URL = 'kp-izlog';

const fetchKpIzlog =
  (thunkAPI: ThunkAPI) =>
  (categoryId = 0) =>
    apiRequest<KpIzlogResponse>(`${KP_IZLOG_URL}/${categoryId}`, {
      method: 'GET',
      thunkAPI,
    });

const fetchKpIzlogStat = (thunkAPI: ThunkAPI) => () =>
  apiRequest<KpIzlogStatResponse>(`${KP_IZLOG_URL}/stat`, {
    method: 'GET',
    thunkAPI,
  });

const checkKpIzlogIdent = (thunkAPI: ThunkAPI) => (body: KpIzlogIdentPayload) =>
  apiRequest(`${KP_IZLOG_URL}/check-ident`, { method: 'POST', body, thunkAPI });

const createKpIzlog = (thunkAPI: ThunkAPI) => (body: KpIzlogCreatePayload) =>
  apiRequest(`${KP_IZLOG_URL}/create`, { method: 'POST', body, thunkAPI });

const extendKpIzlog = (thunkAPI: ThunkAPI) => (body: KpIzlogExtendPayload) =>
  apiRequest(`${KP_IZLOG_URL}/extend`, { method: 'POST', body, thunkAPI });

export const KpIzlogAPI = {
  fetchKpIzlog,
  fetchKpIzlogStat,
  checkKpIzlogIdent,
  createKpIzlog,
  extendKpIzlog,
} as const;
