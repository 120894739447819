import { AddressSource } from '@store/address/type';
import { AllSuggestionType } from '@store/classifier/type';
import { Response } from '@store/global/type';
import {
  TrackingCodeEventAction,
  TrackingCodeSource,
} from '@store/shipmentTrackCourier/type';
import { ScheduleCourierSourceProps } from '@store/shippingScheduleCourier/type';

export type LogWelcomeScreenResponse = Response<string>;

export enum AddressEventAction {
  seen = 'seen',
  formOpen = 'formOpen',
  formClosed = 'formClosed',
  sentOld = 'sentOld',
  sentNew = 'sentNew',
  removed = 'removed',
  created = 'created',
  updated = 'updated',
}

export enum ScheduleCourierEventAction {
  formOpen = 'formOpen',
  formClosed = 'formClosed',
  cancel = 'cancel',
  primaryAddressUsed = 'primaryAddressUsed',
  savedAddressUsed = 'savedAddressUsed',
  eduModalOpened = 'eduModalOpen',
  shippingHistoryPageOpened = 'shippingHistoryPageOpened',
  conversationOpened = 'conversationOpened',
  checkShippingStatus = 'checkShippingStatus',
}

export type LogNiriGroupSuggestionsPayload = {
  keyword: string;
  suggestions: AllSuggestionType[];
  groupId: number;
};

export type LogFmcgClickPayload = {
  id: string | number;
  userId: string | number;
  channel: string;
};

export type LogFeedbackInterceptor = {
  ident: string;
  eventAction: 'cancel' | 'seen';
};

export type LogAdCreateStepPayload = {
  adId: number;
  categoryId: number;
  formId?: string;
  groupId: number;
  step: number;
  stepName: string;
};

export type AdIdPayload = {
  adId: string | number;
};

export type ClickOnPhoneButtonPayload = {
  onSuccess: (phoneNumber: number) => void;
  adId: string | number;
};

export type LogAddressPayload = {
  eventAction: AddressEventAction;
  source: AddressSource;
  senderId?: string | number;
  receiverId?: string | number;
  adId?: string | number;
  addressCount?: number;
  messageId?: number;
  hasText?: boolean;
  primary?: boolean;
};

export type LogTrackingCodePayload = {
  eventAction: TrackingCodeEventAction;
  source: TrackingCodeSource;
  messageShipmentTrackCourierId?: number;
  adId?: string | number;
  receiverId?: string | number;
  messageId?: number;
  isScanned?: boolean;
};

export type LogChangeThemePayload = {
  theme: 'system' | 'dark' | 'light';
};

export type LogUpsellPromoPayload = {
  eventAction: 'upsellPromoDisplay' | 'adRenewClick' | 'adPromotionClick';
  adId?: string | number;
  position?: number;
};

export type LogDownloadAppPayload = {
  source: 'notifications' | 'messages';
};

export type LogOrderCourierPayload = {
  eventAction: ScheduleCourierEventAction;
  step?: 1 | 2 | 3 | 4;
  courierId?: number | string;
  source?: ScheduleCourierSourceProps;
  id?: number;
};

export type LogIsbnScanPayload = {
  videoInput: MediaDeviceInfo[];
};
