export interface EBankInfo {
  kpAccount: string;
  kpCompanyName: string;
  modelNumber: string;
  controlNumber: string;
  paymentCode: string;
  userType: string;
  userCompanyName: string;
  userName: string;
}

export const createEBankInfo = (eBankInfo: any): EBankInfo => {
  return {
    kpAccount: eBankInfo.kp_account,
    kpCompanyName: eBankInfo.kp_company_name,
    modelNumber: eBankInfo.model_number,
    controlNumber: eBankInfo.control_number,
    paymentCode: eBankInfo.payment_code,
    userType: eBankInfo.user_type,
    userCompanyName: eBankInfo.user_company_name,
    userName: eBankInfo.user_name,
  };
};
