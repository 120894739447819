import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { fetchBookInfo } from './thunk';
import { BookInitialState } from './type';

const initialState: BookInitialState = {
  bookId: '',
  hasResults: false,
  author: '',
  title: '',
  description: '',
  publisher: '',
  publishingYear: '',
  genre: '',
  format: '',
  bookbinding: '',
  script: '',
  pages: null,
  showIsbnInfoWhenNoData: false,
};

const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    resetHasBookResults(state) {
      state.hasResults = false;
    },
    resetAdSaveBookState(state) {
      Object.assign(state, initialState);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[bookSlice.name])) {
        Object.assign(state, action.payload.book);
      }
    });
    builder.addCase(fetchBookInfo.fulfilled, (state, action) => {
      const { ean } = action.meta.arg;
      state.bookId = ean;

      if (action.payload) {
        const {
          author,
          title,
          description,
          publisher,
          publishingYear,
          genre,
          format,
          bookbinding,
          script,
          pages,
        } = action.payload;

        state.hasResults = true;
        state.author = author;
        state.title = title;
        state.description = description;
        state.publisher = publisher;
        state.publishingYear = publishingYear;
        state.format = format;
        state.genre = genre;
        state.bookbinding = bookbinding;
        state.script = script;
        state.pages = pages;
      } else {
        state.showIsbnInfoWhenNoData = true;
      }
    });
  },
});

export const { resetHasBookResults, resetAdSaveBookState } = bookSlice.actions;

export default bookSlice;
