export type LoginReasonInfoProps = {
  text: string;
  type: string;
};

export const LOGIN_REASON_INFO_LIST: Record<string, LoginReasonInfoProps> = {
  followAd: {
    text: 'Ulogujte se da biste pratili oglase. Svi zapraćeni oglasi će Vam se nalaziti u Moj KP meniju, u sekciji Pratim.',
    type: 'info',
  },
  openMessageThread: {
    text: 'Ulogujte se da biste poslali poruku. KP Poruke su najbezbedniji način komunikacije na KupujemProdajem.',
    type: 'info',
  },
  clickOnPhoneButton: {
    text: 'Ulogujte se da biste videli broj telefona, razmenjivali KP Poruke i koristili sve ostale pogodnosti KP naloga.',
    type: 'info',
  },
  saveSearch: {
    text: 'Ulogujte se da biste sačuvali pretragu. Dobijaćete obaveštenja kada se pojave novi oglasi koji Vas zanimaju i lako pokretati pretragu iznova.',
    type: 'info',
  },
  newAd: {
    text: 'Ulogujte se da biste postavljali oglase, razmenjivali KP Poruke i koristili sve ostale pogodnosti KP naloga.',
    type: 'info',
  },
  applyJob: {
    text: 'Ulogujte se da biste konkurisali na oglase za posao i koristili sve ostale pogodnosti KP naloga.',
    type: 'info',
  },
};
