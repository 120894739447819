import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { fetchGeneralGroupedInfo } from '@store/generalStats/thunk';
import { FmcgWidgetItem } from '@store/generalStats/type';

import { RecommendedInitialState } from './type';

const initialState: RecommendedInitialState = {
  stats: {
    fmcgWidget: {} as FmcgWidgetItem,
  },
  isLoading: true,
};

const recommendedSlice = createSlice({
  name: 'recommended',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[recommendedSlice.name])) {
        Object.assign(state, action.payload.recommended);
      }
    });

    builder.addCase(fetchGeneralGroupedInfo.fulfilled, (state, action) => {
      const { featuredLinks } = action.payload;
      state.stats = featuredLinks;
      state.isLoading = false;
    });
  },
});

export default recommendedSlice;
