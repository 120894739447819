const checkWhitelistedIpSourceMaps = (ip: string) => {
  const regex = /^10[.]111/g;
  const mnsIps = ['91.222.7.3', '91.222.5.22'];

  if (mnsIps.includes(ip)) {
    return true;
  }

  return regex.test(ip);
};

export default checkWhitelistedIpSourceMaps;
