import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';
import { fetchEmoji } from '@store/emoji/thunk';
import { EmojiInitialState } from './type';

const initialState: EmojiInitialState = {
  list: [],
};

const emojiSlice = createSlice({
  name: 'emoji',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[emojiSlice.name])) {
        Object.assign(state, action.payload.emoji);
      }
    });

    builder.addCase(fetchEmoji.fulfilled, (state, action) => {
      state.list = action.payload;
    });
  },
});

export default emojiSlice;
