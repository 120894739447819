import { apiRequest } from '@store/api/request/makeRequest';
import type { AddGdprResponse, RequestGdprResponse } from './type';
import type { ThunkAPI } from '@store/configureStore';

const GDPR_URL = 'me/user-gdpr';

const addGdpr = (thunkAPI: ThunkAPI) => (value: string | string[]) =>
  apiRequest<AddGdprResponse>(`${GDPR_URL}-add`, {
    method: 'POST',
    body: value,
    thunkAPI,
  });

const requestGdpr = (thunkAPI: ThunkAPI) => () =>
  apiRequest<RequestGdprResponse>(`${GDPR_URL}-request`, {
    method: 'GET',
    thunkAPI,
  });

export const GdprAPI = {
  addGdpr,
  requestGdpr,
} as const;
