/* eslint-disable import/no-cycle */
import Router from 'next/router';

import { getFilteredDataAndCheckFields } from '@lib/ad/getFilteredDataAndCheckFields';
import { getParamsByCategoryAndGroup } from '@lib/ad/getParamsByCategoryAndGroup';
import { handleAdSaveLockPeriodWarning } from '@lib/ad/handleAdSaveLockPeriodWarning';
import { handleAdSaveMinusWarning } from '@lib/ad/handleAdSaveMinusWarning';
import { delay } from '@lib/delay';
import asyncAdCheckValidate from '@lib/validators/asyncAdCheckValidate';
import { asyncAdSaveAuthValidate } from '@lib/validators/asyncAdSaveAuthValidate';

import { AdRoute, MyKPAdsRoute } from '@server/routes';

import { AdAPI } from '@store/adSave/api';
import { AdSavePayload, FetchUserOwnedAdPayload } from '@store/adSave/type';
import { setCaptchaSiteKey } from '@store/antiBot/slice';
import { setLoginReasonInfo } from '@store/auth/slice';
import { fetchCategories } from '@store/category/thunk';
import { EmptyObject } from '@store/global/type';
import { createAppAsyncThunk } from '@store/global/utils';
import { logAdCreateStep } from '@store/logging/thunk';
import { fetchUserAds } from '@store/meAds/thunk';
import { hideToast, showToast, toggleModal } from '@store/modal/slice';

import {
  clearShowErrorLinkFields,
  resetQuickEditAdId,
  setShowErrorLinkFields,
} from './slice';

export const fetchUserOwnedAd = createAppAsyncThunk(
  'adSave/fetchUserOwnedAd',
  async (payload: FetchUserOwnedAdPayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    const { adId = '', showPrepaidMinusWarning = false } = payload;
    try {
      const { data } = await AdAPI.fetchUserOwnedAd(thunkAPI)(adId);
      dispatch(fetchCategories());
      dispatch(hideToast());

      if (data?.results?.captcha === 'soft') {
        dispatch(setCaptchaSiteKey(data.results.captchaSiteKey));
        dispatch(toggleModal('areYouHuman'));
      }
      const error = data?.errors?.[0];
      const errors = await handleAdSaveMinusWarning({
        dispatch,
        error,
        showPrepaidMinusWarning,
      });

      return { ...data.results, errors };
    } catch (error) {
      const response = error.response.data;
      asyncAdSaveAuthValidate({
        dispatch,
        setLoginReasonInfo,
        errors: response.errors,
        captchaSiteKey: response.captchaSiteKey,
      });

      const { errors } = await handleAdSaveLockPeriodWarning({
        dispatch,
        response,
      });
      return rejectWithValue(errors);
    }
  }
);

export const saveAd = createAppAsyncThunk(
  'adSave/saveAd',
  async (payload: AdSavePayload, thunkAPI) => {
    const { rejectWithValue, dispatch, getState } = thunkAPI;
    try {
      const { data } = await AdAPI.saveAd(thunkAPI)(payload);
      const {
        newAd,
        adId,
        categoryName: category,
        groupName: group,
        adName: ad,
      } = data;
      const { categoryId, groupId } = payload.data;
      const { uniqueStamp } = getState().adSave;
      if (newAd) {
        dispatch(
          logAdCreateStep({
            step: 5,
            stepName: 'final',
            adId,
            categoryId: Number(categoryId),
            groupId: Number(groupId),
            formId: `${uniqueStamp}`,
          })
        );

        await Router.push(
          AdRoute.generateUrl({ adId, firstParam: category, group, ad })
        );
      } else {
        const {
          query,
          query: { returnUrl = '' },
        } = Router.router;

        if (returnUrl) {
          const url = Buffer.from(returnUrl as string, 'base64').toString();
          await Router.push(url);
        } else {
          await Router.push(
            MyKPAdsRoute.generateUrl(
              {},
              { infoBox: 'edited', ...query, adId: '' }
            )
          );
        }
        dispatch(showToast({ content: 'Uspešno ste izmenili oglas' }));
      }
      return data;
    } catch (e) {
      const { errors = '' } = e.response.data;
      errors.forEach((error) => {
        if (error.error_code.includes('newAdsForbidden')) {
          dispatch(toggleModal(error.error_code));
        }
      });
      return rejectWithValue(errors);
    }
  }
);

export const saveAdSpecificFields = createAppAsyncThunk(
  'adSave/saveAdSpecificFields',
  async (payload: AdSavePayload, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const { values } = payload;

      const checkFields = Object.keys(values).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});

      const filteredData = Object.entries(values).reduce(
        (acc: EmptyObject, [key, val]) => {
          let value = val;
          if (checkFields[key]) {
            if (Array.isArray(value) && !['photos'].includes(key)) {
              value = value.join(',');
            }
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      const body = {
        checkFields,
        data: filteredData,
      };

      const { data } = await AdAPI.saveAdSpecificFields(thunkAPI)(body);
      if (data.success) {
        const { query } = Router.router;
        dispatch(fetchUserAds({ query: { ...query, skipLoading: true } }));

        setTimeout(() => {
          dispatch(resetQuickEditAdId());
        }, 1000);
      }
      return data;
    } catch (e) {
      const { errors = '' } = e.response.data;
      return rejectWithValue(errors);
    }
  }
);

export const checkAd = createAppAsyncThunk(
  'adSave/checkAd',
  async (payload: AdSavePayload, thunkAPI) => {
    const { rejectWithValue, dispatch, getState } = thunkAPI;
    const {
      values,
      values: { categoryId, groupId },
    } = payload;
    const { activeStep, isEditAd } = getState().adSave;
    const { isFileLoading } = getState().file;
    const { groups } = getState().group;
    const { isPhoneDisplayControlEnabled = false } =
      getState().stats.featureFlags;

    await dispatch(clearShowErrorLinkFields());

    const { adClass, isDeliveryMethodAllowed, isImmediateAvailableAllowed } =
      await getParamsByCategoryAndGroup({
        values,
        groups,
        categoryId,
        groupId,
      });
    const { filteredData, checkFields } = await getFilteredDataAndCheckFields({
      values,
      adClass,
      isEditAd,
      activeStep,
      isDeliveryMethodAllowed,
      isImmediateAvailableAllowed,
    });

    if (
      isPhoneDisplayControlEnabled &&
      filteredData.phoneNumberSelected !== 'yes'
    ) {
      filteredData.phone = '';
    }

    try {
      if (isEditAd || activeStep >= 3) {
        if (isFileLoading) {
          dispatch(toggleModal('[IMAGES_NOT_LOADED]'));
          return {};
        }
        return await dispatch(
          saveAd({
            data: filteredData,
            checkFields,
          })
        );
      }

      await delay(500);

      const { data } = await AdAPI.checkAd(thunkAPI)({
        data: filteredData,
        checkFields,
      });

      return { ...data, activeStep };
    } catch (e) {
      const { errors = '' } = e.response.data;

      await asyncAdCheckValidate({
        dispatch,
        errors,
        toggleModal,
        setShowErrorLinkFields,
      });

      return rejectWithValue(errors);
    }
  }
);
