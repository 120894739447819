import { pathToRegexp } from 'path-to-regexp';
import { stringify } from 'qs';
import { parse } from 'url';

import { omitFalsy } from '@lib/omitFalsy';

import { formatUrl } from './utils/formatUrl';
import { isFunction } from './utils/isFunction';
import { isNotSaveParam } from './utils/isNotSaveParam';
import { mapValues } from './utils/mapValues';

export class Route {
  public path: string;

  private readonly urlFormatter?: (string: string) => string;

  public query: object;

  constructor(path: string, urlFormatter = null, query = {}) {
    this.path = path;
    this.urlFormatter = urlFormatter;
    this.query = query;
  }

  public generateUrl(params: object = {}, queryStringParams: object = {}) {
    const newParams = this.formatUrl(params);
    const newQueryStringParams = {
      ...this.query,
      ...omitFalsy(queryStringParams),
    };

    const queryString = stringify(newQueryStringParams, {
      indices: false,
      encode: true,
    });

    const pathname = Object.keys(newParams).reduce((acc, key) => {
      const regex = new RegExp(`:${key}`, 'i');
      const safeParam = isNotSaveParam(newParams[key]) ? '_' : newParams[key];

      return acc.replace(regex, safeParam);
    }, this.path);

    return queryString ? `${pathname}?${queryString}` : pathname;
  }

  public isMatch(url: string) {
    const { pathname } = parse(url, true);
    const regex = pathToRegexp(this.path);
    const isMatch = regex.test(pathname);

    return isMatch;
  }

  private formatUrl(params: object) {
    let fn = formatUrl;

    if (isFunction(this.urlFormatter)) {
      fn = (string) => formatUrl(this.urlFormatter(string));
    }

    return mapValues(params, (param: string) =>
      typeof param === 'string' ? fn(param) : param
    );
  }
}
