import { API_URL } from '@lib/constants/env.constants';
import isServer from '@lib/ssrSetup/isServer';

import type { ThunkAPI } from '@store/configureStore';

export const getHeaders = (thunkAPI: ThunkAPI) => {
  if (!thunkAPI) return {};
  const { getState } = thunkAPI;
  const { locals = {}, device = {}, headers = {} } = getState().meta;
  const isMobile = device?.platform?.type === 'mobile';
  const { kpSession, kpMachineId } = headers;

  if (!isServer) {
    return {
      'x-kp-channel': isMobile ? 'mobile_web_react' : 'desktop_react',
      ...(kpSession && { 'x-kp-session': kpSession }),
      ...(kpMachineId && { 'x-kp-machine-id': kpMachineId }),
    };
  }

  return {
    ...(locals.cookies && { Cookie: locals.cookies }),
    Origin: API_URL,
    Referer: API_URL,
    'User-Agent': locals.userAgent,
    'x-kp-channel': isMobile ? 'mobile_web_ssr' : 'ssr_react',
    'X-Forwarded-For': locals.ip,
    'MNS-Client-Source-Port': locals.port,
    'X-NODE-JS-PID': locals.pid,
    'X-NODE-JS-HOSTNAME': locals.hostname,
    'X-NODE-JS-SESSION': locals.session,
    'X-NODE-JS-IP': locals.ip,
    'X-NODE-JS-UUID': locals.nonce,
    'X-NODE-JS-URL': locals.asPath,
    ...(kpSession && { 'x-kp-session': kpSession }),
    ...(kpMachineId && { 'x-kp-machine-id': kpMachineId }),
  };
};
