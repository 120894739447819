import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { fetchAgreements } from '@store/agreement/thunk';
import { AgreementInitialState } from '@store/agreement/type';

const initialState: AgreementInitialState = {
  list: [],
  isLoading: false,
};

const agreementSlice = createSlice({
  name: 'agreement',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[agreementSlice.name])) {
        Object.assign(state, action.payload.agreement);
      }
    });

    builder.addCase(fetchAgreements.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchAgreements.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    });
  },
});

export default agreementSlice;
