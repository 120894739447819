import Router from 'next/router';

import { createAction } from '@reduxjs/toolkit';

import { delay } from '@lib/delay';
import { LocalStorageAPI } from '@lib/localStorage';

import { createAppAsyncThunk } from '@store/global/utils';
import { MeStatsAPI } from '@store/meStats/api';
import { ChangeUserStatsPayload } from '@store/meStats/type';

export const fetchPrepaid = createAppAsyncThunk(
  'meStats/fetchPrepaid',
  async (_, thunkAPI) => {
    const { data } = await MeStatsAPI.fetchPrepaidBalance(thunkAPI)();

    await delay(200);

    return data.results;
  }
);

export const fetchUnread = createAppAsyncThunk(
  'meStats/fetchUnread',
  async (_, thunkAPI) => {
    const { data } = await MeStatsAPI.fetchUnreadNotification(thunkAPI)();

    return data.results;
  }
);

export const fetchUserStats = createAppAsyncThunk(
  'meStats/fetchUserStats',
  async (_, thunkAPI) => {
    const { data } = await MeStatsAPI.fetchUserStats(thunkAPI)();

    return data.results;
  }
);

export const changeUserStats = createAction(
  'USER_STATS_CHANGED',
  (payload: ChangeUserStatsPayload) => ({
    payload,
  })
);

export const fetchUserGroupedInfo = createAppAsyncThunk(
  'meStats/groupedInfo',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { query } = Router.router;
      const value = LocalStorageAPI.getWithExpiry('meStats/groupedInfo');
      const canReturnCachedData =
        !query?.isAdmin && value && value?.stats?.userId;

      if (canReturnCachedData) {
        return value;
      }

      const { data } = await MeStatsAPI.fetchUserGroupedInfo(thunkAPI)();
      if (data.results) {
        LocalStorageAPI.setWithExpiry(
          'meStats/groupedInfo',
          data.results,
          45000
        );
      }

      return data.results ?? {};
    } catch (error) {
      const { errors } = error.response.data;
      return rejectWithValue(errors);
    }
  }
);

export const setSeenWelcomeScreen = createAppAsyncThunk(
  'meStats/setSeenWelcomeScreen',
  async (_, thunkAPI) => {
    const { data } = await MeStatsAPI.setSeenWelcomeScreen(thunkAPI)();
    return data.success;
  }
);
