import Router from 'next/router';

import { delay } from '@lib/delay';
import { LocalStorageAPI } from '@lib/localStorage';

import { setLoginReasonInfo } from '@store/auth/slice';
import { createAppAsyncThunk } from '@store/global/utils';

import { GeneralStatsAPI } from './api';

export const fetchReturnUrl = createAppAsyncThunk(
  'stats/fetchReturnUrl',
  // eslint-disable-next-line consistent-return
  async (payload: string, thunkAPI) => {
    try {
      const { data } = await GeneralStatsAPI.fetchReturnUrl(thunkAPI)(payload);

      Router.push(data.results.url);

      return data;
    } catch (error) {
      /* empty */
    }
  }
);

export const fetchAdStats = createAppAsyncThunk(
  'stats/fetchAdStats',
  async (_, thunkAPI) => {
    const response = await GeneralStatsAPI.fetchGeneralStats(thunkAPI)();
    return response.data.results;
  }
);

export const fetchFeatureFlags = createAppAsyncThunk(
  'stats/fetchFeatureFlags',
  async (_, thunkAPI) => {
    const response = await GeneralStatsAPI.fetchFeatureFlags(thunkAPI)();

    return response.data.results;
  }
);

export const fetchGeneralGroupedInfo = createAppAsyncThunk(
  'stats/groupedInfo',
  async (_, thunkAPI) => {
    const value = LocalStorageAPI.getWithExpiry('stats/groupedInfo');

    if (value) {
      return value;
    }

    const response = await GeneralStatsAPI.fetchGeneralGroupedInfo(thunkAPI)();
    if (response?.data?.results) {
      LocalStorageAPI.setWithExpiry(
        'stats/groupedInfo',
        response.data.results,
        25000
      );
    }

    return response?.data?.results ?? {};
  }
);

export const fetchPasswordRules = createAppAsyncThunk(
  'stats/fetchPasswordRules',
  async (_, thunkAPI) => {
    const response = await GeneralStatsAPI.fetchPasswordRules(thunkAPI)();
    return response.data.results;
  }
);

export const setCustomHeightTrigger = createAppAsyncThunk(
  'stats/setCustomHeightTrigger',
  async (payload: boolean) => {
    await delay(500);
    return payload;
  }
);

export const fetchAccessControlInfo = createAppAsyncThunk(
  'stats/fetchAccessControlInfo',
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const response = await GeneralStatsAPI.fetchAccessControlInfo(thunkAPI)();

    dispatch(
      setLoginReasonInfo({
        text: response.data.results.accessControlErrorDescription,
        type: 'warning',
      })
    );
    return response.data.results;
  }
);
