import { createSelector } from 'reselect';

import { ModalInitialState } from './type';

export const modalStateSelector = (state: { modal: ModalInitialState }) =>
  state.modal;

export const selectModal = createSelector(
  modalStateSelector,
  (slicedState: ModalInitialState) => slicedState.activeModal
);

export const selectScreen = createSelector(
  modalStateSelector,
  (slicedState: ModalInitialState) => slicedState.activeScreen
);

export const selectToast = createSelector(
  modalStateSelector,
  (slicedState: ModalInitialState) => slicedState.toast
);

export const selectIsDescriptionScreenOpen = createSelector(
  modalStateSelector,
  (slicedState: ModalInitialState) => slicedState.isDescriptionScreenOpen
);
