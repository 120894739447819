import Router from 'next/router';
import { IncomingMessage, ServerResponse } from 'http';

import isServer from './ssrSetup/isServer';

export const ssrRedirect = (
  response: ServerResponse<IncomingMessage>,
  url: string
) => {
  const shouldServerRedirect =
    isServer && response.writeHead && !response.headersSent;
  if (shouldServerRedirect) {
    const encodedUrl = encodeURI(url);

    response.writeHead(301, {
      Location: encodedUrl,
    });
    response.end();
  } else {
    Router.push(url);
  }
};
