import isEmpty from 'lodash/isEmpty';
import Bowser from 'bowser';

import { OUTDATED_BROWSER_VERSIONS } from '@lib/constants/browser.constants';

const isSupportedBrowser = (userAgent: string | undefined): boolean => {
  if (isEmpty(userAgent)) {
    return false;
  }

  const browser = Bowser.getParser(userAgent);
  // The full list of aliases for browser: https://github.com/lancedikson/bowser/blob/HEAD/src/constants.js
  const isSupported = browser.satisfies(OUTDATED_BROWSER_VERSIONS);

  return isSupported === undefined ? true : isSupported;
};

export default isSupportedBrowser;
