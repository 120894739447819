import { createAppAsyncThunk } from '@store/global/utils';
import { MediaKitAPI } from '@store/mediakit/api';

export const fetchDownloads = createAppAsyncThunk(
  'mediaKit/fetchDownloads',
  async (_, thunkAPI) => {
    const { data } = await MediaKitAPI.fetchDownloads(thunkAPI)();

    return data.results;
  }
);
