import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import type {
  OAuthRegisterPayload,
  OAuthRegisterResponse,
  RegisterPayload,
  UserSummaryPayload,
  UserSummaryResponse,
} from './type';

const USER_URL = 'users';

const fetchUserSummary =
  (thunkAPI: ThunkAPI) =>
  ({ userId }: UserSummaryPayload) =>
    apiRequest<UserSummaryResponse>(`${USER_URL}/${userId}`, {
      method: 'GET',
      thunkAPI,
    });

const register = (thunkAPI: ThunkAPI) => (data: RegisterPayload) => {
  const { terms, older16, ...rest } = data;

  return apiRequest(`${USER_URL}`, {
    method: 'POST',
    body: { ...rest, consents: { terms: terms[0], older16: older16[0] } },
    thunkAPI,
  });
};

const registerViaOAuth =
  (thunkAPI: ThunkAPI) =>
  ({ accessToken = '', socialNetwork = '' }: OAuthRegisterPayload) => {
    return apiRequest<OAuthRegisterResponse>(`${USER_URL}/social`, {
      method: 'POST',
      body: { accessToken, socialNetwork },
      thunkAPI,
    });
  };

export const UserAPI = {
  fetchUserSummary,
  register,
  registerViaOAuth,
};
