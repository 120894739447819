import { apiRequest } from '../api/request/makeRequest';
import type {
  CloseRapidMessagePayload,
  RapidMessagesPayload,
  RapidMessagesResponse,
} from './type';
import type { ThunkAPI } from '@store/configureStore';

const RAPID_MESSAGE_URL = 'rapid-message';

const fetchRapidMessages =
  (thunkAPI: ThunkAPI) => (data: RapidMessagesPayload) =>
    apiRequest<RapidMessagesResponse>(`${RAPID_MESSAGE_URL}s/list`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

const closeRapidMessage =
  (thunkAPI: ThunkAPI) => (data: CloseRapidMessagePayload) =>
    apiRequest(`${RAPID_MESSAGE_URL}s/close/${data.rapidMessageId}`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

export const RapidMessageAPI = {
  fetchRapidMessages,
  closeRapidMessage,
} as const;
