import { apiRequest } from '../api/request/makeRequest';
import type { LocationUserFavoritesResponse } from './type';
import type { ThunkAPI } from '@store/configureStore';

const LOCATION_URL = 'locations';

const fetchLocationsUserFavorites = (thunkAPI: ThunkAPI) => () =>
  apiRequest<LocationUserFavoritesResponse>(`${LOCATION_URL}/user-favorites`, {
    method: 'GET',
    thunkAPI,
  });

export const LocationAPI = {
  fetchLocationsUserFavorites,
} as const;
