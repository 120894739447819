export const normalizeMiddleware = () => (next) => (action) => {
  // filter both by action type and metadata content
  if (action.type.includes('set') && action.meta && action.meta.normalizeKey) {
    // transform the data structure
    const items = action.payload.reduce((acc, item) => {
      acc[item[action.meta.normalizeKey]] = item;
      return acc;
    }, {});

    const newAction = {
      ...action,
      payload: items,
      meta: { ...action.meta, normalizeKey: null },
    };

    // fire the next action with modified payload
    next(newAction);
  } else {
    next(action);
  }
};
