export const decodeHTML = (snippet: string) => {
  if (!snippet) {
    return snippet;
  }

  return snippet;
  // .replace(/(<([a-zA-Z]+)>)/gi, '')
  // .replace(/\s\s+/g, ' ')
  // .replace(/\n|\r/g, ' ')
  // .replace(/\xA0/g, ' ');
};
