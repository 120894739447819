import { impression, sendToFacebook, sendToGTM } from '@lib/gtag';

export const smallBusinessEvents = {
  impression: () => impression('small_business'),

  success: () => {
    sendToGTM({
      event: 'small_business',
      eventAction: 'success',
      action: 'success',
    });
    sendToFacebook({
      event: 'small_business',
      action: 'success',
    });
  },
};
