import slices from './slices';

const selector = (slice) => (state) => state[slice.name];

export const formValueSelector = (state, props) => props.values;
export const categorySelector = selector(slices.categorySlice);
export const categoryBoxSelector = selector(slices.categoryBoxSlice);
export const classifierSelector = selector(slices.classifierSlice);
export const groupSelector = selector(slices.groupSlice);
export const smallBusinessSelector = selector(slices.smallBusinessSlice);
export const userSelector = selector(slices.userSlice);
export const searchSelector = selector(slices.searchSlice);
export const statsSelector = selector(slices.statsSlice);
export const currencyConverterSelector = selector(
  slices.currencyConverterSlice
);
export const authSelector = selector(slices.authSlice);
export const adSelector = selector(slices.adSlice);
export const reviewSelector = selector(slices.reviewSlice);
export const meAccountSelector = selector(slices.meAccountSlice);
export const meAdsSelector = selector(slices.meAdsSlice);
export const meProfileSelector = selector(slices.meProfileSlice);
export const meStatsSelector = selector(slices.meStatsSlice);
export const gdprSelector = selector(slices.gdprSlice);
export const addressSelector = selector(slices.addressSlice);
export const recommendedSelector = selector(slices.recommendedSlice);
export const bannerSelector = selector(slices.bannerSlice);
export const locationSelector = selector(slices.locationSlice);
export const mediaKitSelector = selector(slices.mediaKitSlice);
export const searchFormSelector = selector(slices.searchFormSlice);
export const staticPagesSelector = selector(slices.staticPagesSlice);
export const feedbackSelector = selector(slices.feedbackSlice);
export const antiBotSelector = selector(slices.antiBotSlice);
export const customValueSelector = selector(slices.customValueSlice);
export const pollSelector = selector(slices.pollSlice);
export const kpIzlogSelector = selector(slices.kpIzlogSlice);
export const rapidMessageSelector = selector(slices.rapidMessageSlice);
export const myKpHeaderSelector = selector(slices.myKpHeaderSlice);
export const fraudSelector = selector(slices.fraudSlice);
export const jobSelector = selector(slices.jobSlice);
export const categoryInfoSelector = selector(slices.categoryInfoSlice);
export const adSaveSelector = selector(slices.adSaveSlice);
export const promotionSelector = selector(slices.promotionSlice);
export const agreementSelector = selector(slices.agreementSlice);
export const savedSearchSelector = selector(slices.savedSearchSlice);
export const addressBookSelector = selector(slices.addressBookSlice);
export const invoiceSelector = selector(slices.invoiceSlice);
export const notificationSelector = selector(slices.notificationSlice);
export const renewerSelector = selector(slices.renewerSlice);
export const messageThreadSelector = selector(slices.messageThreadSlice);
export const messageInboxSelector = selector(slices.messageInboxSlice);
export const transactionSelector = selector(slices.transactionSlice);
export const fileSelector = selector(slices.fileSlice);
export const emojiSelector = selector(slices.emojiSlice);
export const categoryHeadlineSelector = selector(slices.categoryHeadlineSlice);
export const bookInfoSelector = selector(slices.bookSlice);
export const shipmentTrackCourierSelector = selector(
  slices.shipmentTrackCourierSlice
);
export const shippingOrderStatus = selector(slices.shippingOrderStatusSlice);
export const shippingCourierSelector = selector(
  slices.shippingScheduleCourierSlice
);
