import { apiRequest } from '../api/request/makeRequest';
import type { CategoryBoxPayload, CategoryBoxResponse } from './type';
import type { ThunkAPI } from '@store/configureStore';

const CATEGORY_BOX_URL = 'category-box';

const fetchCategoryBox =
  (thunkAPI: ThunkAPI) =>
  ({ kind = 'goods' }: CategoryBoxPayload) =>
    apiRequest<CategoryBoxResponse>(`${CATEGORY_BOX_URL}?kind=${kind}`, {
      method: 'GET',
      thunkAPI,
    });

const fetchCategoryBoxById =
  (thunkAPI: ThunkAPI) =>
  ({ categoryId, kind = 'goods' }: CategoryBoxPayload) =>
    apiRequest<CategoryBoxResponse>(
      `${CATEGORY_BOX_URL}/${categoryId}?kind=${kind}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );

export const CategoryBoxAPI = {
  fetchCategoryBox,
  fetchCategoryBoxById,
} as const;
