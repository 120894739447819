import isEmpty from 'lodash/isEmpty';

import { CookiesAPI } from '@lib/common/cookies';

type ShippingEducation = {
  safeOrderEducationalModalInfo: {
    displayFrequency: number;
    educationalModalEnabled: boolean;
    forceDisplayExpiry: string;
  };
};

export const getShippingEducation = ({
  safeOrderEducationalModalInfo,
}: ShippingEducation) => {
  const {
    displayFrequency = 0,
    educationalModalEnabled = false,
    forceDisplayExpiry = '',
  } = safeOrderEducationalModalInfo;

  const hasCookieShippingEducation =
    Boolean(CookiesAPI.get('shipping_education')) ?? false;
  const forcedCookieShippingEducation = CookiesAPI.get(
    'force_shipping_education'
  );

  const newForcedExpiryTimestamp = new Date(forceDisplayExpiry).getTime();

  if (!educationalModalEnabled) return false;

  const setShippingEducationCookie = () => {
    CookiesAPI.set('shipping_education', '1', {
      expires: displayFrequency,
    });
  };

  const setForcedShippingEducationCookie = (date: string) => {
    CookiesAPI.set('force_shipping_education', date, {
      expires: 1000,
    });
  };

  const getForcedDateInfo = () => {
    const isNewForcedDateInPast =
      new Date().getTime() > newForcedExpiryTimestamp;
    const isNewForceDateGreaterThanStoredDate =
      newForcedExpiryTimestamp > Number(forcedCookieShippingEducation);
    const isForcedWithNewDate =
      isNewForceDateGreaterThanStoredDate && isNewForcedDateInPast;
    return { isNewForcedDateInPast, isForcedWithNewDate };
  };

  const initEducationWithDaysFrequency = () => {
    if (!hasCookieShippingEducation) {
      setShippingEducationCookie();
      return true;
    }
    return false;
  };

  const initEducationWithForcedDate = () => {
    if (isEmpty(forceDisplayExpiry)) return false;

    const { isNewForcedDateInPast, isForcedWithNewDate } = getForcedDateInfo();

    if (!forcedCookieShippingEducation && isNewForcedDateInPast) {
      setForcedShippingEducationCookie(newForcedExpiryTimestamp.toString());
      return true;
    }
    if (isForcedWithNewDate) {
      setForcedShippingEducationCookie(newForcedExpiryTimestamp.toString());
      return true;
    }
    return false;
  };

  const shouldDisplayCourierEducation =
    initEducationWithForcedDate() || initEducationWithDaysFrequency();

  return shouldDisplayCourierEducation;
};
