import { EmptyObject } from '@store/global/type';
import { createAppAsyncThunk } from '@store/global/utils';
import { LoggingAPI } from '@store/logging/api';

import {
  AdIdPayload,
  LogAdCreateStepPayload,
  LogAddressPayload,
  LogChangeThemePayload,
  LogDownloadAppPayload,
  LogFeedbackInterceptor,
  LogIsbnScanPayload,
  LogNiriGroupSuggestionsPayload,
  LogOrderCourierPayload,
  LogTrackingCodePayload,
  LogUpsellPromoPayload,
} from './type';

export const logWelcomeScreen = createAppAsyncThunk(
  'logging/logWelcomeScreen',
  async (payload: string, thunkAPI) => {
    const { data } = await LoggingAPI.logWelcomeScreen(thunkAPI)(payload);

    return data.results;
  }
);

export const logNiriGroupSuggestions = createAppAsyncThunk(
  'logging/logNiriGroupSuggestions',
  async (payload: LogNiriGroupSuggestionsPayload, thunkAPI) => {
    const { data } = await LoggingAPI.logNiriGroupSuggestions(thunkAPI)(
      payload
    );

    return data.results;
  }
);

export const logFmcgClick = createAppAsyncThunk(
  'logging/logFmcgClick',
  async (payload: EmptyObject, thunkAPI) => {
    const { data } = await LoggingAPI.logFmcgClick(thunkAPI)(payload);

    return data.results;
  }
);

export const logAdCreateStep = createAppAsyncThunk(
  'log/adCreate',
  async (payload: LogAdCreateStepPayload, thunkAPI) => {
    const { data } = await LoggingAPI.logAdCreateStep(thunkAPI)(payload);

    return data.results;
  }
);

export const logClickOnPhoneButton = createAppAsyncThunk(
  'logging/logClickOnPhoneButton',
  async (payload: AdIdPayload, thunkAPI) => {
    const { data } = await LoggingAPI.logClickOnPhoneButton(thunkAPI)(payload);

    return data.results;
  }
);

export const logClickOnPromotionButton = createAppAsyncThunk(
  'logging/logClickOnPromotionButton',
  async (payload: AdIdPayload, thunkAPI) => {
    const { data } = await LoggingAPI.logClickOnPromotionButton(thunkAPI)(
      payload
    );

    return data.results;
  }
);

export const logFeedbackInterceptor = createAppAsyncThunk(
  'logging/logFeedbackInterceptor',
  async (payload: LogFeedbackInterceptor, thunkAPI) => {
    const { data } = await LoggingAPI.logFeedbackInterceptor(thunkAPI)(payload);

    return data.results;
  }
);

export const logAddress = createAppAsyncThunk(
  'logging/logAddress',
  async (payload: LogAddressPayload, thunkAPI) => {
    const { data } = await LoggingAPI.logAddress(thunkAPI)(payload);

    return data.results;
  }
);

export const logTrackingCode = createAppAsyncThunk(
  'logging/logTrackingCode',
  async (payload: LogTrackingCodePayload, thunkAPI) => {
    const { data } = await LoggingAPI.logTrackingCode(thunkAPI)(payload);

    return data.results;
  }
);

export const logChangeTheme = createAppAsyncThunk(
  'logging/logChangeTheme',
  async (payload: LogChangeThemePayload, thunkAPI) => {
    const { data } = await LoggingAPI.logChangeTheme(thunkAPI)(payload);

    return data.results;
  }
);

export const logUpsellPromo = createAppAsyncThunk(
  'logging/logUpsellPromo',
  async (payload: LogUpsellPromoPayload, thunkAPI) => {
    const { data } = await LoggingAPI.logUpsellPromo(thunkAPI)(payload);

    return data.results;
  }
);

export const logDownloadApp = createAppAsyncThunk(
  'logging/logDownloadApp',
  async (payload: LogDownloadAppPayload, thunkAPI) => {
    const { data } = await LoggingAPI.logDownloadApp(thunkAPI)(payload);

    return data.results;
  }
);

export const logOrderCourier = createAppAsyncThunk(
  'logging/logOrderCourier',
  async (payload: LogOrderCourierPayload, thunkAPI) => {
    const { data } = await LoggingAPI.logOrderCourier(thunkAPI)(payload);

    return data.results;
  }
);

export const logIsbnScan = createAppAsyncThunk(
  'logging/logIsbnScan',
  async (payload: LogIsbnScanPayload, thunkAPI) => {
    const { data } = await LoggingAPI.logIsbnScan(thunkAPI)(payload);

    return data.results;
  }
);
