export type Review = {
  id: number;
  userCreated: string;
  userType: string;
  username: string;
  rate: number;
  rateDesc: string;
  rateAgreement: number;
  adId: number;
  adName: string;
  adKind: string;
  comment: string;
  relatedTo: string;
  posted: string;
  reply: string;
  replyStatus: string;
  replyPosted: string;
  postUserId: number;
  positive: boolean;
  negative: boolean;
  rateDescriptionText: string;
  rateCommunicationText: string;
  ratePaymentText: string;
  rateAgreementText: string;
  userId: number;
  userShowAdsUrl: boolean;
  rateAsBuyer: boolean;
  rateAsSeller: boolean;
  replyIsActive: boolean;
  canReply: boolean;
  canRemove: boolean;
  postedHour: string;
  replyComment: string;
};

export const createReview = (review: any): Review => {
  return {
    id: review.review_id,
    userCreated: review.user_created,
    userType: review.user_type,
    username: review.user_name,
    rate: review.rate,
    rateDesc: review.rate_description || '',
    rateAgreement: review.rate_agreement,
    adId: review.ad_id,
    adName: review.ad_name,
    adKind: review.ad_kind,
    comment: review.comment,
    relatedTo: review.related_to,
    posted: review.posted,
    reply: review.reply,
    replyStatus: review.reply_status,
    replyPosted: review.reply_posted,
    postUserId: review.post_user_id,
    positive: review.positive,
    negative: review.negative,
    rateDescriptionText: review.rate_description_text,
    rateCommunicationText: review.rate_communication_text,
    ratePaymentText: review.rate_payment_text,
    rateAgreementText: review.rate_agreement_text,
    userId: review.user_id,
    userShowAdsUrl: review.user_show_ads_url,
    rateAsBuyer: review.rate_as_buyer,
    rateAsSeller: review.rate_as_seller,
    replyIsActive: review.reply_is_active,
    canReply: review.can_reply,
    canRemove: review.can_remove,
    postedHour: review.posted_hour,
    replyComment: review.reply_comment,
  };
};
