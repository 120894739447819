import { createAppAsyncThunk } from '@store/global/utils';
import { TransactionAPI } from './api';

import { normalize } from '@lib/normalize';
import {
  CreditCardFormConnectPayload,
  FetchTransactionsPayload,
  OrderInfoPayload,
  PaymentSlipPayload,
  PrepaidInvoicePayload,
  SmsPhoneCodePayload,
  StoredCardId,
} from './type';

export const fetchTransactions = createAppAsyncThunk(
  'invoice/fetchTransactions',
  async (payload: FetchTransactionsPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await TransactionAPI.fetchTransactions(thunkAPI)(
        payload
      );

      return data.results;
    } catch (error) {
      return rejectWithValue(
        normalize(error.response.data.errors, 'error_code')
      );
    }
  }
);

export const fetchUserCards = createAppAsyncThunk(
  'transaction/fetchUserCards',
  async (_, thunkAPI) => {
    const { data } = await TransactionAPI.fetchUserCards(thunkAPI)();
    return data.results;
  }
);

export const deleteUserCard = createAppAsyncThunk(
  'transaction/deleteUserCard',
  async (payload: StoredCardId, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { data } = await TransactionAPI.deleteUserCard(thunkAPI)(payload);
    if (data.success) {
      dispatch(fetchUserCards());
    }
    return data.results;
  }
);

export const setDefaultUserCard = createAppAsyncThunk(
  'transaction/setDefaultUserCard',
  async (payload: StoredCardId, thunkAPI) => {
    const { data } = await TransactionAPI.setDefaultUserCard(thunkAPI)(payload);
    return data.results;
  }
);

export const fetchSmsPrices = createAppAsyncThunk(
  'transaction/fetchSmsPrices',
  async (_, thunkAPI) => {
    const { data } = await TransactionAPI.fetchSmsPrices(thunkAPI)();
    return data.results;
  }
);

export const fetchSmsPhoneCode = createAppAsyncThunk(
  'transaction/fetchSmsPhoneCode',
  async (payload: SmsPhoneCodePayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await TransactionAPI.fetchSmsPhoneCode(thunkAPI)(
        payload
      );

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const fetchPaymentSlip = createAppAsyncThunk(
  'transaction/fetchPaymentSlip',
  async (payload: PaymentSlipPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await TransactionAPI.fetchPaymentSlip(thunkAPI)(payload);

      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const checkConnectCardForm = createAppAsyncThunk(
  'transaction/checkConnectCardForm',
  async (payload: CreditCardFormConnectPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await TransactionAPI.checkConnectCardForm(thunkAPI)(
        payload
      );
      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const connectCardForm = createAppAsyncThunk(
  'transaction/connectCardForm',
  async (payload: CreditCardFormConnectPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await TransactionAPI.connectCardForm(thunkAPI)(payload);
      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const fetchOrderInfo = createAppAsyncThunk(
  'transaction/fetchOrderInfo',
  async (payload: OrderInfoPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await TransactionAPI.fetchOrderInfo(thunkAPI)(payload);
      return data.results;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const fetchTransactionSettings = createAppAsyncThunk(
  'transaction/fetchTransactionSettings',
  async (_, thunkAPI) => {
    const { data } = await TransactionAPI.fetchTransactionSettings(thunkAPI)();
    return data.results;
  }
);

export const createPrepaidInvoice = createAppAsyncThunk(
  'transaction/createPrepaidInvoice',
  async (payload: PrepaidInvoicePayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await TransactionAPI.createPrepaidInvoice(thunkAPI)(
        payload
      );
      return data.result;
    } catch (e) {
      return rejectWithValue(e?.response?.data?.errors);
    }
  }
);
