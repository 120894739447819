import { createSelector } from 'reselect';

import { myKpHeaderSelector } from '../rootSelector';
import { MyKpHeaderInitialState } from './type';

export const selectDisplayMenu = createSelector(
  myKpHeaderSelector,
  (slicedState: MyKpHeaderInitialState) => {
    try {
      return slicedState.displayMenu;
    } catch (e) {
      return false;
    }
  }
);

export const selectStickyHeaderHeight = createSelector(
  myKpHeaderSelector,
  (slicedState: MyKpHeaderInitialState) => {
    try {
      return slicedState.stickyHeaderHeight;
    } catch (e) {
      return null;
    }
  }
);
