import { apiRequest } from '@store/api/request/makeRequest';
import type { SubmitSmallBusinessForm } from '@store/smallBusiness/type';
import type { ThunkAPI } from '@store/configureStore';

const SMALL_BUSINESS_URL = 'small-business';

const postContactForm =
  (thunkAPI: ThunkAPI) => (data: SubmitSmallBusinessForm) =>
    apiRequest(`${SMALL_BUSINESS_URL}/contact-form`, {
      method: 'POST',
      body: data,
      thunkAPI,
    });

export const SmallBusinessAPI = {
  postContactForm,
};
