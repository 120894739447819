import { formatSearchParams } from '@lib/formatSearchParams';
import { MessageInboxPayload } from './type';

export const searchParams = (payload: MessageInboxPayload) => {
  const searchParamsObj = formatSearchParams(payload.query);
  return new URLSearchParams(searchParamsObj).toString();
};

export const includeOldQueryParams = (payload: MessageInboxPayload) => {
  const { includeOld } = payload.query;
  return includeOld
    ? { query: { includeOld: 'yes' } }
    : { query: { includeOld: 'no' } };
};
