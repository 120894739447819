import { ParsedUrlQuery } from 'querystring';

import { createBase64 } from '@lib/createBase64';

import { MyKPAdsRoute } from '@server/routes';

export const LABEL = {
  gallery: {
    basic: 'Galerija',
    car: 'Galerija',
    job: 'Logo firme, slike prostorija, radnog mesta...',
  },
  name: {
    basic: 'Naslov oglasa',
    car: 'Naslov oglasa',
    job: 'Naziv pozicije',
  },
  namePlaceholder: {
    job: 'npr. Pizza majstor u pekari',
    car: '',
    basic: '',
  },
  adDescription: {
    basic:
      'Ne koristiti pretežno velika slova jer takvi oglasi odbijaju posetioce',
    job: `<span>Opis posla, traženo radno iskustvo ili veštine, šta nudite – <a href='https://blog.kupujemprodajem.com/kako-da/kako-da-napravite-dobar-oglas-za-posao/?utm_source=kp&utm_medium=postavljanje_oglasa&utm_campaign=poslovi' target="_blank">pogledajte primer</a></span>`,
    car: 'Opišite u kakvom je stanju automobil koji prodajete, šta treba zameniti, šta je skoro zamenjeno, da li ima sitnih kvarova',
  },
  location: {
    basic: 'Mesto/Grad',
    job: 'Lokacija radnog mesta',
    car: 'Mesto/Grad',
    realEstate: 'Lokacija nekretnine',
    vacationSerbia: 'Lokacija smeštaja',
  },
  owner: {
    basic: 'Ime i prezime (ili naziv firme)',
    job: 'Naziv poslodavca',
    car: 'Ime i prezime (ili naziv firme)',
  },
  ownerPlaceholder: {
    basic: 'npr. Petar Petrović',
    job: '',
    car: 'npr. Petar Petrović',
  },
  personalTitle: {
    basic: (email) => (
      <>
        Lični podaci <span>({email})</span>
      </>
    ),
    job: () => 'Osnovni podaci',
    car: (email) => (
      <>
        Lični podaci <span>({email})</span>
      </>
    ),
    realEstate: () => 'Lokacija nekretnine i Vaši lični podaci',
    vacationSerbia: () => 'Lokacija smeštaja i Vaši lični podaci',
  },
};

export const IMAGE_ERRORS = {
  'file-invalid-type': 'Mogući formati su: jpg, png, gif, heif',
  'file-too-large': 'Fajl je veći od dozvoljenih 25 MB.',
};

export const generateBackUrl = (query: ParsedUrlQuery) => {
  const { status = '', page = 1, returnUrl, scrollId } = query;
  if (returnUrl) {
    const url = createBase64(returnUrl);
    return url;
  }
  return MyKPAdsRoute.generateUrl({}, { page, status, scrollId });
};

export const AD_SAVE_ROUTES = ['/postavljanje-oglasa', '/izmena-oglasa'];
