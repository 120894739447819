import { AxiosPromise } from 'axios';

import isServer from '@lib/ssrSetup/isServer';
import { API_URL } from 'src/lib/constants/env.constants';

import type { Any, RequestConfig } from '@store/global/type';

import { axiosInstance } from './axiosInstance';
import { generateSignature } from './generateSignature';
import { getHeaders } from './getHeaders';
import { handleAccessLoginControl } from './handleAccessLoginControl';
import { handleMaintenanceMode } from './handleMaintenanceMode';
import { handleSoftErrors } from './handleSoftErrors';
import { setSessionHeaders } from './setSessionHeaders';

const baseUrl = `/api/web/v1/`;

export const apiRequest = <T = Any>(
  url: string,
  {
    body,
    method,
    headers,
    onUploadProgress,
    thunkAPI = null,
  }: RequestConfig<typeof body>
): AxiosPromise<T> => {
  let domain = API_URL;
  let webdriver = false;
  let dark = false;
  let theme = 'system';

  if (isServer) {
    domain = process.env.SERVER_API_URL;
  } else {
    dark = window.matchMedia('(prefers-color-scheme: dark)').matches ?? false;
    webdriver = window?.navigator?.webdriver;
    theme = window.localStorage.getItem('theme') ?? 'system';
  }

  const signature = generateSignature(`${baseUrl}${url}`, body);
  const fullUrl = `${domain}${baseUrl}${url}`;

  const response = axiosInstance({
    method,
    url: fullUrl,
    data: body,
    headers: {
      'Content-Type': 'application/json',
      'x-kp-signature': signature,
      'x-kp-webdriver': webdriver,
      'x-kp-dark': dark ? 'true' : 'false',
      'x-kp-theme': theme,
      ...headers,
      ...getHeaders(thunkAPI),
    },
    onUploadProgress,
  });

  response
    .then((payload) => {
      const responseHeaders = payload?.headers ?? {};
      setSessionHeaders(thunkAPI, url, responseHeaders);
      handleMaintenanceMode(thunkAPI, responseHeaders);
      handleSoftErrors(thunkAPI, payload);
    })
    .catch((error) => {
      const responseHeaders = error?.response?.headers ?? {};
      setSessionHeaders(thunkAPI, url, responseHeaders);
      handleMaintenanceMode(thunkAPI, responseHeaders);
      handleAccessLoginControl({ thunkAPI, url, response: error?.response });
    });

  return response;
};
