import https from 'https';
import axios from 'axios';

const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
});

export const axiosInstance = axios.create({
  withCredentials: true,
  httpsAgent,
});
