import isEmpty from 'lodash/isEmpty';
import { parse } from 'bowser';

export function getDeviceInfo(userAgent: string): Bowser.Parser.ParsedResult {
  if (isEmpty(userAgent)) {
    return {
      browser: { name: 'unknown', version: 'unknown' },
      platform: { type: 'unknown' },
      os: { name: 'unknown' },
      engine: { name: 'unknown' },
    };
  }

  const deviceInfo = parse(userAgent);

  if (deviceInfo.browser.name === 'Googlebot') {
    const isBotMobile =
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(userAgent);
    deviceInfo.platform.type = isBotMobile ? 'mobile' : 'desktop';
  }

  return deviceInfo;
}
