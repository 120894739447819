export const ALL_ADS_INFO_DESCRIPTION =
  'U svakom Vašem oglasu ispod opisa oglasa';

export const UPDATE_PROFILE_NOTIFICATION_CONTENT = {
  success: { content: 'Izmene uspešno sačuvane', description: '' },
  successUpdateAds: {
    content: 'Uspešno je započeta izmena ličnih podataka u svim oglasima',
    description:
      'Kod velikog broja oglasa ovaj proces može potrajati i do 10 minuta.',
  },
  successParkAllAds: {
    content: 'Uspešno je započeto parkiranje svih oglasa',
    description:
      'Kod velikog broja oglasa ovaj proces može potrajati i do 10 minuta.',
  },
  successUnparkAllAds: {
    content: 'Uspešno je započeto aktiviranje svih Vaših parkiranih oglasa',
    description:
      'Kod velikog broja oglasa ovaj proces može potrajati i do 10 min.',
  },
  successSetDeliveryMethod: {
    content:
      'Uspešno je započeto dodavanje izabranog načina isporuke u svim Vašim oglasima.',
    description:
      'U slučaju velikog broja oglasa ovaj proces može potrajati i do 10 minuta.',
  },
};
