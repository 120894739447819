import { ROOT_URL } from './env.constants';

export const BACKUP_BANNERS_101 = [
  {
    bannerPositionId: 101,
    bannerId: 95025,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95025"><img src="/static/images/banners/101_1.jpg" alt="" border="0"></a>`,
      info2: '',
    },
  },

  {
    bannerPositionId: 101,
    bannerId: 95029,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95029"><img src="/static/images/banners/101_3.jpg" alt="" border="0"></a>`,
      info2: '',
    },
  },
];

export const BACKUP_BANNERS_104 = [
  {
    bannerPositionId: 104,
    bannerId: 95025,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95025"><img src="/static/images/banners/104_1.jpg" alt="" border="0"></a>`,
      info2: '',
    },
  },

  {
    bannerPositionId: 104,
    bannerId: 95029,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95029"><img src="/static/images/banners/104_3.jpg" alt="" border="0"></a>`,
      info2: '',
    },
  },
];

export const BACKUP_BANNERS_105 = [
  {
    bannerPositionId: 105,
    bannerId: 95025,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95025"><img src="/static/images/banners/105_1.jpg" alt="" border="0"></a>`,
      info2: '',
    },
  },

  {
    bannerPositionId: 105,
    bannerId: 95029,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95029"><img src="/static/images/banners/105_3.jpg" alt="" border="0"></a>`,
      info2: '',
    },
  },
];

export const BACKUP_BANNERS_301 = [
  {
    bannerPositionId: 301,
    bannerId: 95023,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95023"><img src="/static/images/banners/301_1.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 301,
    bannerId: 95027,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95027"><img src="/static/images/banners/301_3.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
];

export const BACKUP_BANNERS_302 = [
  {
    bannerPositionId: 302,
    bannerId: 95024,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95024"><img src="/static/images/banners/302_1.jpg" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 302,
    bannerId: 95028,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95028"><img src="/static/images/banners/302_3.jpg" alt="" border="0"></a>`,
      info2: '',
    },
  },
];

export const BACKUP_BANNERS_306 = [
  {
    bannerPositionId: 306,
    bannerId: 95025,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95025"><img src="/static/images/banners/306_1.jpg" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 306,
    bannerId: 95029,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95029"><img src="/static/images/banners/306_3.jpg" alt="" border="0"></a>`,
      info2: '',
    },
  },
];

export const BACKUP_BANNERS_308 = [
  {
    bannerPositionId: 308,
    bannerId: 95025,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95025"><img src="/static/images/banners/308_1.jpg" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 308,
    bannerId: 95029,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95029"><img src="/static/images/banners/308_3.jpg" alt="" border="0"></a>`,
      info2: '',
    },
  },
];
