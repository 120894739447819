import { apiRequest } from '../api/request/makeRequest';
import type { SeoTextResponse } from './type';
import type { ThunkAPI } from '@store/configureStore';

const SEO_URL = 'seo';

const fetchSeoCategoryText = (thunkAPI: ThunkAPI) => (payload: number) => {
  return apiRequest<SeoTextResponse>(`${SEO_URL}/category-text/${payload}`, {
    method: 'GET',
    thunkAPI,
  });
};

export const seoTextAPI = {
  fetchSeoCategoryText,
} as const;
