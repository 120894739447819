export const getInitialPackageContent = (
  firstShippingOrder: boolean,
  shouldSelectFirstContent: boolean,
  packageCategories: { name: string }[] = []
): string => {
  if (!firstShippingOrder && shouldSelectFirstContent) {
    return packageCategories[0]?.name || '';
  }
  return '';
};
