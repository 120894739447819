import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { checkAd, fetchUserOwnedAd } from '@store/adSave/thunk';
import { AdCheckResponse, FetchUserOwnedAd } from '@store/adSave/type';

import {
  fetchAccessControlInfo,
  fetchAdStats,
  fetchFeatureFlags,
  fetchGeneralGroupedInfo,
  fetchPasswordRules,
  setCustomHeightTrigger,
} from './thunk';
import { GeneralStatsInitialState } from './type';

export const initialState: GeneralStatsInitialState = {
  count: {
    totalAdCountGoods: '...',
    totalAdCountService: '...',
    totalAdCountJob: '...',
    totalAdCount: '...',
    kpIzlogCount: '...',
    yesterdayPublishedAds: '...',
    totalActiveUser: '...',
  },
  featureFlags: {},
  pricelist: {
    lastChangeDate: '11.02.2021.',
  },
  config: {
    maxUserNameLength: 30,
    showUpsellPromoAdInfoPageThreshold: null,
    loginBenefitInfo: '',
  },
  passwordRules: [],
  isLoading: false,
  isCustomHeightTriggered: false,
  accessControlInfo: {
    accessControlErrorDescription: '',
  },
  outdatedBrowsers: {
    browsers: {},
    notificationLifetime: '',
  },
};

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    resetAccessControlInfo(state) {
      state.accessControlInfo = initialState.accessControlInfo;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (!isEqual(state, action.payload[statsSlice.name])) {
        Object.assign(state, action.payload.stats);
      }
    });

    builder.addCase(fetchAdStats.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchAdStats.fulfilled, (state, action) => {
      state.count = { ...state.count, ...action.payload };
      state.isLoading = false;
    });

    builder.addCase(fetchFeatureFlags.fulfilled, (state, action) => {
      state.featureFlags = action.payload;
      state.isLoading = false;
    });

    builder.addCase(checkAd.fulfilled, (state, action) => {
      const { phoneDisplayControl } = action.payload as AdCheckResponse;
      const { isPhoneDisplayControlEnabled = false } =
        phoneDisplayControl ?? {};

      state.featureFlags = {
        ...state.featureFlags,
        isPhoneDisplayControlEnabled,
      };

      state.isLoading = false;
    });

    builder.addCase(fetchUserOwnedAd.fulfilled, (state, action) => {
      const { phoneDisplayControl } = action.payload as FetchUserOwnedAd;
      const { isPhoneDisplayControlEnabled = false } =
        phoneDisplayControl ?? {};
      state.featureFlags = {
        ...state.featureFlags,
        isPhoneDisplayControlEnabled,
      };

      state.isLoading = false;
    });

    builder.addCase(fetchGeneralGroupedInfo.fulfilled, (state, action) => {
      const {
        stats = initialState.count,
        pricelist = initialState.pricelist,
        config = initialState.config,
        outdatedBrowsers = initialState.outdatedBrowsers,
      } = action.payload;

      state.count = { ...state.count, ...stats };
      state.pricelist = pricelist;
      state.config = config;
      state.outdatedBrowsers = outdatedBrowsers;

      state.isLoading = false;
    });

    builder.addCase(fetchPasswordRules.fulfilled, (state, action) => {
      state.passwordRules = action.payload;
    });

    builder.addCase(setCustomHeightTrigger.fulfilled, (state, action) => {
      state.isCustomHeightTriggered = action.payload;
    });

    builder.addCase(fetchAccessControlInfo.fulfilled, (state, action) => {
      state.accessControlInfo = action.payload;
    });
  },
});

export const { resetAccessControlInfo } = statsSlice.actions;

export default statsSlice;
