import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import type {
  FeatureFlagResponse,
  FilterInfoResponse,
  FiltersInfoPayload,
  GeneralAccessControlInfo,
  GeneralAdsStatsResponse,
  GeneralGroupedInfoResponse,
  PasswordRulesResponse,
  ReturnUrlResponse,
} from './type';

const GENERAL_STATS_URL = 'general';

const fetchGeneralStats = (thunkAPI: ThunkAPI) => () =>
  apiRequest<GeneralAdsStatsResponse>(`${GENERAL_STATS_URL}/stats`, {
    method: 'GET',
    thunkAPI,
  });

const fetchFiltersInfo = (thunkAPI: ThunkAPI) => (data: FiltersInfoPayload) =>
  apiRequest<FilterInfoResponse>(`${GENERAL_STATS_URL}/filters-info`, {
    method: 'POST',
    body: data,
    thunkAPI,
  });

const fetchFeatureFlags = (thunkAPI: ThunkAPI) => () =>
  apiRequest<FeatureFlagResponse>(`${GENERAL_STATS_URL}/feature-flags`, {
    method: 'GET',
    thunkAPI,
  });

const fetchGeneralGroupedInfo = (thunkAPI: ThunkAPI) => () =>
  apiRequest<GeneralGroupedInfoResponse>(GENERAL_STATS_URL, {
    method: 'GET',
    thunkAPI,
  });

const fetchReturnUrl = (thunkAPI: ThunkAPI) => (returnUrl: string) =>
  apiRequest<ReturnUrlResponse>(`${GENERAL_STATS_URL}/return-url`, {
    method: 'POST',
    body: { returnUrl },
    thunkAPI,
  });

const fetchPasswordRules = (thunkAPI: ThunkAPI) => () =>
  apiRequest<PasswordRulesResponse>(`${GENERAL_STATS_URL}/password-rules`, {
    method: 'GET',
    thunkAPI,
  });

const fetchAccessControlInfo = (thunkAPI: ThunkAPI) => () =>
  apiRequest<GeneralAccessControlInfo>(
    `${GENERAL_STATS_URL}/access-control-info`,
    {
      method: 'GET',
      thunkAPI,
    }
  );

export const GeneralStatsAPI = {
  fetchGeneralStats,
  fetchFiltersInfo,
  fetchFeatureFlags,
  fetchGeneralGroupedInfo,
  fetchReturnUrl,
  fetchPasswordRules,
  fetchAccessControlInfo,
} as const;
